"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hearthHarmony = void 0;
const towne_1 = require("./towne");
exports.hearthHarmony = Object.assign(Object.assign({}, towne_1.towne), { app: { name: "SPONSORSHIP PROGRAMS" }, company: {
        name: "Hearth & Harmony",
        logo: {
            url: "/custom/hearth-harmony/logo.svg",
            description: "hearth & harmony",
        },
        icon: "/custom/hearth-harmony/tags/favicon.ico",
        image: "/custom/hearth-harmony/tags/logo.png",
    }, group: {
        singular: "Year",
        plural: "Years",
    }, parentGroup: {
        singular: "Year",
        plural: "Years",
    }, event: Object.assign(Object.assign({}, towne_1.towne.event), { type: {
            singular: "Experience",
            plural: "Experiences",
            singularWithArticle: "an Experience",
        } }), footerLinks: [
        {
            text: "Hearth & Harmony Privacy Policy",
            url: "https://www.max.live/privacy-policy",
        },
        { text: "MAX Privacy Policy", url: "https://www.max.live/privacy-policy" },
    ], fanAppUrl: `https://handh.live`, onsiteAppUrl: `https://onsite.handh.live`, privacyPolicyUrl: "https://www.max.live/privacy-policy", regionsCollection: "hearth_harmony_regions", menu: {
        sections: {
            engagement: {
                header: "User Engagement",
                items: {
                    live: {
                        label: "HearthHarmony.Live",
                    },
                },
            },
        },
    } });
