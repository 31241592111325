import styled from "styled-components";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Switch } from "Components/Switch";
import { FlexColumn, FlexRow } from "Components/Flex";
import { Card } from "Components";
import { SvgImportLarge } from "melodies-source/Svgs/ImportLarge";
import { Button } from "melodies-source/Button";
import { pluralize } from "Utils/pluralize";
import {
  doc,
  getCountFromServer,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { useDocument } from "react-firebase-hooks/firestore";
import { formatNumber } from "Utils/format";
import { Ref } from "refs";

export const SweepsLoader = () => (
  <StyledCard isElevated>
    <InnerCard>
      <HeaderContainer>
        <HeaderText>
          <Skeleton width="15rem" />
        </HeaderText>
      </HeaderContainer>
      <SweepsSearchContainer>
        <EligibleContactsContainer>
          <EligibleContactsNumber>
            <Skeleton width={100} />
          </EligibleContactsNumber>
          <EligibleContactsSubtext>
            <Skeleton width={150} />
          </EligibleContactsSubtext>
          <ViewContestRulesContainer>
            <ViewContestRules>
              <Skeleton height="2rem" width={150} />
            </ViewContestRules>
          </ViewContestRulesContainer>
        </EligibleContactsContainer>
        <FiltersContainer>
          <GeographicFilter limitToUS={false} setLimitToUS={null} isLoading />
          <FilterDivider />
          <NumberOfWinnersFilter
            isLoading
            numberOfWinners={0}
            eligibleContacts={0}
            setNumberOfWinners={null}
          />
          <LoadingButtonContainer>
            <Skeleton width={200} height={42} containerClassName="flex-1" />
          </LoadingButtonContainer>
        </FiltersContainer>
      </SweepsSearchContainer>
    </InnerCard>
  </StyledCard>
);

const LoadingButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  ${({ theme }) => theme.media.desktop} {
    align-self: flex-end;
    margin-top: auto;
  }
`;

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  ${({ theme }) => theme.media.mobile} {
    padding: 24px 15px;
  }
  ${({ theme }) => theme.media.tablet} {
    padding: 24px 15px;
  }
`;

const makeSweepsEntriesQuery = ({
  surveyId,
  limitToUS,
  withOrderBy,
  withLimit,
}: {
  surveyId: string;
  limitToUS: boolean;
  withOrderBy?: boolean;
  withLimit?: boolean;
}) => {
  return query(
    Ref("sts3_surveys/{surveyId}/sweeps_entries", {
      surveyId,
    }),
    ...[
      where("mainEntry", "==", true),
      limitToUS ? where("countryCode", "==", "US") : null,
      withOrderBy ? orderBy("createdAt", "desc") : null,
      withLimit ? limit(1) : null,
    ].filter(Boolean),
  );
};

const SweepsSearch = ({
  surveyId,
  numberOfWinners,
  runNewSweeps,
  setNumberOfWinners,
  forceUS,
}: {
  surveyId: string;
  forceUS?: boolean;
  numberOfWinners: number;
  runNewSweeps: (props: {
    numberOfWinners: number;
    limitToUS: boolean;
  }) => Promise<void>;
  setNumberOfWinners: Dispatch<SetStateAction<number>>;
}) => {
  const [limitToUS, setLimitToUS] = useState(!!forceUS);
  const [responses, setResponses] = useState(0);
  const timer = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (responses < numberOfWinners) {
      setNumberOfWinners(responses);
    }
  }, [responses, numberOfWinners, setNumberOfWinners]);

  const [surveyDoc] = useDocument(
    doc(getFirestore(), `sts3_surveys/${surveyId}`),
  );

  useEffect(() => {
    const fetchCount = async () => {
      const aggregate = await getCountFromServer(
        makeSweepsEntriesQuery({
          surveyId,
          limitToUS,
        }),
      );

      setResponses(aggregate?.data?.()?.count);
    };

    fetchCount(); // Initial load

    const unsubscribe = onSnapshot(
      makeSweepsEntriesQuery({
        surveyId,
        limitToUS,
        withOrderBy: true,
        withLimit: true,
      }),
      {
        includeMetadataChanges: true,
      },
      (snapshot) => {
        const hasPendingWrites = snapshot.metadata.hasPendingWrites;
        const fromCache = snapshot.metadata.fromCache;

        if (!hasPendingWrites && !fromCache) {
          if (timer.current) {
            clearTimeout(timer.current);
          }

          timer.current = setTimeout(() => fetchCount(), 500);
        }
      },
    );

    return () => {
      unsubscribe();
      clearTimeout(timer.current);
    };
  }, [limitToUS, surveyId]);

  return (
    <>
      <StyledCard isElevated>
        <InnerCard>
          <HeaderContainer>
            <HeaderText>Sweepstakes Winners</HeaderText>
          </HeaderContainer>
          <SweepsSearchContainer>
            <EligibleContactsContainer>
              <EligibleContactsNumber>
                {formatNumber(responses)}
              </EligibleContactsNumber>
              <EligibleContactsSubtext>{`Eligible Contact${
                responses === 1 ? "" : "s"
              }`}</EligibleContactsSubtext>
              <ViewContestRulesContainer
                onClick={() =>
                  window.open(
                    surveyDoc?.data().sweeps?.rulesUrl ??
                      `https://${process.env.REACT_APP_RULES_BUCKET}/${surveyId}`.replace(
                        "set.live",
                        "set.fan",
                      ),
                  )
                }
              >
                <ViewContestRules>View Sweepstakes Rules</ViewContestRules>
                <SvgImportLarge
                  color="#1B0076"
                  style={{
                    marginLeft: "0.25rem",
                    transform: "translateY(3px)",
                  }}
                  height={17}
                />
              </ViewContestRulesContainer>
            </EligibleContactsContainer>
            <FiltersContainer>
              <GeographicFilter
                limitToUS={limitToUS}
                setLimitToUS={setLimitToUS}
                disableSelection={forceUS}
              />
              <FilterDivider />
              <NumberOfWinnersFilter
                eligibleContacts={responses}
                numberOfWinners={numberOfWinners}
                setNumberOfWinners={setNumberOfWinners}
              />
              <FindWinnersButton
                onClick={async () =>
                  await runNewSweeps({ numberOfWinners, limitToUS })
                }
                disabled={numberOfWinners < 1}
              >{`Find ${pluralize(
                numberOfWinners,
                "Winner",
              )}`}</FindWinnersButton>
            </FiltersContainer>
          </SweepsSearchContainer>
        </InnerCard>
      </StyledCard>
    </>
  );
};

export default SweepsSearch;

const FindWinnersButton = styled(Button)`
  width: 100%;

  ${({ theme }) => theme.media.desktop} {
    width: 192px;
    align-self: flex-end;
    margin-top: auto;
  }
`;

const SweepsSearchContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.desktop} {
    flex-direction: row;
    justify-content: spaced-between;
  }
`;

const GeographicFilter = ({
  disableSelection,
  limitToUS,
  setLimitToUS,
  isLoading,
}: {
  disableSelection?: boolean;
  limitToUS: boolean;
  setLimitToUS: Dispatch<SetStateAction<boolean>>;
  isLoading?: boolean;
}) => {
  return (
    <FlexRow style={{ justifyContent: "space-between", alignItems: "center" }}>
      <FlexColumn>
        <FiltersLabelText>
          {isLoading ? <Skeleton /> : "Limit to United States Only"}
        </FiltersLabelText>
        <FiltersLabelSubtext>
          {isLoading ? (
            <Skeleton width={100} />
          ) : limitToUS ? (
            "Enabled"
          ) : (
            "Disabled"
          )}
        </FiltersLabelSubtext>
      </FlexColumn>
      {isLoading ? (
        <Skeleton width={75} height="1.5rem" />
      ) : (
        <Switch
          value={limitToUS}
          onChange={setLimitToUS}
          isDisabled={disableSelection}
          disabledTooltip={
            <>
              <div>In order to allow winners outside of the US,</div>
              <div> please supply your own rules for the sweepstakes</div>
            </>
          }
        />
      )}
    </FlexRow>
  );
};

const NumberOfWinnersFilter = ({
  eligibleContacts,
  numberOfWinners,
  setNumberOfWinners,
  isLoading,
}: {
  eligibleContacts: number;
  numberOfWinners: number;
  setNumberOfWinners: Dispatch<SetStateAction<number>>;
  isLoading?: boolean;
}) => {
  return (
    <FlexRow
      style={{
        justifyContent: "space-between",
        marginBottom: 15,
        alignItems: "center",
      }}
    >
      <FlexColumn>
        <FiltersLabelText>
          {isLoading ? <Skeleton width={100} /> : "Number of Winners"}
        </FiltersLabelText>
        <FiltersLabelSubtext>
          {isLoading ? (
            <Skeleton />
          ) : (
            `Out Of ${formatNumber(eligibleContacts)} Eligible Contact${
              eligibleContacts === 0 ? "" : "s"
            }`
          )}
        </FiltersLabelSubtext>
      </FlexColumn>
      {isLoading ? (
        <Skeleton width={100} height="2rem" />
      ) : (
        <Incrementer
          value={numberOfWinners}
          setValue={setNumberOfWinners}
          min={Math.min(eligibleContacts, 1)}
          max={eligibleContacts}
        />
      )}
    </FlexRow>
  );
};

const Incrementer = ({
  value,
  setValue,
  min,
  max,
}: {
  value: number;
  setValue: Dispatch<SetStateAction<number>>;
  min: number;
  max: number;
}) => {
  return (
    <FlexRow>
      <IncrementButtonContainer
        onClick={() => setValue(value > min ? value - 1 : min)}
        isDisabled={value === min}
      >
        <svg width="16" height="3" viewBox="0 0 16 3" fill="none">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 0.802734H15V1.80273H1V0.802734Z"
            fill="#999999"
            stroke="white"
          />
        </svg>
      </IncrementButtonContainer>
      <IncrementerValueContainer>{value}</IncrementerValueContainer>
      <IncrementButtonContainer
        onClick={() => setValue(value < max ? value + 1 : max)}
        isDisabled={value === max}
      >
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.5 1.21094V7.71094H15V8.71094H8.5V15.2109H7.5V8.71094H1V7.71094H7.5V1.21094H8.5Z"
            fill="black"
            stroke="white"
          />
        </svg>
      </IncrementButtonContainer>
    </FlexRow>
  );
};

const EligibleContactsContainer = styled.div`
  height: 216px;
  border-radius: 12px;
  border: 1px solid #e6e9eb;
  padding-top: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
  width: 100%;
  margin-bottom: 15px;

  ${({ theme }) => theme.media.desktop} {
    margin-right: 56px;
    padding-top: 50px;
  }
`;

const EligibleContactsNumber = styled.div`
  color: #1b0076;
  text-align: center;
  font-family: Poppins;
  font-size: 38px;
  font-weight: 600;
  line-height: 54px;
`;

const EligibleContactsSubtext = styled.div`
  color: #999;
  font-family: Poppins;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
`;

const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const FilterDivider = styled.div`
  height: 2px;
  background-color: #e6e9eb;
  margin-bottom: 13px;
  margin-top: 13px;
  width: 100%;
`;

const FiltersLabelText = styled.div`
  color: #596266;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;

  ${({ theme }) => theme.media.desktop} {
    font-size: 15px;
    line-height: 22px;
  }
`;

const FiltersLabelSubtext = styled.div`
  color: #596266;
  font-family: Poppins;
  font-size: 12px;
  line-height: 20px;

  ${({ theme }) => theme.media.desktop} {
    line-height: 22px;
  }
`;
const HeaderContainer = styled.div`
  height: 71px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const HeaderText = styled.div`
  text-align: center;
  font-family: Poppins;
  font-size: 26px;
  font-weight: 600;
  line-height: 36px;
`;

const IncrementButtonContainer = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  width: 30px;
  height: 30px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: ${({ isDisabled }) => (isDisabled ? "#e9e9e9" : "#1b0076")};
  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};

  ${({ theme }) => theme.media.desktop} {
    width: 40px;
    height: 40px;
  }
`;

const IncrementerValueContainer = styled.div`
  width: 59px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid #596266;
  background: #fff;
  margin-left: 2px;
  margin-right: 2px;

  display: flex;
  align-items: center;
  justify-content: center;
  color: #222;
  font-family: Overpass;
  font-size: 17px;
  line-height: normal;

  ${({ theme }) => theme.media.desktop} {
    height: 40px;
  }
`;

const InnerCard = styled.div`
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;

  ${({ theme }) => theme.media.desktop} {
    padding-left: 30px;
    padding-right: 30px;
  }
`;

const ViewContestRules = styled.div`
  color: #1b0076;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

const ViewContestRulesContainer = styled.div`
  display: flex;
  margin-top: auto;
  margin-bottom: 30px;
  cursor: pointer;
  margin-top: 10px;
`;
