import React from "react";
import { TextInput } from "Components";
import { Field, FieldProps } from "formik";
import { Section } from "Routes/SetPage/Components/Section";
import styled from "styled-components";
import { SetPageReleaseForm } from "@max/common/src/setpage";
import * as yup from "yup";
import { yupOptional, yupRequired } from "Utils/yup";
import { ReleaseList } from "./Components/ReleaseList";
import { doc, getFirestore, setDoc, Timestamp } from "firebase/firestore";
import { generateFirestoreId } from "Utils/generateFirestoreId";
import { ArtistRelease } from "@max/common/src/artists/presave";

export const RELEASE_VALIDATION_SHAPE = {
  type: yupRequired,
  upc: yupRequired.matches(/^\w{12,14}$/, "Please enter a valid UPC code"),
  isrc: yup.string().when("type", {
    is: "track_on_album",
    then: yupRequired.matches(
      /^[A-Z]{2}[A-Z0-9]{3}\d{2}\d{5}$/,
      "Please enter a valid ISRC code",
    ),
    otherwise: yupOptional.nullable(),
  }),
  date: yupRequired,
  title: yupRequired,
  description: yupOptional,
  image: yup.string().required("Please upload an image"),
  preSaveOn: yup.array().min(1, "At least one service is required"),
  allowFutureReleases: yup.bool().required(),
};

export const getReleaseInitialValues = (): Omit<
  SetPageReleaseForm,
  "source"
> => ({
  id: "",
  type: null,
  upc: "",
  isrc: "",
  date: null,
  title: "",
  description: "",
  image: "",
  preSaveOn: ["spotify", "apple_music"],
  allowFutureReleases: true,
  colorMode: "light" as const,
  createdAt: Timestamp.now(),
  updatedAt: Timestamp.now(),
});

export const deletePreSave = async (releaseId: string) => {
  const reference = doc(getFirestore(), `artist_releases/${releaseId}`);
  await setDoc(reference, { deletedAt: Timestamp.now() }, { merge: true });
};

export const saveRelease = async (
  release: SetPageReleaseForm,
  artistGroupId: string,
) => {
  const releaseId = release.id || generateFirestoreId();
  const { title, type, upc, isrc, image, date } = release;
  await setDoc(
    doc(getFirestore(), `artist_releases/${releaseId}`),
    {
      title,
      type,
      upc,
      isrc,
      image,
      releaseDate: date,
      ...(!release.id && {
        artistGroupId,
        completedAt: null,
        scheduledAt: null,
        deletedAt: null,
        colorMode: release.colorMode,
        saveFutureReleases: release.allowFutureReleases,
        createdAt: Timestamp.now(),
      }),
    } as Partial<ArtistRelease>,
    {
      merge: true,
    },
  );
  return releaseId;
};

export const saveReleaseDate = async (release: SetPageReleaseForm) => {
  await setDoc(
    doc(getFirestore(), `artist_releases/${release.id}`),
    {
      releaseDate: release.date,
    } as Partial<ArtistRelease>,
    {
      merge: true,
    },
  );
};

export const PreSaveTab: React.FC = () => {
  return (
    <Container>
      <Section style={{ marginBottom: 4 }}>
        <Field name="title">
          {({ field, meta }: FieldProps) => (
            <TextInput
              label="Section Title (optional)"
              placeholder="Enter title for this section"
              hasError={meta.touched && !!meta.error}
              helperText={meta.touched && meta.error}
              asString={false}
              {...field}
            />
          )}
        </Field>
      </Section>
      <ReleaseList source="setbio:section" />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 0;
`;
