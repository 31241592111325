import { SurveyBuilderConfig, SurveyTranslatableContent } from "@max/common";
import { randomBytes } from "crypto";

// https://github.com/googleapis/nodejs-firestore/blob/4f4574afaa8cf817d06b5965492791c2eff01ed5/dev/src/util.ts#L52
export function autoId(): string {
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let autoId = "";
  while (autoId.length < 20) {
    const bytes = randomBytes(40);
    // eslint-disable-next-line no-loop-func
    bytes.forEach((b) => {
      // Length of `chars` is 62. We only take bytes between 0 and 62*4-1
      // (both inclusive). The value is then evenly mapped to indices of `char`
      // via a modulo operation.
      const maxValue = 62 * 4 - 1;
      if (autoId.length < 20 && b <= maxValue) {
        autoId += chars.charAt(b % 62);
      }
    });
  }
  return autoId;
}

export const buildPreSaveContestMessage = (count: number = 1) =>
  `Pre-save now to earn ${count} bonus ${
    count === 1 ? "entry" : "entries"
  } for the sweepstakes.`;

export const buildPreSaveContestThankYouMessage = (count: number = 1) =>
  `Thanks for pre-saving! You have earned ${count} bonus ${
    count === 1 ? "entry" : "entries"
  } for the sweepstakes.`;

export const buildSpotifyPlaylistText = (count?: number) =>
  `Publish your setlist using the button below${
    count
      ? ` and earn ${count} bonus ${
          count === 1 ? "entry" : "entries"
        } for the sweepstakes!`
      : "."
  }`;

export const buildSpotifyPlaylistSuccessMessage = (count?: number) =>
  `Thanks for sharing your playlist!${
    count
      ? ` You have earned ${count} bonus ${
          count === 1 ? "entry" : "entries"
        } for the sweepstakes!`
      : ""
  }`;

export const buildSpotifyPlaylistFields = (
  setField: (
    fieldName: keyof SurveyBuilderConfig["fields"],
    content: SurveyTranslatableContent<string>,
  ) => void,
  extraSweepstakes?: number,
) => {
  setField("spotifyPlaylistText", {
    en: buildSpotifyPlaylistText(extraSweepstakes),
  });
  setField("spotifyPlaylistSuccessMessage", {
    en: buildSpotifyPlaylistSuccessMessage(extraSweepstakes),
  });
};
