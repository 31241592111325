import React from "react";
import { H4 } from "melodies-source/Text";
import styled, { css } from "styled-components";
import { SetPageRelease, SetPageReleaseForm } from "@max/common/src/setpage";
import { TextInput, Caption } from "Components/TextInput";
import { ReactComponent as FullAlbumIcon } from "assets/svg/release-full-album.svg";
import { ReactComponent as SingleIcon } from "assets/svg/release-single.svg";
import { ReactComponent as TrackOnAlbumIcon } from "assets/svg/release-track-on-album.svg";
import { ReactComponent as CheckIcon } from "assets/svg/check-filled.svg";
import { Field, FieldProps, useFormikContext } from "formik";

export const RELEASE_TYPES: Record<
  SetPageRelease["type"],
  { icon: React.ReactNode; label: string }
> = {
  full_album: {
    icon: <FullAlbumIcon />,
    label: "Full Album",
  },
  single: {
    icon: <SingleIcon />,
    label: "Single",
  },
  track_on_album: {
    icon: <TrackOnAlbumIcon />,
    label: "Track on Album",
  },
};

export const ReleaseTypeStep: React.FC = () => {
  const formik = useFormikContext<SetPageReleaseForm>();

  return (
    <Container>
      <H4>What are your fans pre-saving?</H4>
      <Field name="type">
        {({ field, form, meta }: FieldProps) => (
          <div>
            <ReleaseTypes>
              {(
                Object.entries(RELEASE_TYPES) as Entries<typeof RELEASE_TYPES>
              ).map(([type, { icon, label }]) => {
                const isSelected = type === field.value;
                const isAnySelected = field.value !== null;

                return (
                  <ReleaseType
                    key={type}
                    isSelected={isSelected}
                    isAnySelected={isAnySelected}
                    onClick={() => form.setFieldValue("type", type)}
                  >
                    {icon}
                    <H4>{label}</H4>
                    {isSelected && <StyledCheckIcon />}
                  </ReleaseType>
                );
              })}
            </ReleaseTypes>
            {meta.error && (
              <StyledCaption error>Please select a release type</StyledCaption>
            )}
          </div>
        )}
      </Field>
      {formik.values.type && (
        <Field name="upc">
          {({ field, meta }: FieldProps) => (
            <TextInput
              label={`Enter ${
                formik.values.type !== "track_on_album"
                  ? RELEASE_TYPES[formik.values.type].label
                  : "Album"
              } UPC Code`}
              placeholder="Enter Code"
              asString={false}
              hasError={meta.touched && !!meta.error}
              helperText={meta.touched && meta.error}
              {...field}
            />
          )}
        </Field>
      )}
      {formik.values.type === "track_on_album" && (
        <Field name="isrc">
          {({ field, meta }: FieldProps) => (
            <TextInput
              label={`Enter ${
                formik.values.type !== "track_on_album"
                  ? RELEASE_TYPES[formik.values.type].label
                  : "Track"
              } ISRC Code`}
              placeholder="Enter Code"
              asString={false}
              hasError={meta.touched && !!meta.error}
              helperText={meta.touched && meta.error}
              {...field}
            />
          )}
        </Field>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const ReleaseTypes = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

interface ReleaseTypeProps {
  isSelected: boolean;
  isAnySelected: boolean;
}

const ReleaseType = styled.div<ReleaseTypeProps>`
  position: relative;
  display: grid;
  grid-template-columns: 55px 1fr;
  align-items: center;
  gap: 15px;
  padding: 17px 20px 17px 10px;
  border-radius: 12px;
  background: #f2f5f7;
  box-shadow: 0px 2.818px 7.044px 0px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  border: 2px solid #f2f5f7;

  &:hover {
    background: #fff;
  }

  ${({ isSelected, isAnySelected }) =>
    isSelected
      ? css`
          border-color: #8017f6;

          svg {
            color: #8017f6;
          }
        `
      : !isAnySelected
      ? css`
          svg {
            color: #8017f6;
          }
        `
      : css`
          color: #999999;
          &:hover {
            border-color: #fff;
          }
        `}
  svg {
    margin-left: auto;
  }
`;

const StyledCheckIcon = styled(CheckIcon)`
  position: absolute;
  top: -10px;
  right: -10px;
  color: #8017f6;
  border: 1px solid white;
  border-radius: 50%;
`;

const StyledCaption = styled(Caption)`
  margin-top: 10px;
`;
