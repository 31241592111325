import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { Routes } from "./Routes";

import { UserProvider } from "auth";

import {
  AuthHandler,
  MenuProvider,
  ModalBackground,
  REDIRECT_PATHS,
  TimeProvider,
} from "Components";
import { DialogProvider } from "Components/DialogProvider";
import { Toaster } from "Components/Toaster";
import { Account } from "Routes/Account";
import { ArtistSelect } from "Routes/ArtistSelect";
import { OauthCallback } from "Routes/OauthCallback";
import { SessionRouter } from "Routes/Session";
import { SESSION_PATHS } from "Routes/Session/paths";
import { Theme } from "melodies-source/Theme";
import { GlobalStyles } from "styled";
import { ModalProvider } from "styled-react-modal";
import "./App.css";
import { ScrollLockProvider } from "contexts/ScrollLockContext";
import { PromptProvider } from "contexts/PromptContext";
import { CustomTheme } from "@max/thirdparty/types";
import { useCustomAppContext } from "contexts/CustomAppContext";
import { createPortal } from "react-dom";
import { AppLoading } from "melodies-source/AppLoading";
import { Signup } from "Routes/Signup";
import { Signout } from "Routes/Signout";
// ensure we call `addMethod` for our custom yelpers before using them in app
import "Utils/yup";
import { RingsRouter } from "Routes/Rings";

export interface AppThemeType {
  media?: {
    mobile: string;
    tablet: string;
    maxTablet: string;
    desktop: string;
  };
  custom?: CustomTheme;
}

export const appTheme: AppThemeType = {
  media: {
    mobile: "@media all and (max-width: 768px)",
    tablet: "@media all and (min-width: 769px) and (max-width: 1263px)",
    maxTablet: "@media all and (max-width: 1263px)",
    desktop: "@media all and (min-width: 769px)",
  },
};

function App() {
  const { customApp } = useCustomAppContext();

  if (customApp) {
    appTheme.custom = customApp.theme;
  }

  return (
    ///@ts-ignore
    <Theme theme={appTheme}>
      <GlobalStyles />
      {createPortal(<AppLoading />, document.getElementById("loader"))}
      <TimeProvider>
        <ScrollLockProvider>
          <ModalProvider backgroundComponent={ModalBackground}>
            <Router>
              <PromptProvider>
                <UserProvider>
                  <Switch>
                    <Route
                      component={OauthCallback}
                      path={"/oauth/callback/:platform"}
                    />
                    <Route
                      component={SessionRouter}
                      path={SESSION_PATHS.ROOT}
                    />
                    <Route component={RingsRouter} path="/rings" />
                    <Route>
                      <MenuProvider>
                        <AuthHandler>
                          <DialogProvider>
                            <Switch>
                              {customApp?.routes && (
                                <Route
                                  path={customApp.routes.paths}
                                  render={() => <customApp.routes.Component />}
                                  exact
                                />
                              )}
                              <Route component={Signout} path={"/signout"} />
                              <Route
                                component={ArtistSelect}
                                exact
                                path={["/", ...REDIRECT_PATHS]}
                              />
                              <Route component={Account} path={"/account"} />
                              <Route component={Signup} path={"/onboard"} />
                              <Route component={Routes} path={"/:artistId"} />
                            </Switch>
                            <Toaster />
                          </DialogProvider>
                        </AuthHandler>
                      </MenuProvider>
                    </Route>
                  </Switch>
                </UserProvider>
              </PromptProvider>
            </Router>
          </ModalProvider>
        </ScrollLockProvider>
      </TimeProvider>
    </Theme>
  );
}

export default App;
