import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { useMobileMediaQuery } from "hooks/useMobileMediaQuery";
import { Body2, H1, H2, H3 } from "melodies-source/Text";
import { FlexColumn } from "Routes/styled";
import { DateTime } from "luxon";
import { cityStateFromAddress } from "Utils";
import { SvgBack as BackIcon } from "melodies-source/Svgs/Back";
import { Stats } from "./Stats";
import { useArtistEventContext } from "contexts/ArtistEventContext";
import { Spinner } from "melodies-source/Spinner";
import { StatsMobile } from "./StatsMobile";
import { DownloadCsv } from "../Components/DownloadCsv";
import {
  doc,
  DocumentReference,
  getDoc,
  getFirestore,
} from "firebase/firestore";
import { useDesktopMediaQuery } from "hooks/useDesktopMediaQuery";
import { EventConfigRoot } from "@max/common";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { AdditionalGroupsAvatars } from "Components/AdditionalGroups";

let Venue;
let Details;

export const Info: React.FC = () => {
  const [isMultiDayEvent, setIsMultiDayEvent] = useState(false);
  const { event, eventLoading } = useArtistEventContext();
  const eventIsVersion2 = event?.version === 2;
  const isMobile = useMobileMediaQuery();
  const isDesktop = useDesktopMediaQuery(1000);
  const { artistId } = useParams<{ artistId: string }>();

  const [setFreshEvent] = useDocumentData(
    eventIsVersion2
      ? (doc(
          getFirestore(),
          `set_fresh_events/${event._id}`,
        ) as DocumentReference<EventConfigRoot>)
      : null,
  );

  useEffect(() => {
    if (event?._id) {
      const ref = doc(getFirestore(), `set_fresh_events_multiday/${event._id}`);

      getDoc(ref).then((doc) => {
        if (doc.exists()) {
          setIsMultiDayEvent(true);
        }
      });
    }
  }, [event?._id]);

  Venue = isMobile ? H2 : H1;
  Details = isMobile ? Body2 : H3;

  return (
    <Container>
      <ActionsContainer>
        <BackLink to={`/${artistId}/reporting/set-live`}>
          <BackIcon /> SET.Live Reports
        </BackLink>
        {eventIsVersion2 && isDesktop && (
          <DownloadCsv isMultiDayEvent={isMultiDayEvent} />
        )}
      </ActionsContainer>
      <MainContainer>
        <InfoContainer>
          <FlexColumn>
            {eventLoading ? (
              <Spinner />
            ) : (
              <>
                <TitleContainer>
                  <Venue>{event.venue}</Venue>
                  {!!setFreshEvent?.additionalGroups?.length && (
                    <AdditionalGroupsAvatars
                      ids={setFreshEvent.additionalGroups}
                    />
                  )}
                </TitleContainer>
                <Details>
                  {`
              ${
                DateTime.fromMillis(event.startsAt.toMillis())
                  .toLocaleString(DateTime.DATETIME_FULL)
                  .split(" at ")[1]
              } • ${DateTime.fromMillis(
                    event.startsAt.toMillis(),
                  ).toLocaleString(DateTime.DATE_MED)}`}
                </Details>
                <Details>
                  {cityStateFromAddress(event.address || "") || "Unknown"}
                </Details>{" "}
              </>
            )}
          </FlexColumn>
          {eventIsVersion2 && !isDesktop && (
            <StyledDownloadCsv isMultiDayEvent={isMultiDayEvent} />
          )}
        </InfoContainer>
        {eventIsVersion2 && (
          <StatsContainer>
            {!isDesktop ? <StatsMobile /> : <Stats />}
          </StatsContainer>
        )}
      </MainContainer>
    </Container>
  );
};

const StatsContainer = styled.div``;

const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;

  h1,
  h2 {
    color: var(--header-text-color);
    margin-bottom: 5px;
  }

  h1 {
    line-height: 1.2;
    margin-bottom: 5px;
  }

  h2 {
    line-height: 1;
  }

  h3 {
    color: var(--text-color);
  }

  ${({ theme }) => theme.media.mobile} {
    & > button {
      margin-top: 12px;
      width: 100%;
      max-width: 100%;
      justify-content: center;
      gap: 10px;
    }
  }
`;

const MainContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  @media all and (max-width: 1000px) {
    flex-direction: column;
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BackLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: var(--main-color);
  font-weight: 500;
`;

const Container = styled.div``;

const StyledDownloadCsv = styled(DownloadCsv)`
  @media (max-width: 1000px) {
    margin-top: 15px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  ${(p) => p.theme.media.mobile} {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 5px;
  }
`;
