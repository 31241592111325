import { StackedModalProvider } from "Components/ModalStack";
import {
  ResponsiveFooterActions,
  ResponsiveModal,
} from "Components/ResponsiveModal";
import { Form, Formik } from "formik";
import { Button } from "melodies-source/Button";
import { ReleaseList } from "Routes/SetPage/Components/Modal/PreSaveReleaseModal/PreSaveTab/Components/ReleaseList";
import { useTheme } from "styled-components";
import * as yup from "yup";
import { useTemplates } from "./TemplateContext";

const VALIDATION_SCHEMA = yup.object().shape({
  release: yup.mixed().required("Please select a Pre-Save"),
});

export const SelectPreSaveReleaseModal = () => {
  const { colors } = useTheme();
  const { selectedPreSaveRelease, setSelectedPreSaveRelease, modal, setModal } =
    useTemplates();

  return (
    <StackedModalProvider>
      <ResponsiveModal
        isOpen={modal.selectRelease}
        variant="large"
        header="Pre-Save Release"
      >
        <Formik
          validationSchema={VALIDATION_SCHEMA}
          initialValues={{
            release: selectedPreSaveRelease,
          }}
          onSubmit={(values) => {
            setSelectedPreSaveRelease(values.release);
            setModal({ aiPrompt: true });
          }}
        >
          <Form>
            <ReleaseList source="setfan" showTitle={false} />
            <ResponsiveFooterActions>
              <Button
                type="button"
                variant="tertiary"
                onClick={() => {
                  setSelectedPreSaveRelease(undefined);
                  setModal({ selectTemplate: true });
                }}
                style={{ color: colors.main }}
              >
                Back
              </Button>
              <Button type="submit" variant="primary">
                Next
              </Button>
            </ResponsiveFooterActions>
          </Form>
        </Formik>
      </ResponsiveModal>
    </StackedModalProvider>
  );
};
