import React, { useEffect, useState } from "react";
import {
  and,
  collection,
  getFirestore,
  Query,
  query,
  QuerySnapshot,
  where,
} from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import styled from "styled-components";
import { UpcomingReleaseCard } from "./UpcomingReleaseCard";
import { H3 } from "melodies-source/Text";
import { Button } from "melodies-source/Button";
import { AddPreSaveModal } from "../../Modals/AddPreSaveModal";
import { useFormikContext } from "formik";
import {
  SetPagePreSaveReleaseModule,
  SetPageRelease,
  SetPageReleaseForm,
} from "@max/common/src/setpage";
import { AnimatedCaret } from "Routes/styled";
import { getReleaseInitialValues } from "../..";
import { ArchivedReleasedCard } from "./ArchivedReleaseCard";
import { Spinner } from "melodies-source/Spinner";
import { SetPageFormValues } from "Routes/SetPage/types";
import _get from "lodash/get";
import { useFormChangesContext } from "contexts/FormChangesContext";
import { Caption, useArtistContext } from "Components";
import { ArtistRelease, PresaveSource } from "@max/common/src/artists/presave";

interface Props {
  source: PresaveSource;
  showTitle?: boolean;
  showArchived?: boolean;
  onChange?: (release: SetPageRelease, action?: "select") => void;
}

export const getReleases = (
  artistReleasesSnapshot: QuerySnapshot<ArtistRelease>,
  selectedRelease: SetPageRelease | null,
  source: PresaveSource,
) => {
  const releases: SetPageReleaseForm[] =
    artistReleasesSnapshot?.docs
      .filter((doc) => doc.data().image)
      .map((doc) => {
        const {
          title,
          releaseDate,
          type,
          upc,
          isrc,
          image,
          colorMode,
          saveFutureReleases,
        } = doc.data();

        return doc.id === selectedRelease?.id
          ? ({ ...selectedRelease, date: releaseDate } as SetPageReleaseForm)
          : ({
              ...getReleaseInitialValues(),
              id: doc.id,
              title,
              upc,
              isrc: isrc || "",
              image,
              type,
              date: releaseDate,
              source,
              allowFutureReleases: saveFutureReleases,
              ...(colorMode && { colorMode }),
            } as SetPageReleaseForm);
      }) || [];

  const sortedReleases = [...releases].sort(
    (a, b) => a.date.toMillis() - b.date.toMillis(),
  );
  const upcomingReleases = sortedReleases.filter(
    (release) => release.date.toMillis() > Date.now(),
  );
  const archivedReleases = sortedReleases
    .filter((release) => release.date.toMillis() <= Date.now())
    .reverse();
  return { upcomingReleases, archivedReleases };
};

export const ReleaseList: React.FC<Props> = ({
  source,
  showTitle = true,
  showArchived = true,
  onChange,
}) => {
  const [addPreSaveModalIsOpen, setAddPreSaveModalIsOpen] = useState(false);
  const [archivedPreSavesOpen, setArchivedPreSavesOpen] = useState(false);
  const moduleFormik = useFormikContext<SetPagePreSaveReleaseModule>();
  const mainFormik = useFormikContext<SetPageFormValues>();
  const { id: artistGroupId } = useArtistContext();
  const [artistReleases, artistReleasesLoading] = useCollection(
    query(
      collection(getFirestore(), "artist_releases"),
      and(where("artistGroupId", "==", artistGroupId)),
    ) as Query<ArtistRelease>,
  );
  const isTakeover = source === "setbio:takeover";

  const { upcomingReleases, archivedReleases } = getReleases(
    artistReleases,
    isTakeover
      ? mainFormik.values.page.takeoverPromotions.pre_save_release?.release
      : moduleFormik.values.release,
    source,
  );

  const formChanges = useFormChangesContext();

  const releaseFormPath = isTakeover
    ? "page.takeoverPromotions.pre_save_release.release"
    : "release";
  const formik = isTakeover ? mainFormik : moduleFormik;

  const toggleAddPreSaveModalOpen = () =>
    setAddPreSaveModalIsOpen((open) => !open);

  const handleReleaseSelect = (release: SetPageRelease) => {
    formik.setFieldValue(releaseFormPath, release);
    if (isTakeover) {
      formChanges?.compareChanges(releaseFormPath, release);
    }
    onChange?.(release, "select");
  };

  useEffect(() => {
    if (
      upcomingReleases.length === 1 &&
      !_get(formik.values, releaseFormPath)
    ) {
      formik.setFieldValue(releaseFormPath, upcomingReleases[0]);
      onChange?.(upcomingReleases[0]);
    }
  }, [upcomingReleases.length, onChange]);

  const touched = _get(formik.touched, releaseFormPath);
  const error = _get(formik.errors, releaseFormPath);

  return artistReleasesLoading ? (
    <Spinner />
  ) : (
    <Container>
      {upcomingReleases.length > 1 && showTitle && (
        <H3>Choose one of these pre-saves:</H3>
      )}
      {upcomingReleases.map((release) => (
        <UpcomingReleaseCard
          key={release.upc}
          release={release}
          isSelected={_get(formik.values, releaseFormPath)?.id === release.id}
          onSelect={() => handleReleaseSelect(release)}
          isTakeover={source === "setbio:takeover"}
          onChange={onChange}
        />
      ))}
      {upcomingReleases.length ? (
        <AddPreSaveButtonText onClick={toggleAddPreSaveModalOpen} text>
          Add Another Pre-Save
        </AddPreSaveButtonText>
      ) : (
        <AddPreSaveButton onClick={toggleAddPreSaveModalOpen}>
          + Add a Pre-Save
        </AddPreSaveButton>
      )}
      {touched && error && <Caption error>{error}</Caption>}
      <AddPreSaveModal
        key={`add-pre-save-modal-${addPreSaveModalIsOpen}`}
        isOpen={addPreSaveModalIsOpen}
        onClose={toggleAddPreSaveModalOpen}
        source={source}
        onChange={onChange}
      />
      {!isTakeover && archivedReleases.length > 0 && showArchived && (
        <>
          <HeadingWithCarret
            onClick={() => setArchivedPreSavesOpen((open) => !open)}
          >
            <AnimatedCaret
              direction={archivedPreSavesOpen ? "up" : "down"}
              $withTransition={false}
            />
            <H3>Archived Pre-Saves ({archivedReleases.length})</H3>
          </HeadingWithCarret>
          {archivedPreSavesOpen &&
            archivedReleases.map((release) => (
              <ArchivedReleasedCard key={release.id} release={release} />
            ))}
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  ${H3} {
    color: var(--main-color);
  }
`;

const AddPreSaveButtonText = styled(Button)`
  width: fit-content;
  margin: auto;
  padding: 0;
  text-decoration: underline;
  height: auto;
`;

const AddPreSaveButton = styled(Button)`
  width: 100%;
  max-width: 390px;
  margin: 20px auto;
`;

const HeadingWithCarret = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 10px;
  color: var(--main-color);
  cursor: pointer;
`;
