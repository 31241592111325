import { buildApi } from "@max/common/dist/api";
import { getAuth, getIdToken } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";

export const api = buildApi({
  fetchMap: {
    "artist.resolver": `https://${process.env.REACT_APP_ACCOUNT_PROXY_SEARCH_SERVER}`,
    "artist.start": `https://${process.env.REACT_APP_ACCOUNTS_PROXY_SEARCH_SERVER}`,
    "search.youtube": `https://${process.env.REACT_APP_YOUTUBE_PROXY_SEARCH_SERVER}`,
    "search.streaming": `https://${process.env.REACT_APP_STREAMING_PROXY_SEARCH_SERVER}`,
    "ai.handle": `https://${process.env.REACT_APP_AI_SUBDOMAIN_SERVER}`,
    "search.metamerch": `https://${process.env.REACT_APP_METAMERCH_PROXY_SEARCH_SERVER}`,
    "touring.search": `https://${process.env.REACT_APP_TICKETING_ACCOUNT_SEARCH_SERVER}`,
    "touring.artist": `https://${process.env.REACT_APP_TICKETING_SHOW_IMPORT_SERVER}`,
    "touring.remove": `https://${process.env.REACT_APP_TICKETING_SHOW_REMOVE_SERVER}`,
    "portal.presave:release": "/api/presave/release",
  },
  getToken: async () => {
    const auth = getAuth();
    if (auth.currentUser) {
      return getIdToken(auth.currentUser);
    }
    console.warn(
      "An attempt is being made to call an API endpoint before we have an authenticated user",
    );
    return "";
  },
  httpsCallable: (name) => httpsCallable(getFunctions(), name),
});
