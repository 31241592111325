import React, { useState } from "react";
import { H4 } from "melodies-source/Text";
import styled from "styled-components";
import { Caption, Label, TextInput } from "Components";
import { Field, FieldArray, FieldProps, useFormikContext } from "formik";
import { Textarea } from "melodies-source/Textarea";
import { ImageField } from "Routes/SetPage/Components/ImageField";
import { Checkbox } from "melodies-source/Selectable";
import { SetPageReleaseForm } from "@max/common/src/setpage";
import { Datepicker } from "Components/DatePicker";
import { getDateTime, getTimestamp } from "Utils/date";
import { DateTime } from "luxon";
import { RELEASE_TYPES } from "../../Modals/AddPreSaveModal/ReleaseTypeStep";
import { SwitchField } from "../SwitchField";
import { getColorMode } from "Routes/SetPage/getColorMode";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { doc, DocumentReference, getFirestore } from "firebase/firestore";
import { ArtistRelease } from "@max/common/src/artists/presave";

const SERVICES = {
  spotify: "Spotify",
  apple_music: "Apple Music",
};

interface Props {
  mode: "add" | "edit";
  releaseId?: string;
}

export const ReleaseDetailForm: React.FC<Props> = ({ mode, releaseId }) => {
  const formik = useFormikContext<SetPageReleaseForm>();
  const [artistRelease, artistReleaseLoading] = useDocumentData(
    releaseId &&
      (doc(
        getFirestore(),
        `artist_releases/${releaseId}`,
      ) as DocumentReference<ArtistRelease>),
  );
  const [
    isDisabledDatepickerMessageVisible,
    setIsDisabledDatepickerMessageVisible,
  ] = useState(false);

  const releaseType = formik.values.type;
  const releaseTypeLabel = RELEASE_TYPES[releaseType].label;
  const isDatepickerDisabled =
    mode === "edit" &&
    (artistReleaseLoading ||
      !!artistRelease?.apple?.scheduledAt ||
      !!artistRelease?.spotify?.scheduledAt);

  const toggleDisabledDatepickerMessageVisible = () =>
    setIsDisabledDatepickerMessageVisible((visible) => !visible);

  const handleImageUploaded = async (url: string) => {
    const colorMode = await getColorMode(url);
    formik.setFieldValue("colorMode", colorMode);
  };

  return (
    <Container>
      {mode === "add" && <H4>Tell us more about this release</H4>}
      <Field name="title">
        {({ field, meta }: FieldProps) => (
          <TextInput
            label="Release Title"
            placeholder="Enter the release title"
            asString={false}
            hasError={meta.touched && !!meta.error}
            helperText={meta.touched && meta.error}
            {...field}
          />
        )}
      </Field>
      {mode === "edit" && (
        <>
          <Field name="upc">
            {({ field, meta }: FieldProps) => (
              <TextInput
                label={`${releaseTypeLabel} UPC code`}
                placeholder="Enter Code"
                asString={false}
                hasError={meta.touched && !!meta.error}
                helperText={meta.touched && meta.error}
                disabled={mode === "edit"}
                {...field}
              />
            )}
          </Field>
          {releaseType === "track_on_album" && (
            <Field name="isrc">
              {({ field, meta }: FieldProps) => (
                <TextInput
                  label={`${releaseTypeLabel} ISRC Code`}
                  placeholder="Enter Code"
                  asString={false}
                  hasError={meta.touched && !!meta.error}
                  helperText={meta.touched && meta.error}
                  disabled={mode === "edit"}
                  {...field}
                />
              )}
            </Field>
          )}
          <Field name="date">
            {({ field }: FieldProps) => (
              <DatepickerContainer
                onMouseEnter={
                  isDatepickerDisabled
                    ? toggleDisabledDatepickerMessageVisible
                    : undefined
                }
                onMouseLeave={
                  isDatepickerDisabled
                    ? toggleDisabledDatepickerMessageVisible
                    : undefined
                }
              >
                <Datepicker
                  label="Release Date and Time"
                  placeholder="Select..."
                  value={field.value && getDateTime(field.value)}
                  onChange={(date) =>
                    formik.setFieldValue("date", getTimestamp(date))
                  }
                  minDate={DateTime.now()}
                  disabled={isDatepickerDisabled}
                  {...(isDisabledDatepickerMessageVisible && {
                    helperText:
                      "The pre-save process has been scheduled and can no longer be edited.",
                  })}
                />
              </DatepickerContainer>
            )}
          </Field>
        </>
      )}
      <Field name="description">
        {({ field, meta }: FieldProps) => (
          <StyledTextarea
            label="Description (optional)"
            placeholder="Enter a description"
            asString={false}
            hasError={meta.touched && !!meta.error}
            helperText={meta.touched && meta.error}
            {...field}
          />
        )}
      </Field>
      <ImageField
        name="image"
        label="Upload an image"
        afterOnComplete={handleImageUploaded}
        aspectRatio={1}
        withAspectRatio
      />
      <div>
        <Label>Pre-Save on:</Label>
        <FieldArray name="preSaveOn">
          {(helpers) => (
            <>
              {(Object.entries(SERVICES) as Entries<typeof SERVICES>).map(
                ([serviceValue, serviceLabel]) => (
                  <Checkbox
                    key={serviceValue}
                    label={serviceLabel}
                    value={formik.values.preSaveOn.includes(serviceValue)}
                    onChange={(checked) =>
                      checked
                        ? helpers.push(serviceValue)
                        : helpers.remove(
                            formik.values.preSaveOn.findIndex(
                              (service) => service === serviceValue,
                            ),
                          )
                    }
                  />
                ),
              )}
            </>
          )}
        </FieldArray>
        {formik.touched.preSaveOn && formik.errors.preSaveOn && (
          <Caption error>{formik.errors.preSaveOn}</Caption>
        )}
      </div>
      <SwitchField
        name="allowFutureReleases"
        label="Allow Fans to Save Future Releases"
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  input {
    font-weight: 400;
  }
`;

const DatepickerContainer = styled.div`
  label + div + p {
    color: var(--text-color) !important;
  }
`;

const StyledTextarea = styled(Textarea)`
  textarea {
    ::placeholder {
      color: #999999;
      font-family: var(--max-font-primary);
      font-weight: 400;
      font-size: 12px;
      line-height: normal;
      user-select: none;
    }
  }
`;
