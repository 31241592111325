import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useArtistContext } from "Components";
import IntegrationCard from "../IntegrationCard";
import RivetHeader from "./rivet-header.svg";
import RivetLogo from "./RivetLogo";
import styled, { CSSProperties } from "styled-components";
import { DateTime } from "luxon";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { Button } from "melodies-source/Button";
import { useDesktopMediaQuery } from "hooks";
import { RivetIntegrationStatus } from "./types";
import { RivetIntegrationModal, RivetRemovalModal } from "./RivetModals";
import LastSyncIcon from "../LastSyncIcon";
import { Ref } from "../../../../../refs";

const RivetIntegration = ({ sx }: { sx?: CSSProperties }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [integrationStatus, setIntegrationStatus] =
    useState<RivetIntegrationStatus>(RivetIntegrationStatus.NOT_CONNECTED);
  const [removalModalOpen, setRemovalModalOpen] = useState(false);

  const { id: artistGroupId } = useArtistContext();

  const [rivetDoc] = useDocumentData(
    Ref("artist_groups/{groupId}/artist_group_private/rivet", {
      groupId: artistGroupId,
    }),
  );

  const lastSynced: DateTime | undefined = rivetDoc?.lastSynced
    ? DateTime.fromMillis(rivetDoc.lastSynced.toMillis())
    : undefined;

  useEffect(() => {
    if (!!rivetDoc?.secretPath) {
      setIntegrationStatus(RivetIntegrationStatus.CONNECTED);
    } else {
      setIntegrationStatus(RivetIntegrationStatus.NOT_CONNECTED);
    }
  }, [rivetDoc]);

  return (
    <>
      <RivetIntegrationModal
        artistGroupId={artistGroupId}
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
      />
      <RivetRemovalModal
        artistGroupId={artistGroupId}
        onClose={() => {
          setRemovalModalOpen(false);
          setIntegrationStatus(RivetIntegrationStatus.NOT_CONNECTED);
        }}
        isOpen={removalModalOpen}
      />
      <RivetIntegrationCard
        integrationStatus={integrationStatus}
        lastSynced={lastSynced}
        onRemoveRivet={() => setRemovalModalOpen(true)}
        setModalOpen={setModalOpen}
        sx={sx}
      />
    </>
  );
};

export default RivetIntegration;

const RivetIntegrationCard = ({
  integrationStatus,
  lastSynced,
  onRemoveRivet,
  setModalOpen,
  sx,
}: {
  integrationStatus: RivetIntegrationStatus;
  lastSynced?: DateTime;
  onRemoveRivet: () => void;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  sx?: CSSProperties;
}) => {
  const isDesktop = useDesktopMediaQuery();

  if (integrationStatus === RivetIntegrationStatus.CONNECTED) {
    return (
      <IntegrationCard
        header={<img src={RivetHeader} alt="rivet logo" />}
        body={
          <>
            <div style={{ display: "flex", alignItems: "center" }}>
              <LastSyncIcon />
              <StatusText isDesktop={isDesktop}>
                Last Sync Time:{" "}
                {lastSynced
                  ? lastSynced.toLocaleString(DateTime.TIME_SIMPLE)
                  : "Never Synced"}
              </StatusText>
            </div>
            <BodyText>You're connected to Rivet!</BodyText>
          </>
        }
        footer={
          <FooterText
            style={{ marginTop: 9, textAlign: "center" }}
            onClick={onRemoveRivet}
          >
            Remove Rivet
          </FooterText>
        }
        sx={sx}
      />
    );
  }

  // rivet is not yet connected
  return (
    <IntegrationCard
      header={<img src={RivetHeader} alt="rivet logo" />}
      footer={
        <Button onClick={() => setModalOpen(true)}>
          <span style={{ marginRight: "0.35rem" }}>Connect to</span>
          <RivetLogo fill="white" />
        </Button>
      }
      body="SET can automatically sync your contacts to your Rivet account. Click the button below to get started."
      sx={sx}
    />
  );
};

const BodyText = styled.div`
  font-size: 10px;
  line-height: 170%;
  font-weight: 400;
  text-align: center;
  color: #666;
`;

const FooterText = styled.div`
  color: #180171;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0px;
  margin: 0 auto;
  padding-bottom: 5px;
  cursor: pointer;
  bottom: 0;
`;

const StatusText = styled.div<{ isDesktop: boolean }>`
  color: #635bff;
  font-weight: 500;
  font-size: ${({ isDesktop }) => (isDesktop ? "min(1.3vw, 12px)" : "13px")};
  line-height: 19.5px;
  margin-left: 0.25rem;
`;
