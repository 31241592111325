export const getImageBrightness = async (imageUrl: string) => {
  const response = await fetch(imageUrl);
  const blob = await response.blob();
  const image = await createImageBitmap(blob);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  canvas.width = image.width;
  canvas.height = image.height;

  ctx.drawImage(image, 0, 0);

  const top = canvas.height * 0.6666;
  const height = canvas.height - top;
  const data = ctx.getImageData(0, top, canvas.width, height);

  let sum = 0;
  let r: number;
  let g: number;
  let b: number;
  let avg: number;

  for (let x = 0, len = data.data.length; x < len; x += 4) {
    r = data.data[x];
    g = data.data[x + 1];
    b = data.data[x + 2];
    avg = Math.floor((r + g + b) / 3);
    sum += avg;
  }

  return Math.floor(sum / (canvas.width * height));
};
