import React from "react";
import { SetPageReleaseForm } from "@max/common/src/setpage";
import { Field, FieldProps, useFormikContext } from "formik";
import { H4 } from "melodies-source/Text";
import { Datepicker } from "Components/DatePicker";
import { DateTime } from "luxon";
import styled from "styled-components";
import { Caption } from "Components";
import { getDateTime, getTimestamp } from "Utils/date";

export const ReleaseDateStep: React.FC = () => {
  const formik = useFormikContext<SetPageReleaseForm>();

  return (
    <Container>
      <H4>What are your fans pre-saving?</H4>

      <div>
        <Field name="date">
          {({ field }: FieldProps) => (
            <Datepicker
              label="Pick a Date and Time"
              placeholder="Select..."
              value={field.value && getDateTime(field.value)}
              onChange={(date) =>
                formik.setFieldValue("date", getTimestamp(date))
              }
              minDate={DateTime.now()}
            />
          )}
        </Field>
        {formik.errors.date && (
          <StyledCaption error>Please select a date</StyledCaption>
        )}
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const StyledCaption = styled(Caption)`
  margin-top: 10px;
`;
