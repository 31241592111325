import { useEffect, useState } from "react";
import { useTypesenseDocuments } from "hooks/useTypesenseDocument";
import parsePhone from "libphonenumber-js";
import { useArtistContext } from "Components";
import { Sweepstakes } from "@max/common/dist/sweepstakes";

export type WinnerDetails = {
  age: string;
  fanId: string;
  contactId: string;
  submissionId: string;
  name: string;
  location: string;
  gender: string;
  email: string;
  phone: string;
  free_responses: string[];
  responses: string[];
  isRemoved: boolean;
  surveyId: string;
  sweepsId: string;
  conversationId: string;
  rawPhone: string;
  canSms: boolean;
};

/**
 * this hook consumes a sweeps document and finds the corresponding typesense doc
 * for the contact and submission collection for each user in the winners list and
 * returns a list of those winners details, in the same order
 * @returns
 */
const useSweepsWinnersDetails = (
  sweeps: Sweepstakes & { id: string },
  surveyId: string,
): { winnersDetails: WinnerDetails[]; loading: boolean } => {
  const { id: artistGroupId } = useArtistContext();
  const [winnersDetails, setWinnersDetails] = useState<WinnerDetails[]>([]);
  const [submissionIds, setSubmissionIds] = useState<string[]>([]);
  const [contactIds, setContactIds] = useState<string[]>([]);

  const [loading, setLoading] = useState(false);

  const { documents: submissionDocs, loading: loadingSubmissions } =
    useTypesenseDocuments({
      documentIds: submissionIds,
      collection: "submissions",
      params: {
        limit: sweeps.numberOfWinners,
        per_page: sweeps.numberOfWinners,
      },
    });

  const { documents: contactDocs, loading: loadingContacts } =
    useTypesenseDocuments({
      documentIds: contactIds,
      collection: "contacts",
      params: {
        limit: sweeps.numberOfWinners,
        per_page: sweeps.numberOfWinners,
      },
    });

  useEffect(() => {
    setSubmissionIds(sweeps.winners.map((winner) => `${surveyId}#${winner}`));
    setContactIds(sweeps.winners.map((winner) => `${artistGroupId}:${winner}`));
  }, [sweeps.winners, artistGroupId, surveyId]);

  useEffect(() => {
    setLoading(true);
    const updatedWinners: WinnerDetails[] = [];
    for (const winner of sweeps.winners) {
      const submissionDoc = submissionDocs?.find(
        (sDoc) => sDoc.id === `${surveyId}#${winner}`,
      );
      const contactDoc = contactDocs?.find(
        (cDoc) => cDoc.id === `${artistGroupId}:${winner}`,
      );

      if (!submissionDoc || !contactDoc) {
        continue;
      }

      const contactPhone = (() => {
        const isUsContact = contactDoc.country?.toLowerCase() === "us";
        if (isUsContact && contactDoc.phone) {
          return parsePhone(contactDoc.phone).formatNational();
        }
        if (!isUsContact && contactDoc.phone) {
          return parsePhone(contactDoc.phone).formatInternational();
        }
        return undefined;
      })();

      updatedWinners.push({
        age: contactDoc.age?.toString() ?? "unknown",
        canSms:
          !!contactPhone && submissionDoc.responses.includes("optIn#optInText"),
        contactId: `${artistGroupId}:${winner}`,
        conversationId: `${sweeps.id}#${winner}`,
        email: contactDoc.email,
        fanId: winner,
        free_responses: submissionDoc.free_responses ?? [],
        gender: contactDoc.gender ? genderLabels[contactDoc.gender] : "unknown",
        isRemoved: sweeps.removedFans?.some(
          (removedFan) => removedFan === winner,
        ),
        location: contactDoc.location ?? "unknown",
        name: contactDoc.name,
        phone: contactPhone ?? "unknown",
        rawPhone: contactDoc.phone,
        responses: submissionDoc.responses ?? [],
        submissionId: `${surveyId}#${winner}`,
        surveyId: surveyId,
        sweepsId: sweeps.id,
      });
    }
    setWinnersDetails(updatedWinners);
    setLoading(false);
  }, [sweeps, submissionDocs, surveyId, contactDocs, artistGroupId]);

  return {
    winnersDetails,
    loading: loading || loadingSubmissions || loadingContacts,
  };
};

export default useSweepsWinnersDetails;

const genderLabels = {
  male: "Male",
  female: "Female",
  notsay: "Prefer not to say",
  nonbinary: "Non-binary",
};
