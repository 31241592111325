import { useFormikContext } from "formik";
import { SetPageFormValues } from "../Routes/SetPage/types";
import { deepStrictEqual } from "assert";
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { SetPageModule, SetPageTakeoverPromotion } from "@max/common/setpage";

interface ChangedSections {
  appearance: boolean;
  takeover: boolean;
  pageContent: boolean;
  tracking: boolean;
}

interface CompareOptions {
  mergeValue?: boolean;
  overrideChangeKey?: string;
  rule?: "any";
}

type TakeoverType = SetPageTakeoverPromotion["type"];

interface Context {
  batchCompare: (
    entries: Record<
      string,
      {
        value: unknown;
        options?: CompareOptions;
      }
    >,
  ) => void;
  changedSections: ChangedSections;
  compareChanges: (
    changeKey: string,
    value?: unknown,
    options?: CompareOptions,
  ) => void;
  compareDependents: (
    changeKey: string,
    fields: {
      fieldName: string;
      value?: unknown;
      options?: Omit<CompareOptions, "overrideChangeKey">;
    }[],
  ) => void;
  compareEnableTakeover: (nowEnabled: boolean) => void;
  compareModuleChange: (
    module: SetPageModule,
    action: "change" | "remove",
    newIndex?: number,
  ) => void;
  compareTakeoverPromotion: (
    updates: Record<string, unknown>,
    takeoverType?: TakeoverType,
  ) => void;
  compareTakeoverType: (updatedTakeoverType: TakeoverType) => void;
  initialValues: SetPageFormValues;
  unsavedChanges: string[];
}

const FormChangesContext = createContext<Context>(null);

export const FormChangesProvider = ({
  children,
  unsavedChanges,
  setUnsavedChanges,
}: PropsWithChildren<{
  unsavedChanges: Set<string>;
  setUnsavedChanges: Dispatch<SetStateAction<Set<string>>>;
}>) => {
  const { initialValues: formikInitialValues, values: formikValues } =
    useFormikContext<SetPageFormValues>();

  const [changedSections, setChangedSections] = useState<ChangedSections>({
    appearance: false,
    takeover: false,
    pageContent: false,
    tracking: false,
  });

  useEffect(() => {
    findSectionChanges(unsavedChanges, setChangedSections);
  }, [unsavedChanges]);

  const addChange = (newChange: string) => {
    setUnsavedChanges((unsavedChanges) =>
      new Set(unsavedChanges).add(newChange),
    );
  };

  const removeChange = (changeToRemove: string) => {
    setUnsavedChanges((unsavedChanges) => {
      unsavedChanges.delete(changeToRemove);
      return new Set(unsavedChanges);
    });
  };

  const baseCompare = (
    changeKey: string,
    value: unknown,
    options?: CompareOptions,
  ) => {
    if (Array.isArray(value) && options?.rule) {
      return value.reduce(
        (eq: boolean, val) =>
          eq ||
          valuesAreEqual(
            resolveValue(changeKey, val, options),
            accessProperty(changeKey, formikInitialValues),
          ),
        false,
      );
    }
    return valuesAreEqual(
      resolveValue(changeKey, value, options),
      accessProperty(changeKey, formikInitialValues),
    );
  };

  const resolveValue = (
    changeKey: string,
    val?: unknown,
    options?: CompareOptions,
  ) => {
    const formikStateVal = accessProperty(changeKey, formikValues);
    // if we want to merge object values with formik state
    if (
      val &&
      options?.mergeValue &&
      typeof formikStateVal === "object" &&
      typeof val == "object"
    ) {
      return { ...formikStateVal, ...val };
    }

    return val ?? accessProperty(changeKey, formikValues);
  };

  const batchUpdateChanges = (
    changesToAdd: string[],
    changesToRemove: string[],
  ) => {
    setUnsavedChanges((unsavedChanges) => {
      const newUnsavedChanges = new Set(unsavedChanges);
      changesToAdd.forEach((change) => newUnsavedChanges.add(change));
      changesToRemove.forEach((change) => newUnsavedChanges.delete(change));
      return newUnsavedChanges;
    });
  };

  const compareChanges = (
    fieldName: string,
    value?: unknown,
    options?: CompareOptions,
  ): void => {
    const areEqual = baseCompare(fieldName, value, options);
    if (areEqual) {
      removeChange(options?.overrideChangeKey ?? fieldName);
    } else {
      addChange(options?.overrideChangeKey ?? fieldName);
    }
  };

  const batchCompare = (
    entries: Record<string, { value: unknown; options?: CompareOptions }>,
  ) => {
    let changesToAdd: string[] = [];
    let changesToRemove: string[] = [];

    Object.entries(entries).forEach(([changeKey, { value, options }]) => {
      const areEqual = baseCompare(changeKey, value, options);
      if (areEqual) {
        changesToRemove.push(options?.overrideChangeKey ?? changeKey);
      } else {
        changesToAdd.push(options?.overrideChangeKey ?? changeKey);
      }
    });

    batchUpdateChanges(changesToAdd, changesToRemove);
  };

  const compareDependents = (
    changeKey: string,
    fields: {
      fieldName: string;
      value?: unknown;
      options?: Omit<CompareOptions, "overrideChangeKey">;
    }[],
  ): void => {
    const allFieldAreUnchanged = fields.reduce(
      (eq, field) =>
        eq && baseCompare(field.fieldName, field.value, field.options),
      true,
    );

    if (allFieldAreUnchanged) {
      removeChange(changeKey);
    } else {
      addChange(changeKey);
    }
  };

  const compareEnableTakeover = (nowEnabled: boolean) => {
    mergeCompareTakeoverPromotions([
      {
        fieldName: "page.settings.enable_takeover_promotion",
        value: nowEnabled,
      },
    ]);
  };

  const compareModuleChange = (
    newModule: SetPageModule,
    action: "change" | "remove",
    newIndex?: number,
  ) => {
    if (action === "change") {
      compareChanges(`modules.${newIndex}`, newModule, {
        overrideChangeKey: `modules.${newModule.id}`,
      });
    }

    if (action === "remove") {
      const initialStateIndex = formikInitialValues.modules.findIndex(
        ({ id }) => id === newModule.id,
      );
      // module wasn't present in initial form state, remove any change for it.
      if (initialStateIndex === -1) {
        removeChange(`modules.${newModule.id}`);
      } else {
        addChange(`modules.${newModule.id}`);
      }
    }
  };

  const mergeCompareTakeoverPromotions = (
    fields?: { fieldName: string; value?: unknown }[],
  ) => {
    const defaultFieldsToCompare = [
      { fieldName: "page.takeoverType" },
      { fieldName: "page.settings.enable_takeover_promotion" },
      {
        fieldName: `page.takeoverPromotions.${formikValues.page.takeoverType}`,
      },
    ];

    const additionalFieldsToCompare =
      fields?.filter(
        (field) =>
          !defaultFieldsToCompare.find(
            ({ fieldName }) => fieldName === field.fieldName,
          ),
      ) ?? [];
    const mergedFields = defaultFieldsToCompare.map((defaultField) => {
      const override = fields?.find(
        ({ fieldName }) => fieldName === defaultField.fieldName,
      );
      return override ?? defaultField;
    });
    compareDependents("page.takeoverPromotions", [
      ...mergedFields,
      ...additionalFieldsToCompare,
    ]);
  };

  const compareTakeoverPromotion = (
    updates: Record<string, unknown>,
    takeoverType?: TakeoverType,
  ) => {
    const resolvedTakeoverType = takeoverType ?? formikValues.page.takeoverType;
    mergeCompareTakeoverPromotions([
      {
        fieldName: `page.takeoverPromotions.${resolvedTakeoverType}`,
        value: {
          ...formikValues.page.takeoverPromotions[resolvedTakeoverType],
          ...updates,
        },
      },
    ]);
  };

  const compareTakeoverType = (updatedTakeoverType: TakeoverType) => {
    mergeCompareTakeoverPromotions([
      {
        fieldName: "page.takeoverType",
        value: updatedTakeoverType,
      },
    ]);
  };

  const contextProps = {
    batchCompare,
    changedSections,
    compareChanges,
    compareDependents,
    compareEnableTakeover,
    compareModuleChange,
    compareTakeoverPromotion,
    compareTakeoverType,
    initialValues: formikInitialValues,
    unsavedChanges: Array.from(unsavedChanges),
  };

  return (
    <FormChangesContext.Provider value={contextProps}>
      {children}
    </FormChangesContext.Provider>
  );
};

export const useFormChangesContext = (): Context => {
  const context = useContext(FormChangesContext);

  if (context === undefined) {
    throw new Error(
      "useFormChangesContext must be used within a FormChangesProvider",
    );
  }

  return context;
};

const accessProperty = (path: string, obj?: unknown) =>
  path.split(".").reduce((a, b) => a?.[b], obj);

const findSectionChanges = (
  changes: Set<string>,
  setChangedSections: Dispatch<SetStateAction<ChangedSections>>,
) => {
  Object.entries(sectionPaths).forEach(([section, paths]) => {
    const match = paths?.find((path) => {
      for (const change of changes) {
        if (change.startsWith(path)) {
          return change;
        }
      }
      return false;
    });
    setChangedSections((changedSections) => ({
      ...changedSections,
      [section]: !!match,
    }));
  });
};

const sectionPaths: Record<keyof ChangedSections, string[]> = {
  appearance: ["page.theme", "page.color"],
  takeover: ["page.takeoverPromotions"],
  pageContent: ["modules", "page.header", "page.socials"],
  tracking: ["page.integrations"],
};

const valuesAreEqual = (newValue: unknown, initialValue: unknown): boolean => {
  try {
    deepStrictEqual(newValue, initialValue);
    return true;
  } catch (_) {
    return false;
  }
};
