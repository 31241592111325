import { findPreSaveSurveyConfigModule } from "@max/common";
import { Sweepstakes } from "@max/common/dist/sweepstakes";
import { useArtistContext } from "Components";
import {
  CollectionReference,
  DocumentReference,
  QueryFieldFilterConstraint,
  QueryLimitConstraint,
  QueryOrderByConstraint,
  QueryStartAtConstraint,
  addDoc,
  arrayUnion,
  arrayRemove,
  collection,
  doc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  serverTimestamp,
  startAfter,
  updateDoc,
  where,
} from "firebase/firestore";
import { addToast } from "melodies-source/Toast";
import { useEffect, useState } from "react";
import { useCollection, useDocumentData } from "react-firebase-hooks/firestore";
import { Ref } from "refs";

export const useSetfanSweeps = ({ surveyId }: { surveyId?: string }) => {
  const [activeSweeps, setActiveSweeps] = useState<
    (Sweepstakes & { id: string; ref: DocumentReference<Sweepstakes> }) | null
  >(null);
  const [runningSweeps, setRunningSweeps] = useState(true);
  const [additionalEntries, setAdditionalEntries] = useState(0);
  const [winnerBeingReplaced, setWinnerBeingReplaced] = useState<string>();
  const [sweepsCollection, loadingSweepsCollection] = useCollection(
    query(
      collection(
        getFirestore(),
        `sts3_surveys/${surveyId}/sweeps`,
      ) as CollectionReference<Sweepstakes>,
      orderBy("createdAt", "desc"),
      limit(1),
    ),
  );

  const [surveyProdConfig] = useDocumentData(
    Ref("sts3_surveys/{surveyId}/versions/{versionId}", {
      surveyId,
      versionId: "prod",
    }),
  );

  useEffect(() => {
    if (surveyProdConfig) {
      const presaveModule = findPreSaveSurveyConfigModule(
        surveyProdConfig.pages,
      );
      if (
        presaveModule &&
        typeof presaveModule.data.extraContestEntries === "number"
      ) {
        setAdditionalEntries(presaveModule.data.extraContestEntries);
      }
    }
  }, [surveyProdConfig]);

  const { logAction } = useArtistContext();

  useEffect(() => {
    if (
      !loadingSweepsCollection &&
      sweepsCollection?.docs?.length &&
      !["cancelled", "error"].includes(sweepsCollection.docs[0].data().status)
    ) {
      setActiveSweeps({
        ...sweepsCollection.docs[0].data(),
        id: sweepsCollection.docs[0].id,
        ref: sweepsCollection.docs[0].ref,
      });
    } else {
      setActiveSweeps(null);
    }

    setRunningSweeps(false);
  }, [sweepsCollection, loadingSweepsCollection]);

  const sweepsWinnersQuery = ({
    seed,
    entriesToQuery,
    limitToUS,
    dir,
  }: {
    seed: number;
    entriesToQuery: number;
    limitToUS: boolean;
    dir: "asc" | "desc";
  }) => {
    const predicates: (
      | QueryOrderByConstraint
      | QueryLimitConstraint
      | QueryStartAtConstraint
      | QueryFieldFilterConstraint
    )[] = [orderBy("seed", dir), startAfter(seed), limit(entriesToQuery)];

    if (limitToUS) {
      predicates.unshift(where("countryCode", "==", "US"));
    }

    return query(
      Ref("sts3_surveys/{surveyId}/sweeps_entries", { surveyId }),
      ...predicates,
    );
  };

  const generateWinners = async (props: {
    entriesToQuery: number;
    exclusionList?: string[];
    limitToUS: boolean;
    numberOfWinners: number;
  }) => {
    const seed = Math.random();
    try {
      const rawWinners = await Promise.all([
        getDocs(sweepsWinnersQuery({ ...props, seed, dir: "asc" })),
        getDocs(sweepsWinnersQuery({ ...props, seed, dir: "desc" })),
      ]);

      // combine queries and dedupe
      const combinedWinners = rawWinners.reduce((acc, sweepsEntryQuerySnap) => {
        sweepsEntryQuerySnap.docs.forEach((sweepsEntrySnap) => {
          if (!acc.some((win) => win === sweepsEntrySnap.data().fanId)) {
            // id is hashed email
            acc.push(sweepsEntrySnap.data().fanId);
          }
        });
        return acc;
      }, [] as string[]);

      // eliminate ids in the exclusion list as well as existing winners
      return combinedWinners
        .filter((win) => !props.exclusionList?.includes(win))
        .filter((win) => !activeSweeps?.winners.includes(win))
        .slice(0, props.numberOfWinners);
    } catch (err) {
      console.error(
        `there was an issue picking generating winners: ${
          (err as Error).message
        }`,
      );
      return [];
    }
  };

  const runNewSweeps = async (props: {
    numberOfWinners: number;
    limitToUS: boolean;
  }) => {
    setRunningSweeps(true);
    try {
      // handle additional entries for new multiple entry sweepstakes
      // maximum entries we could possibly need assuming we picked a lot of duplicates is
      // the number of winners for the sweeps x (1 + the maximum number of additional entries a fan can get)
      const entriesToQuery = props.numberOfWinners * (1 + additionalEntries);
      const winners = await generateWinners({ ...props, entriesToQuery });

      // if not enough candidates remaining, alert user and cancel sweeps
      if (winners.length < props.numberOfWinners) {
        addToast(
          "Unable to find enough winners for sweepstakes. Cancelling.",
          "error",
        );
        return;
      }

      const response = await addDoc(
        collection(getFirestore(), `sts3_surveys/${surveyId}/sweeps`),
        {
          surveyId,
          winners,
          numberOfWinners: props.numberOfWinners,
          limitToUS: props.limitToUS,
          createdAt: serverTimestamp(),
          status: "sampled",
        },
      );
      await logAction("portal_run_sweepstakes", { sweepsId: response.id });
    } catch (err) {
      console.error(`unable to run new sweeps: ${(err as Error).message}`);
    } finally {
      setRunningSweeps(false);
    }
  };

  const replaceWinner = async (replaceId: string) => {
    setWinnerBeingReplaced(replaceId);
    try {
      const existingWinners = activeSweeps.winners;
      const exclusionList = activeSweeps.fanExclusions ?? [];
      exclusionList.push(replaceId);

      const newWinners = await generateWinners({
        entriesToQuery: 1,
        numberOfWinners: 1,
        limitToUS: activeSweeps.limitToUS,
        exclusionList,
      });

      if (!newWinners.length) {
        addToast(
          "Unable to find a replacement for winner. This usually indicates there are no more viable contestants. Cancelling sweepstakes.",
          "error",
        );
        return await cancelCurrentSweeps();
      }

      const replaceIdx = existingWinners.findIndex((ew) => ew === replaceId);
      if (replaceIdx === -1) {
        console.error("there was a problem finding winner to replace");
        return;
      }

      existingWinners[replaceIdx] = newWinners[0];
      await updateDoc(activeSweeps.ref, {
        winners: existingWinners,
        fanExclusions: exclusionList,
        // if the fan being replaced is currently "removed", remove them from the list
        removedFans: arrayRemove(replaceId),
      });
      await logAction("portal_replace_sweepstakes", {
        surveyId,
        sweepsId: activeSweeps.id,
      });
    } catch (err) {
      console.error(
        `there was a problem replacing winner ${replaceId}: ${
          (err as Error).message
        }`,
      );
    } finally {
      setWinnerBeingReplaced("");
    }
  };

  const removeWinner = async (removeId: string) => {
    setWinnerBeingReplaced(removeId);
    try {
      await updateDoc(activeSweeps.ref, {
        removedFans: arrayUnion(removeId),
      });
    } catch (err) {
      console.error(`there was an error removing fan ${removeId}`);
    } finally {
      setWinnerBeingReplaced("");
    }
  };

  const cancelCurrentSweeps = async () => {
    try {
      await updateDoc(
        doc(
          getFirestore(),
          `sts3_surveys/${surveyId}/sweeps/${activeSweeps.id}`,
        ),
        { status: "cancelled", cancelledAt: serverTimestamp() },
      );
      await logAction("portal_cancel_sweepstakes", {
        surveyId,
        sweepsId: activeSweeps.id,
      });
    } catch (err) {
      console.error(
        `Unable to cancel current sweeps: ${(err as Error).message}`,
      );
    }
  };

  return {
    cancelCurrentSweeps,
    runNewSweeps,
    activeSweeps,
    removeWinner,
    replaceWinner,
    winnerBeingReplaced,
    loading: runningSweeps || loadingSweepsCollection,
    sweepsCollection,
  };
};
