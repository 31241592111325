import styled, { css } from "styled-components";
import phoneFrameImg from "assets/images/phone-base.png";
import phoneStatusBarImgDark from "assets/images/phone-status-bar-dark.png";
import { useEffect, useRef, useState } from "react";
import {
  CategoryOptionId,
  DEFAULT_PROFILING_CATEGORIES,
  ProfilingCategoryId,
  SurveyBuilderConfig,
  SurveyConfig,
  SurveyConfigModule,
  generateSurveyConfig,
} from "@max/common/dist/setfan";
import { useBuilderContext } from "./BuilderContext";
import { Select } from "melodies-source/Select";
import { Button } from "melodies-source/Button";
import { FlexColumn, FlexRow } from "Components/Flex";
import { useArtistContext } from "Components";
import { useIsMobile } from "melodies-source/utils";
import { useScrollLockContext } from "contexts/ScrollLockContext";
import { FieldValue } from "firebase/firestore";

interface PreviewProps {
  isThumbnail?: boolean;
  requestView?: string;
}

export const Preview = ({
  isThumbnail,
  requestView,
  ...props
}: PreviewProps) => {
  const { data } = useBuilderContext();
  const iframe = useRef<HTMLIFrameElement>(null);
  const [iframeInitialized, setIframeInitialized] = useState(false);
  const [page, setPage] = useState(0);
  const [view, setView] = useState<"new" | "existing">("new");
  const [options, setOptions] = useState<
    { name: string; label: string; value: string }[]
  >([]);
  const { name: artistName } = useArtistContext();
  const { unlockScroll, lockScroll } = useScrollLockContext();

  // build Preview Screen options dropdown list
  useEffect(() => {
    let options = [{ name: "main", label: "Main Survey", value: "0" }];
    let proBlocks = Math.min(
      // add 1 for core if new
      data.fields.displayedCategories.content.length + (view === "new" ? 1 : 0),
      parseInt(data.fields.numberOfCategories.content),
    );

    const proPages = Math.ceil(proBlocks / 2);
    for (let i = 1; i <= proPages; i++) {
      options.push({
        name: "profiling",
        label: `Profiling Questions${proPages > 1 ? ` ${i}` : ""}`,
        value: `${i}`,
      });
    }
    options.push({
      name: "thankyou",
      label: "Thank You",
      value: `${options.length}`,
    });
    setOptions(options);
  }, [
    data.fields.displayedCategories.content,
    data.fields.numberOfCategories.content,
    view,
  ]);

  useEffect(() => {
    if (requestView) {
      const option = options.find((o) => o.name === requestView);

      if (option) {
        setPage(parseInt(option.value));
      }
    }
  }, [requestView, options]);

  // scroll to top of preview window on page/options change
  useEffect(() => {
    iframe.current?.contentWindow?.scrollTo(0, 0);
  }, [page, options]);

  // make sure we aren't on a non-existent page after options change
  useEffect(() => {
    if (options.length && page > options.length - 1) {
      setPage(options.length - 1);
    }
  }, [options.length]);

  useEffect(() => {
    if (!iframeInitialized) {
      return;
    }
    const survey = forceProfilingCategories(
      data,
      generateSurveyConfig({
        ...data,
        fields: {
          ...data.fields,
          // a bit hacky, but since we don't prune empty profiling pages like
          // we do in the fan app, we need to ensure consistency between the
          // number of preview pages generated above, and the number of pages
          // which are generated for the survey config
          // NOTE: `displayedCategories` is of length 0 when only one cat is selected (default)
          // so fall back to 1 if `displayedCategories.content.length` is 0
          numberOfCategories: {
            content: `${Math.min(
              parseInt(data.fields.numberOfCategories.content),
              data.fields.displayedCategories.content.length || 1,
            )}`,
          },
        },
        artistName,
      }),
      view === "existing",
    );

    iframe.current?.contentWindow?.postMessage({
      survey: {
        ...survey,
        config: {
          ...survey.config,
          globalStyles: (survey.config.globalStyles += `
            html::-webkit-scrollbar {
              display: none;
            } button {
              cursor: not-allowed;
              pointer-events: none;
            }
            `),
        },
      },
      page,
    });
  }, [data, iframeInitialized, page, view, options]);

  const preview = (
    <Prev
      id="setfan-preview"
      ref={iframe}
      src={`${process.env.PUBLIC_URL}/setfanembed`}
      title="preview"
      onLoad={() => setIframeInitialized(true)}
    />
  );

  const isMobile = useIsMobile();

  useEffect(() => {
    if (isMobile) {
      lockScroll();
      return () => unlockScroll();
    }
  }, [isMobile]);

  return (
    <>
      <Container {...props}>
        {isMobile ? (
          <>
            <InnerShadow />
            {preview}
          </>
        ) : (
          <>
            <PhoneStatusBarContainer style={{ backgroundColor: "#ccc" }}>
              <PhoneStatusBar src={phoneStatusBarImgDark} />
            </PhoneStatusBarContainer>
            <TemplateContainer>
              <ScrollContainer isThumbnail={isThumbnail}>
                {preview}
              </ScrollContainer>
            </TemplateContainer>
            <PhoneFrame src={phoneFrameImg} />
            {!isThumbnail && <DropShadow />}
          </>
        )}
      </Container>
      {!isThumbnail && (
        <Actions>
          <Select
            label="Preview Screen"
            onChange={(val) => setPage(parseInt(val))}
            options={options}
            value={page?.toString()}
          />
          <FlexRow>
            <ToggleLeft onClick={() => setView("new")} active={view === "new"}>
              New Contact View
            </ToggleLeft>
            <ToggleRight
              onClick={() => setView("existing")}
              active={view === "existing"}
            >
              Existing Contact View
            </ToggleRight>
          </FlexRow>
        </Actions>
      )}
    </>
  );
};

// iterate through the profiling modules and add the category force property for
// the categories which are valid for this preview
function forceProfilingCategories(
  builderConfig: SurveyBuilderConfig,
  surveyConfig: SurveyConfig<FieldValue>,
  existingUser: boolean,
): SurveyConfig<FieldValue> {
  const categoriesToShow = [...DEFAULT_PROFILING_CATEGORIES].filter((cat) =>
    builderConfig.fields.displayedCategories.content.includes(
      cat.split(".")[1] as CategoryOptionId,
    ),
  );
  // if not an existing user, add core back in
  if (!existingUser) {
    categoriesToShow.unshift("profilecategory.core" as ProfilingCategoryId);
  }

  let forcedConfig = { ...surveyConfig };

  const injectForceProfiling = (module: SurveyConfigModule) => {
    switch (module.type) {
      case "page":
      case "container":
      case "card":
        module.modules?.forEach((mod, idx) => {
          // when using an existing user, we might not have enough available
          // categories to fill out the profiling blocks in the survey.
          // in this instance, remove any "extra" profiling modules
          if (mod.type === "profiling" && !categoriesToShow.length) {
            module.modules.splice(idx, 1);
          } else {
            return injectForceProfiling(mod);
          }
        });
        break;
      case "profiling":
        // if already forced (like email on the first page), use that.
        // else use next category from beginning of `categoriesToShow` array
        module.force = module.force ?? categoriesToShow.shift();
        break;
    }
  };

  forcedConfig.pages.forEach((page) => injectForceProfiling(page));

  if (existingUser) {
    forcedConfig.profilingExclusions.unshift("profilecategory.core");
    forcedConfig.profilingExclusions.unshift("profilecategory.email");
  }

  return { ...forcedConfig };
}

const Actions = styled(FlexColumn)`
  margin-top: 16px;
  gap: 16px;
  ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 12px 16px 0;
    gap: 12px;
    order: 1;
    margin-top: 0;
    z-index: 2;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    flex-grow: 0;
  }
`;

const InnerShadow = styled.div`
  box-shadow: inset 0 0 16px rgba(0, 0, 0, 0.32);
  pointer-events: none;
  position: absolute;
  top: 0;
  left: -20px;
  right: -20px;
  bottom: -20px;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  aspect-ratio: 396/800;

  ${({ theme }) => theme.mediaQueries.mobile} {
    width: calc(100% + 20px);
    aspect-ratio: initial;
    order: 2;
    align-self: center;
    z-index: 1;
    flex-grow: 1;
    border-radius: 40px 40px 0 0;
    border: 10px solid #fff;
    margin: 150px -10px -10px;
    overflow: hidden;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.32);
  }
`;

const DropShadow = styled.div`
  border-radius: 10.46% 10.46% 10.46% 10.46% / 5.13% 5.13% 5.13% 5.13%;
  box-shadow: 0px 0px 150px rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 8px;
  right: 12px;
  bottom: 8px;
  left: 12px;
  z-index: 0;
`;

const PhoneFrame = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const PhoneStatusBar = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const PhoneStatusBarContainer = styled.div`
  background-color: #ffffff;
  border-top-left-radius: 11.65% 73.21%;
  border-top-right-radius: 11.65% 73.21%;
  position: relative;
  margin: 4% 5.56% 0 5.56%;
  height: 7%;
  z-index: 3;
`;

const Prev = styled.iframe`
  height: 100%;
`;

const ScrollContainer = styled.div<{ isThumbnail?: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  ${(p) => p.isThumbnail && `overflow: hidden;`};
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const TemplateContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0 5.56% 5% 5.56%;
  position: relative;
  z-index: 2;
  border-bottom-left-radius: 11.65% 5.78%;
  border-bottom-right-radius: 11.65% 5.78%;
  overflow: hidden;
`;

const ToggleLeft = styled(Button)<{ active?: boolean }>`
  background-color: #ffffff;
  border: 1px solid #999999;
  border-radius: 18px 0 0 18px;
  color: #1b0076;
  padding: 6px 0;
  width: 50%;
  height: auto;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  &:hover {
    background-color: #e8e5f1;
    box-shadow: none;
  }

  ${(p) =>
    p.active &&
    css`
      font-weight: 600;
      background-color: #e8e5f1;
    `};
`;

const ToggleRight = styled(ToggleLeft)`
  border-radius: 0 18px 18px 0;
  border-left: none;
`;
