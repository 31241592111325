import { synthscape as synthscapeConfig } from "@max/thirdparty/companies/synthscape";
import { GtbAffiliationMap } from "@max/common/dist/thirdparty/gtb";
import { GtbCustomApp } from "../gtb/types";
import { hearthHarmony } from "../hearthHarmony";

export const synthscape: GtbCustomApp = {
  ...synthscapeConfig,
  routes: hearthHarmony.routes,
  userRoles: JSON.parse(
    JSON.stringify(GtbAffiliationMap).replaceAll("GTB", "Synthscape"),
  ),
};
