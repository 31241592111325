import { fizz as fizzConfig } from "@max/thirdparty/companies/fizz";
import { GtbAffiliationMap } from "@max/common/dist/thirdparty/gtb";
import { GtbCustomApp } from "../gtb/types";
import { gtb } from "../gtb";

export const fizz: GtbCustomApp = {
  ...fizzConfig,
  routes: gtb.routes,
  userRoles: JSON.parse(
    JSON.stringify(GtbAffiliationMap).replaceAll("GTB", "Fizz"),
  ),
};
