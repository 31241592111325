import styled from "styled-components";
import Popover from "Components/Popover";
import { ReactComponent as RefreshIcon } from "assets/svg/refresh.svg";
import { ReactComponent as ShowIcon } from "assets/svg/show.svg";
import { ReactComponent as TrashIcon } from "assets/svg/trash.svg";
import Badge from "Components/Badge";
import { Fragment, useState } from "react";
import {
  ActionModal,
  ActionModalState,
  buildModalState,
  WinnerDetailModal,
  MessageModal,
} from "./modal";
import Skeleton from "react-loading-skeleton";
import useSurveyQuestions from "hooks/useSurveyQuestions";
import useSweepsWinnersDetails, {
  WinnerDetails,
} from "./hooks/useSweepsWinnersDetails";
import useSweepsConversations from "./hooks/useSweepsConversations";
import { Card } from "Components/Card";
import { FlexRow, FlexColumn } from "Components/Flex";
import { Button } from "melodies-source/Button";
import { useScrollLockContext } from "contexts/ScrollLockContext";
import { useParams } from "react-router-dom";
import { Tooltip } from "melodies-source/Tooltip";
import { InstantMessage as InstantMessageIcon } from "svg/InstantMessage";
import { formatNumber } from "Utils/format";
import { Sweepstakes } from "@max/common/dist/sweepstakes";

const WinnersList = ({
  sweeps,
  cancelCurrentSweeps,
  removeWinner,
  replaceWinner,
  winnerBeingReplaced,
}: {
  sweeps: Sweepstakes & { id: string };
  cancelCurrentSweeps: () => Promise<void>;
  removeWinner: (removeId: string) => Promise<void>;
  replaceWinner: (replaceId: string) => Promise<void>;
  winnerBeingReplaced: string;
}) => {
  const { lockScroll, unlockScroll } = useScrollLockContext();
  const { surveyId } = useParams() as { surveyId: string };
  const [actionModalState, setActionModalState] =
    useState<ActionModalState | null>(null);
  const [detailsModalState, setDetailsModalState] =
    useState<WinnerDetails | null>(null);
  const [conversationModalState, setConversationModalState] =
    useState<WinnerDetails | null>(null);

  const { surveyQuestions } = useSurveyQuestions(surveyId);

  const { numberOfWinners, limitToUS } = sweeps;

  const { winnersDetails } = useSweepsWinnersDetails(sweeps, surveyId);

  const { conversations } = useSweepsConversations(sweeps.id);

  return (
    <>
      <StyledCard isElevated style={{ position: "relative" }}>
        <InnerCard>
          <HeaderContainer>
            <FlexColumn>
              <HeaderText>Sweepstakes Winners</HeaderText>
              <HeaderSubtext>{`${formatNumber(numberOfWinners)} winner${
                numberOfWinners === 1 ? "" : "s"
              }${
                limitToUS ? " | Limited to United States" : ""
              }`}</HeaderSubtext>
            </FlexColumn>
            <ResetContainer
              onClick={() => {
                lockScroll();
                setActionModalState(
                  buildModalState({
                    action: "reset",
                    onClose: () => {
                      setActionModalState(null);
                      unlockScroll();
                    },
                    onConfirm: () => cancelCurrentSweeps(),
                  }),
                );
              }}
            >
              <ResetIcon />
              <ResetText>Reset</ResetText>
            </ResetContainer>
          </HeaderContainer>
          <WinnersContainer>
            {winnersDetails.map((winnerDetails) => {
              let overflow = false;
              let unread =
                conversations?.[winnerDetails.conversationId]?.unread ?? 0;
              if (unread > 9) {
                overflow = true;
                unread = 9;
              }

              return (
                <Fragment
                  key={`${winnerDetails.contactId}-${winnerDetails.submissionId}`}
                >
                  <FlexRow
                    key={`${winnerDetails.submissionId}-${winnerDetails.contactId}`}
                    style={{
                      justifyContent: "space-between",
                      paddingTop: 21,
                      paddingBottom: 21,
                      alignItems: "flex-end",
                    }}
                  >
                    {winnerDetails.isRemoved ? (
                      <ReplaceButton
                        onClick={async () =>
                          await replaceWinner(
                            winnerDetails.contactId.split(":")[1],
                          )
                        }
                      >
                        Replace
                      </ReplaceButton>
                    ) : winnerBeingReplaced === winnerDetails.fanId ? (
                      <WinnerRowLoader />
                    ) : (
                      <>
                        <FlexColumn>
                          <FiltersLabelText>
                            {winnerDetails.name}
                          </FiltersLabelText>
                          <FiltersLabelSubtext>
                            {winnerDetails.location}
                          </FiltersLabelSubtext>
                        </FlexColumn>
                        <FlexRow>
                          <Badge
                            value={unread}
                            overflow={overflow}
                            onClick={() => {
                              if (!winnerDetails.canSms) return;
                              lockScroll();
                              setConversationModalState(winnerDetails);
                            }}
                          >
                            {winnerDetails.canSms ? (
                              <StyledInstantMessageIcon
                                canSms={winnerDetails.canSms}
                              />
                            ) : (
                              <Tooltip
                                parent={
                                  <StyledInstantMessageIcon
                                    canSms={winnerDetails.canSms}
                                  />
                                }
                              >
                                <div style={{ width: 200 }}>
                                  This winner has not opted in to sms messaging.
                                  Please email them for correspondence.
                                </div>
                              </Tooltip>
                            )}
                          </Badge>
                          <StyledPopover forceSide="left">
                            <FlexColumn style={{ overflow: "hidden" }}>
                              <WinnerPopoverRow
                                onClick={() => {
                                  lockScroll();
                                  setActionModalState(
                                    buildModalState({
                                      action: "replace",
                                      winnerDetails,
                                      onClose: () => {
                                        setActionModalState(null);
                                        unlockScroll();
                                      },
                                      onConfirm: async () => {
                                        await replaceWinner(
                                          winnerDetails.fanId,
                                        );
                                      },
                                    }),
                                  );
                                }}
                              >
                                <RefreshIcon style={{ marginRight: 10 }} />
                                <WinnerPopoverLabel>
                                  Replace Winner
                                </WinnerPopoverLabel>
                              </WinnerPopoverRow>
                              <WinnerPopoverRow
                                onClick={() => {
                                  lockScroll();
                                  setDetailsModalState(winnerDetails);
                                }}
                              >
                                <ShowIcon style={{ marginRight: 10 }} />
                                <WinnerPopoverLabel>
                                  Winner Details
                                </WinnerPopoverLabel>
                              </WinnerPopoverRow>
                              <WinnerPopoverRow
                                onClick={() => {
                                  lockScroll();
                                  setActionModalState(
                                    buildModalState({
                                      action: "remove",
                                      winnerDetails,
                                      onClose: () => {
                                        setActionModalState(null);
                                        unlockScroll();
                                      },
                                      onConfirm: async () => {
                                        await removeWinner(winnerDetails.fanId);
                                      },
                                    }),
                                  );
                                }}
                              >
                                <TrashIcon style={{ marginRight: 10 }} />
                                <WinnerPopoverLabel>Remove</WinnerPopoverLabel>
                              </WinnerPopoverRow>
                            </FlexColumn>
                          </StyledPopover>
                        </FlexRow>
                      </>
                    )}
                  </FlexRow>
                </Fragment>
              );
            })}
          </WinnersContainer>
        </InnerCard>
      </StyledCard>
      {!!actionModalState && (
        <ActionModal {...{ ...actionModalState, isOpen: true }} />
      )}
      {!!detailsModalState && (
        <WinnerDetailModal
          isOpen={!!detailsModalState}
          onClose={() => {
            unlockScroll();
            setDetailsModalState(null);
          }}
          winnerDetails={detailsModalState}
          surveyQuestions={surveyQuestions}
        />
      )}
      {!!conversationModalState && (
        <MessageModal
          winner={conversationModalState}
          isOpen={!!conversationModalState}
          onClose={() => {
            unlockScroll();
            setConversationModalState(null);
          }}
        />
      )}
    </>
  );
};

export default WinnersList;

const StyledInstantMessageIcon = styled(InstantMessageIcon)<{
  canSms: boolean;
}>`
  height: 29px;
  width: 29px;
  cursor: ${({ canSms }) => (canSms ? "pointer" : "not-allowed")};
  position: relative;

  & > path {
    fill: ${({ canSms }) => (canSms ? "#1b0076" : "#c9c9c9")};
  }
`;

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 24px;

  ${({ theme }) => theme.media.tablet} {
    padding: 24px 48px 15px 48px;
  }
`;

const WinnerRowLoader = () => {
  return (
    <>
      <FlexColumn>
        <FiltersLabelText>
          <Skeleton width={100} containerClassName="flex-1" />
        </FiltersLabelText>
        <FiltersLabelSubtext>
          <Skeleton width={100} containerClassName="flex-1" />
        </FiltersLabelSubtext>
      </FlexColumn>
      <FlexRow>
        <Skeleton
          height={27}
          width={27}
          containerClassName="flex-1"
          style={{ marginRight: 15 }}
        />
        <Skeleton height={27} width={5} />
      </FlexRow>
    </>
  );
};

const ResetIcon = styled(RefreshIcon)`
  color: #1b0076;
  height: 14px;
  width: 14px;

  ${({ theme }) => theme.media.tablet} {
    width: 22px;
    height: 22px;
  }
`;

const ReplaceButton = styled(Button)`
  background: transparent !important;
  border: 1px solid #1b0076;
  color: #1b0076;
  width: 123px;

  ${({ theme }) => theme.media.tablet} {
    width: 123px;
  }
`;

const StyledPopover = styled(Popover)`
  margin-left: 15px;
`;

const WinnerPopoverRow = styled(FlexRow)`
  padding: 12px 15px;
  width: 180px;

  &:hover {
    background-color: #1b007615;
  }
`;

const WinnerPopoverLabel = styled.div`
  color: #333;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
`;

const WinnersContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > div:not(:last-child) {
    border-bottom: 1px solid #dedede;
  }
`;

const FiltersLabelText = styled.div`
  color: #333;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
`;

const FiltersLabelSubtext = styled.div`
  color: #333;
  font-family: Poppins;
  font-size: 12px;
  line-height: 18px;
`;

const HeaderSubtext = styled.div`
  color: #333;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  line-height: 18px;

  ${({ theme }) => theme.media.tablet} {
    font-size: 15px;
    line-height: 22px;
  }
`;

const HeaderContainer = styled.div`
  height: 71px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;

  ${({ theme }) => theme.media.tablet} {
    flex-direction: row;
    justify-content: center;
    margin-bottom: 0;
  }
`;

const HeaderText = styled.div`
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;

  ${({ theme }) => theme.media.tablet} {
    font-size: 26px;
    line-height: 36px;
  }
`;

const InnerCard = styled.div`
  width: 100%;
`;

const ResetContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 9px;

  ${({ theme }) => theme.mediaQueries.desktop} {
    position: absolute;
    right: 43px;
  }
`;

const ResetText = styled.span`
  color: #1b0076;
  font-family: Poppins;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;

  ${({ theme }) => theme.mediaQueries.desktop} {
    line-height: 24px;
    font-size: 16px;
  }
`;
