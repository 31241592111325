import { typedFields, useConfigSlice } from "../Components/BuilderContext";
import styled from "styled-components";
import { SurveyInputList } from "../Components/SurveyInputList";
import { Checkbox } from "melodies-source/Selectable";
import { Textarea } from "melodies-source/Textarea";

export const ADDITIONAL_SETTINGS_FIELDS = typedFields([
  "hasIntroFooter",
  "introFooter",
  "hasThankYouFooter",
  "thankYouFooter",
]);

export const AdditionalSettings = () => {
  const { fields, setField, setFieldTouched, validation } = useConfigSlice(
    ADDITIONAL_SETTINGS_FIELDS,
  );

  return (
    <SurveyInputList>
      <FooterCheckbox
        label="Add an intro footer"
        value={fields.hasIntroFooter.content}
        onChange={(v) => setField("hasIntroFooter", v)}
      />
      {fields.hasIntroFooter.content && (
        <Textarea
          value={fields.introFooter.content.en}
          onChange={(text) => setField("introFooter", { en: text })}
          onBlur={() => setFieldTouched("introFooter")}
          hasError={fields.introFooter.isTouched && !!validation.introFooter}
          helperText={validation.introFooter || ""}
        />
      )}
      <FooterCheckbox
        label="Add a thank you footer"
        value={fields.hasThankYouFooter.content}
        onChange={(v) => setField("hasThankYouFooter", v)}
      />
      {fields.hasThankYouFooter.content && (
        <Textarea
          value={fields.thankYouFooter.content.en}
          onChange={(text) => setField("thankYouFooter", { en: text })}
          onBlur={() => setFieldTouched("thankYouFooter")}
          hasError={
            fields.thankYouFooter.isTouched && !!validation.thankYouFooter
          }
          helperText={validation.thankYouFooter || ""}
        />
      )}
    </SurveyInputList>
  );
};

const FooterCheckbox = styled(Checkbox)`
  margin: 0;
  p {
    font-size: 20px;
    line-height: 1;
  }
  ${({ theme }) => theme.mediaQueries.mobile} {
    p {
      font-size: 16px;
      line-height: 20px;
    }
  }
`;
