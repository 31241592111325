import { Caption, InputWrap, Label, useArtistContext } from "Components";
import { ReplaceImageButton } from "Components/ReplaceImageButton";
import { Field, FieldProps } from "formik";
import { ImageUploader } from "./ImageUploader";
import { PreviewImage } from "./PreviewImage";
import styled from "styled-components";

interface ImageFieldProps {
  name: string;
  label: string;
  aspectRatio?: number;
  withAspectRatio?: boolean;
  className?: string;
  afterOnComplete?: (uploadUrl: string) => void;
}

export const ImageField = ({
  name,
  label,
  aspectRatio,
  withAspectRatio = false,
  className,
  afterOnComplete,
}: ImageFieldProps) => {
  const { artistGroupDoc } = useArtistContext();

  return (
    <Field name={name}>
      {({ field, meta, form }: FieldProps) => {
        return (
          <InputWrap>
            <Label>{label}</Label>
            {field.value ? (
              <>
                <PreviewImage
                  src={field.value}
                  style={{
                    aspectRatio: aspectRatio ? String(aspectRatio) : undefined,
                  }}
                />
                <ReplaceImageButton
                  onClick={() => form.setFieldValue(field.name, "")}
                />
              </>
            ) : (
              <ImageUploader
                className={className}
                uploadPath={`set-page/${artistGroupDoc.id}/modules`}
                uploadWithFileExt={false}
                aspectRatio={aspectRatio}
                withAspectRatio={withAspectRatio}
                onComplete={(v) => {
                  form.setFieldValue(field.name, v);
                  afterOnComplete?.(v);
                }}
              />
            )}
            {meta.touched && meta.error && (
              <Caption error>{meta.error}</Caption>
            )}
          </InputWrap>
        );
      }}
    </Field>
  );
};

export const ImageFieldWhiteBackground = styled(ImageField)`
  input[type="file"] + div > div {
    background: white !important;
  }
`;
