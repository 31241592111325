import React from "react";
import { SetPageReleaseForm } from "@max/common/src/setpage";
import styled from "styled-components";
import { Body1, Subtitle2 } from "melodies-source/Text";
import { getDateTime } from "Utils/date";
import { useMobileMediaQuery } from "hooks";

interface Props {
  release: SetPageReleaseForm;
}
export const ArchivedReleasedCard: React.FC<Props> = ({ release }) => {
  const isMobile = useMobileMediaQuery();

  const releaseDateText = (
    <Body1>Released {getDateTime(release.date).toFormat("LLL d, yyyy")}</Body1>
  );

  return (
    <Container>
      <Cover src={release.image} alt="cover" />
      <Detail>
        <div>
          <Title>{release.title}</Title>
          {isMobile && releaseDateText}
        </div>
        <div>
          <Subtitle2>UPC# {release.upc}</Subtitle2>
          {release.isrc && <Subtitle2>ISRC# {release.isrc}</Subtitle2>}
        </div>
      </Detail>
      {!isMobile && releaseDateText}
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: 20px;
  padding: 15px 20px 15px 15px;
  border-radius: 12px;
  background: #f2f5f7;

  ${({ theme }) => theme.media.mobile} {
    gap: 10px;
  }
`;

const Cover = styled.img`
  width: 65px;
`;

const Detail = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${Subtitle2} {
    font-size: 12px;
    color: #999;
  }

  ${({ theme }) => theme.media.mobile} {
    ${Body1} {
      font-size: 14px;
    }

    ${Subtitle2} {
      font-size: 11px;
    }
  }
`;

const Title = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;

  ${({ theme }) => theme.media.mobile} {
    font-size: 14px;
    line-height: 20px;
  }
`;
