import { ReactNode } from "react";
import styled, { css } from "styled-components";
import { H3 } from "melodies-source/Text";
import { SvgForward } from "melodies-source/Svgs/Forward";

interface SectionContainerProps {
  title: ReactNode;
  titleAddon?: React.FC<any>;
  icon: ReactNode;
  lastIndex?: boolean;
  slice: React.FC<any>;
  active?: boolean;
  onClick?: VoidFunction;
}

export const SectionContainer = ({
  title,
  titleAddon: TitleAddon,
  icon,
  lastIndex,
  slice: Slice,
  active,
  onClick,
}: SectionContainerProps) => {
  return (
    <Container>
      <Header onClick={() => onClick()}>
        <IconContainer>{icon}</IconContainer>
        <Title>{title}</Title>
        {TitleAddon && (
          <TitleAddonContainer>
            <TitleAddon />
          </TitleAddonContainer>
        )}
        <DropdownIcon open={active} />
      </Header>
      <Inner open={active} lastIndex={lastIndex}>
        {active && <Slice />}
      </Inner>
      {active && !lastIndex && <InnerShadow />}
    </Container>
  );
};

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 24px;
  margin-right: 16px;

  svg {
    color: var(--text-color);
    width: 24px;
    height: 24px;
  }

  ${({ theme }) => theme.mediaQueries.mobile} {
    width: 20px;
    margin-right: 10px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  &:not(:first-child) {
    border-top: 1px solid var(--box-shadow-color);
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 24px 32px;

  ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 16px 20px;
  }
`;

const Title = styled(H3)`
  display: flex;
  color: var(--text-color);
  flex: 1 1 auto;

  ${({ theme }) => theme.mediaQueries.mobile} {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
  }
`;

const TitleAddonContainer = styled.div`
  margin-right: 30px;
`;

const Inner = styled.div<{ open: boolean; lastIndex?: boolean }>`
  height: 0;
  ${({ open, lastIndex }) =>
    open &&
    css`
      height: auto;
      padding: 0 32px ${lastIndex ? 32 : 22}px;
      overflow: initial;
      ${({ theme }) => theme.mediaQueries.mobile} {
        padding: 0 20px ${lastIndex ? 24 : 14}px;
      }
    `}
`;

const InnerShadow = styled.div`
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.06) 0%,
    rgba(0, 0, 0, 0) 70.26%
  );
  height: 30px;
  width: 100%;
`;

const DropdownIcon = styled(SvgForward)<{ open?: boolean }>`
  color: var(--text-color);
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  rotate: 90deg;
  ${(p) => p.open && `transform: rotate(180deg);`};
  ${({ theme }) => theme.mediaQueries.mobile} {
    width: 20px;
    height: 20px;
  }
`;
