import React, { useState } from "react";
import { Layout } from "./Components/Layout";
import { ContactInfo } from "./ContactInfo";
import { Password } from "./Password";
import { AccountCreated } from "./AccountCreated";
import { MFA } from "./MFA";
import { useUser } from "auth";
import { AccountReady } from "./AccountReady";
import { useHistory } from "react-router-dom";

export interface StepProps {
  handleBack: () => void;
  handleNext: () => void;
}

export const Signup: React.FC<React.PropsWithChildren<{}>> = () => {
  const { profile } = useUser();
  const [step, setStep] = useState(
    !profile?.shouldOnboard && profile?.shouldSetupMFA ? 3 : 1,
  );
  const history = useHistory();
  const views = [
    ContactInfo,
    Password,
    MFA,
    !profile?.shouldOnboard && profile?.shouldSetupMFA
      ? AccountReady
      : AccountCreated,
  ];

  const handleBack = () => {
    if (step === 1) return;
    setStep((step) => step - 1);
  };

  const handleNext = () => {
    if (step === views.length) return;
    setStep((step) => step + 1);
  };

  const Step = views[step - 1];

  if (profile && !profile.shouldOnboard && !profile.shouldSetupMFA) {
    history.push("/");
  }

  return (
    <Layout>
      <Step handleBack={handleBack} handleNext={handleNext} />
    </Layout>
  );
};
