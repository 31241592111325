import React from "react";
import { Field, FieldProps } from "formik";
import styled, { css } from "styled-components";
import { Body1 } from "melodies-source/Text";
import { Switch } from "Components/Switch";

interface Props {
  name: string;
  label: string;
}

export const SwitchField: React.FC<Props> = ({ name, label }) => {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => {
        return (
          <SwitchContainer>
            <StyledSwitch
              value={field.value}
              onChange={(value) => {
                form.setFieldValue(field.name, value);
              }}
            />
            <Body1>{label}</Body1>
          </SwitchContainer>
        );
      }}
    </Field>
  );
};

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  ${Body1} {
    font-weight: 500;
    color: var(--text-color);
  }
`;

export const StyledSwitch = styled(Switch)`
  ${({ value }) =>
    value &&
    css`
      background: #1db964;
      border-color: #1db964;
    `}
`;
