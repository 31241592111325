import { hearthHarmony as hearthHarmonyConfig } from "@max/thirdparty/companies/hearthHarmony";
import { GtbAffiliationMap } from "@max/common/dist/thirdparty/gtb";
import { GtbCustomApp } from "../gtb/types";
import { gtb } from "../gtb";

export const hearthHarmony: GtbCustomApp = {
  ...hearthHarmonyConfig,
  routes: gtb.routes,
  userRoles: JSON.parse(
    JSON.stringify(GtbAffiliationMap).replaceAll("GTB", "Hearth & Harmony"),
  ),
};
