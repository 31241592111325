import { Button, DefaultLayout } from "Components";
import { Card } from "melodies-source/Card";
import { Body1, Caption, H1, H2 } from "melodies-source/Text";
import { GlobalStyles } from "styled";
import styled from "styled-components";
import { FlexColumn } from "Routes/styled";
import {
  Route,
  RouteComponentProps,
  Switch,
  useHistory,
  useParams,
} from "react-router-dom";
import { SvgRight } from "melodies-source/Svgs/Right";
import { Preview } from "../Components/Preview";
import { SurveyInfo } from "./views/SurveyInfo";
import { SurveyQuestions } from "./views/SurveyQuestions";
import {
  Dispatch,
  HTMLAttributes,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { ProfilingQuestions } from "./views/ProfilingQuestions";
import { THANK_YOU_FIELDS } from "../slices/ThankYouMessage";
import { ThankYouMessage } from "./views/ThankYouMessage";
import { useSurveyBuilder } from "../hooks/useSurveyBuilder";
import {
  BuilderProvider,
  useBuilderContext,
  useConfigSlice,
} from "../Components/BuilderContext";
import { SongsQuestion } from "../Components/SongsQuestion";
import { CONTEST_FIELDS } from "../slices/Contest";
import { INFO_FIELDS } from "../slices/SurveyInfoContent";
import { PrimaryActionsContainer } from "../dashboard/Components/Templates/SelectCategoryModal";
import { useIsMobile } from "melodies-source/utils";
import { PageGridContainer } from "../editor";
import { SvgCloseLarge } from "melodies-source/Svgs/CloseLarge";
import { SvgMobile } from "melodies-source/Svgs/Mobile";

export const Wizard = ({
  match,
}: RouteComponentProps<{ surveyId: string }>) => {
  const [page, setPage] = useState<number | null>(0);
  const { survey, version } = useSurveyBuilder(match.params.surveyId);

  return (
    <DefaultLayout>
      <GlobalStyles grayBG />
      {survey && version && (
        <BuilderProvider survey={survey} version={version}>
          <Switch>
            <Route
              path={match.path}
              exact={true}
              render={() => <InnerCreator page={page} setPage={setPage} />}
            />
            <Route
              path={`${match.path}/setlist/:setlistId?`}
              render={() => <InnerSetlist backPath={match.url} />}
            />
          </Switch>
        </BuilderProvider>
      )}
    </DefaultLayout>
  );
};

interface InnerSetlistProps {
  backPath: string;
}

const InnerSetlist = ({ backPath }: InnerSetlistProps) => {
  return (
    <div style={{ padding: 40 }}>
      <SongsQuestion backPath={backPath} />
    </div>
  );
};

interface InnerCreatorProps {
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
}

const InnerCreator = ({ page, setPage }: InnerCreatorProps) => {
  const history = useHistory();
  const { artistId: artistGroupId } = useParams() as { artistId: string };
  const currentSlice = slices[page];
  const SliceComponent = currentSlice?.slice;
  const { onPublish, publishingStatus } = useBuilderContext();
  const isPublishNextStep = page === slices.length - 1;
  const { isValid, setFieldsTouched } = useConfigSlice(currentSlice.fields);
  const isMobile = useIsMobile();
  const [isPreviewVisible, setIsPreviewVisible] = useState<boolean>(false);

  const draftButton = (
    <Button
      loading={publishingStatus === "draft"}
      disabled={publishingStatus === "live"}
      variant="tertiary"
      onClick={() => onPublish({ status: "draft" })}
    >
      Save as Draft
    </Button>
  );

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
      /* Fix for Typescript issue fixed in v5.1 noted here:
      https://github.com/microsoft/TypeScript/issues/47441 */
    } as unknown as ScrollToOptions);
  }, [page]);

  return (
    <StyledPageGridContainer>
      <PageCard isElevated>
        <Header>
          <H1>Survey Creator</H1>
          <Body1>
            Build a survey to meet your needs. Customize with your own
            questions, images, text, and profiling questions to make the most
            out of your efforts.
          </Body1>
        </Header>
        <TopDivider />
        <Content>
          {currentSlice?.title && (
            <H2 style={{ marginBottom: 12 }}>{currentSlice.title}</H2>
          )}
          <SliceComponent />
        </Content>
        <BottomDivider />
        <Footer>
          {isMobile && draftButton}
          <Button
            variant="outlined"
            onClick={() =>
              !page
                ? history.replace(`/${artistGroupId}/set-fan/surveys`)
                : setPage((page) => page - 1)
            }
          >
            {!page ? "Cancel" : "Back"}
          </Button>
          <PrimaryActionsContainer>
            {!isMobile && draftButton}
            <Button
              loading={publishingStatus === "live"}
              disabled={publishingStatus === "draft"}
              variant="primary"
              onClick={async () => {
                if (!isValid) {
                  return setFieldsTouched();
                }
                if (isPublishNextStep) {
                  await onPublish({ status: "live" });
                } else {
                  setPage((page) => page + 1);
                }
              }}
              {...(!isPublishNextStep && { rightIcon: <SvgRight /> })}
            >
              {currentSlice?.buttonText}
            </Button>
          </PrimaryActionsContainer>
        </Footer>
      </PageCard>
      {(!isMobile || isPreviewVisible) && (
        <PreviewContainer isVisible={isPreviewVisible}>
          <Preview requestView={currentSlice?.requestView} />
        </PreviewContainer>
      )}
      <PreviewFab
        isVisible={isPreviewVisible}
        onClick={() => setIsPreviewVisible((v) => !v)}
      />
    </StyledPageGridContainer>
  );
};

interface MobileActionButtonProps extends HTMLAttributes<HTMLButtonElement> {
  isVisible?: boolean;
}

export const PreviewFab = ({
  isVisible,
  ...props
}: MobileActionButtonProps) => {
  return (
    <MobileActionButton {...props}>
      {isVisible ? <SvgCloseLarge /> : <SvgMobile />}
      <Caption>{isVisible ? "Close" : "Preview"}</Caption>
    </MobileActionButton>
  );
};

const slices = [
  {
    slice: SurveyInfo,
    buttonText: "Survey Questions",
    fields: [...INFO_FIELDS, ...CONTEST_FIELDS],
    requestView: "main",
  },
  {
    slice: SurveyQuestions,
    buttonText: "Profiling Questions",
    fields: [],
    requestView: "main",
  },
  {
    title: "Profiling Questions",
    slice: ProfilingQuestions,
    buttonText: "Thank You Message",
    requestView: "profiling",
    fields: [],
  },
  {
    title: "Thank You Message",
    slice: ThankYouMessage,
    buttonText: "Publish",
    requestView: "thankyou",
    fields: THANK_YOU_FIELDS,
  },
];

export const MobileActionButton = styled.button`
  border: none;
  display: flex;
  flex-direction: column;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: #1b0076;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
  color: #fff;
  position: fixed;
  bottom: 24px;
  right: 20px;
  z-index: 20;
  align-items: center;
  justify-content: center;
  gap: 2px;

  ${Caption} {
    font-size: 10px;
    line-height: 10px;
    color: #ffffff;
    white-space: nowrap;
    text-align: center;
  }

  svg {
    margin-top: -4px;
    width: 28px;
    height: 28px;
  }

  ${({ theme }) => theme.mediaQueries.desktop} {
    display: none;
  }
`;

export const PreviewContainer = styled(FlexColumn)<{ isVisible?: boolean }>`
  position: sticky;
  top: 40px;
  width: 100%;
  flex-shrink: 0;

  ${({ theme }) => theme.mediaQueries.mobile} {
    background-color: #ffffff;
    display: ${(p) => (p.isVisible ? "flex" : "none")};
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 20;
    overflow: hidden;
  }
`;

const Content = styled(FlexColumn)`
  ${H2} {
    color: var(--max-text);
    margin-bottom: 8px;
  }

  width: 100%;
  padding: 0 32px;
  ${({ theme }) => theme.mediaQueries.mobile} {
    ${H2} {
      font-size: 20px;
      line-height: 28px;
      font-weight: 500;
      margin-bottom: 0;
    }
    padding: 0 20px;
  }
`;

const Header = styled(Content)`
  padding-bottom: 32px;
  ${Body1} {
    color: var(--secondary-text-color);
  }
  ${({ theme }) => theme.mediaQueries.mobile} {
    padding-bottom: 24px;
    ${H1} {
      font-size: 26px;
      line-height: 36px;
      margin-bottom: 4px;
    }
    ${Body1} {
      font-size: 12px;
      line-height: 18px;
    }
  }
`;

const PageCard = styled(Card)`
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.15);
  padding: 32px 0;

  ${({ theme }) => theme.media.mobile} {
    padding: 20px 0;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  }
`;

const StyledPageGridContainer = styled(PageGridContainer)`
  grid-template: auto / 1fr 400px;
  gap: 44px;
  row-gap: 0;
  align-items: flex-start;

  ${({ theme }) => theme.mediaQueries.mobile} {
    grid-template: auto / 1fr;
  }
`;

const BottomDivider = styled.div`
  background-color: var(--box-shadow-color);
  width: 100%;
  height: 1px;
`;

const TopDivider = styled(BottomDivider)`
  margin-bottom: 24px;

  ${({ theme }) => theme.mediaQueries.mobile} {
    margin-bottom: 20px;
  }
`;

const Footer = styled(Content)`
  flex-direction: row;
  padding-top: 32px;
  gap: 20px;
  justify-content: flex-end;

  ${({ theme }) => theme.mediaQueries.mobile} {
    flex-direction: column-reverse;
    align-items: stretch;
    padding-top: 24px;
    gap: 12px;
  }
`;
