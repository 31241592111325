export const useExceptionLogging = () => {
  const logException = (
    error: Error,
    tags?: { [tag: string]: string | number | boolean },
  ) => {
    window.LogRocket?.captureException(error, { tags });
  };

  return { logException };
};
