import {
  typedFields,
  useBuilderContext,
  useConfigSlice,
} from "../Components/BuilderContext";
import { ReactComponent as CheckIcon } from "assets/svg/check-filled.svg";
import { TextInput } from "melodies-source/TextInput";
import { Textarea } from "melodies-source/Textarea";
import styled, { css } from "styled-components";
import {
  SurveyInputList,
  SurveyInputListItem,
  SurveyInputHelpText,
} from "../Components/SurveyInputList";
import { FlexColumn } from "Components/Flex";
import { ReleaseList } from "Routes/SetPage/Components/Modal/PreSaveReleaseModal/PreSaveTab/Components/ReleaseList";
import { useFormik, FormikProvider } from "formik";
import { Body1, H2 } from "melodies-source/Text";
import { useRef, useState } from "react";
import { StackedModalProvider } from "Components/ModalStack";
import { ReactComponent as AddToLibraryIcon } from "assets/svg/add-to-library.svg";
import { ReactComponent as HeartIcon } from "assets/svg/heart.svg";
import { ReactComponent as LinkAltIcon } from "assets/svg/link-alt.svg";
import { ReactComponent as PlaylistIcon } from "assets/svg/playlist.svg";
import { Switch } from "Components/Switch";
import { Select } from "melodies-source/Select";
import { Caption } from "Components";
import { Modal } from "melodies-source/Modal";
import { Confirmation } from "melodies-source/Confirmation";
import {
  buildPreSaveContestMessage,
  buildPreSaveContestThankYouMessage,
  buildSpotifyPlaylistFields,
} from "../util";

export const THANK_YOU_FIELDS = typedFields([
  "thankYouHeader",
  "thankYouBody",
  "hasFeaturedCta",
  "featuredCtaHeaderText",
  "featuredCtaButtonText",
  "featuredCtaUrl",
  "isContest",
  "hasPreSave",
  "preSaveRelease",
  "hasPreSaveExtraContestEntries",
  "preSaveExtraContestEntries",
  "preSaveMessage",
  "preSaveThankYouMessage",
  "preSaveContestMessage",
  "preSaveContestThankYouMessage",
  "hasSpotifyPlaylist",
  "spotifyPlaylistText",
  "spotifyPlaylistGivesSweepstakesEntries",
  "spotifyPlaylistSweepstakesEntriesCount",
  "spotifyPlaylistSuccessMessage",
]);

const UpdateSweepsTextModal = ({
  isOpen,
  onClose,
  onConfirm,
}: {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}) => {
  return (
    <Modal
      header="Update Sweeps Text?"
      headerColor="main"
      isOpen={isOpen}
      withBackdropClose={false}
      onClose={onClose}
    >
      <Confirmation
        cancelLabel="Do Not Update"
        confirmLabel="Yes, Update"
        onCancel={onClose}
        onConfirm={onConfirm}
      >
        <Body1>
          You have changed the number of bonus entries for the sweepstakes.
          Would you like to update the text to reflect this change?
        </Body1>
      </Confirmation>
    </Modal>
  );
};

export const ThankYouMessage = () => {
  const [updateSweepsModal, setUpdateSweepsModal] = useState(false);
  const { fields, validation, setField, setFieldTouched } =
    useConfigSlice(THANK_YOU_FIELDS);
  const nextSweepstakesEntriesCount = useRef(
    fields.spotifyPlaylistSweepstakesEntriesCount.content,
  );
  const { data } = useBuilderContext();
  const initialIsContestValue = useRef(fields.isContest.content);

  const hasFeaturedCta = fields.hasFeaturedCta.content === true;
  const hasPreSave = fields.hasPreSave.content === true;
  const hasSpotifyPlaylist = fields.hasSpotifyPlaylist.content === true;
  const onlyThankYouMessage =
    !hasFeaturedCta && !hasPreSave && !hasSpotifyPlaylist;
  const hasSongsQuestion = !!data.surveyQuestions.find(
    (question) => question.type === "SongsQuestion",
  );

  const formik = useFormik({
    initialValues: { release: fields.preSaveRelease.content },
    onSubmit: () => {},
  });

  const toggleSweepsTextConfirmModalOpen = () =>
    setUpdateSweepsModal((open) => !open);

  return (
    <SurveyInputList>
      <SurveyInputListItem>
        <TextInput
          label="Header Copy"
          placeholder="Add header copy text..."
          hasError={
            fields.thankYouHeader.isTouched && !!validation.thankYouHeader
          }
          helperText={validation.thankYouHeader || ""}
          value={fields.thankYouHeader.content.en}
          onChange={(text) => setField("thankYouHeader", { en: text })}
          onBlur={() => setFieldTouched("thankYouHeader")}
        />
        <SurveyInputHelpText>
          Tell your fans “thanks” your way!
        </SurveyInputHelpText>
      </SurveyInputListItem>
      <SurveyInputListItem>
        <SurveyBodyTextArea
          label="Body Copy"
          placeholder="Add body copy text..."
          hasError={fields.thankYouBody.isTouched && !!validation.thankYouBody}
          helperText={validation.thankYouBody || ""}
          value={fields.thankYouBody.content.en}
          onChange={(text) => setField("thankYouBody", { en: text })}
          onBlur={() => setFieldTouched("thankYouBody")}
        />
        <SurveyInputHelpText>
          Use this to tell your fans more about how you will use this survey
          data, and what they can expect for any next steps as outlined in your
          sweepstakes.
        </SurveyInputHelpText>
      </SurveyInputListItem>
      <SectionContainer>
        <Divider />
        <H2>Promotion</H2>
        <PromotionGrid hasSongsQuestion={hasSongsQuestion}>
          <PromotionOption
            $active={onlyThankYouMessage}
            onClick={() => {
              setField("hasFeaturedCta", false);
              setField("hasPreSave", false);
              setField("hasSpotifyPlaylist", false);
            }}
          >
            {onlyThankYouMessage && <StyledCheckIcon />}
            <PromotionIcon $active={onlyThankYouMessage} as={HeartIcon} /> Thank
            You Message Only
          </PromotionOption>
          <PromotionOption
            $active={hasFeaturedCta}
            onClick={() => {
              setField("hasFeaturedCta", true);
              setField("hasPreSave", false);
              setField("hasSpotifyPlaylist", false);
            }}
          >
            {hasFeaturedCta && <StyledCheckIcon />}
            <PromotionIcon $active={hasFeaturedCta} as={LinkAltIcon} /> Featured
            CTA
          </PromotionOption>
          <PromotionOption
            $active={hasPreSave}
            onClick={() => {
              setField("hasPreSave", true);
              setField("hasFeaturedCta", false);
              setField("hasSpotifyPlaylist", false);
            }}
          >
            {hasPreSave && <StyledCheckIcon />}
            <PromotionIcon $active={hasPreSave} as={AddToLibraryIcon} />{" "}
            Pre-Save a Release
          </PromotionOption>
          {hasSongsQuestion && (
            <PromotionOption
              $active={hasSpotifyPlaylist}
              onClick={() => {
                setField("hasSpotifyPlaylist", true);
                setField("hasFeaturedCta", false);
                setField("hasPreSave", false);
              }}
            >
              {hasSpotifyPlaylist && <StyledCheckIcon />}
              <PromotionIcon $active={hasSpotifyPlaylist} as={PlaylistIcon} />
              Create a Spotify Playlist
            </PromotionOption>
          )}
        </PromotionGrid>
        {fields.hasFeaturedCta.content && (
          <Content>
            <SurveyInputListItem>
              <TextInput
                label="Header Text"
                placeholder="Enter body text"
                hasError={
                  fields.featuredCtaHeaderText.isTouched &&
                  !!validation.featuredCtaHeaderText
                }
                helperText={
                  (fields.featuredCtaHeaderText.isTouched &&
                    validation.featuredCtaHeaderText) ||
                  ""
                }
                value={fields.featuredCtaHeaderText.content.en}
                onChange={(text) =>
                  setField("featuredCtaHeaderText", { en: text })
                }
                onBlur={() => setFieldTouched("featuredCtaHeaderText")}
              />
            </SurveyInputListItem>
            <SurveyInputListItem>
              <TextInput
                label="Button Text"
                placeholder="Enter button text"
                maxLength={20}
                hasError={
                  fields.featuredCtaButtonText.isTouched &&
                  !!validation.featuredCtaButtonText
                }
                helperText={
                  (fields.featuredCtaButtonText.isTouched &&
                    validation.featuredCtaButtonText) ||
                  ""
                }
                value={fields.featuredCtaButtonText.content.en}
                onChange={(text) =>
                  setField("featuredCtaButtonText", { en: text })
                }
                onBlur={() => setFieldTouched("featuredCtaButtonText")}
              />
            </SurveyInputListItem>
            <SurveyInputListItem>
              <TextInput
                label="Button URL"
                placeholder="Enter button URL"
                hasError={
                  fields.featuredCtaUrl.isTouched && !!validation.featuredCtaUrl
                }
                helperText={
                  (fields.featuredCtaUrl.isTouched &&
                    validation.featuredCtaUrl) ||
                  ""
                }
                value={fields.featuredCtaUrl.content.en}
                onChange={(text) => setField("featuredCtaUrl", { en: text })}
                onBlur={() => setFieldTouched("featuredCtaUrl")}
              />
            </SurveyInputListItem>
          </Content>
        )}
        {fields.hasPreSave.content && (
          <Content>
            <StackedModalProvider>
              <FormikProvider value={formik}>
                <ReleaseList
                  source="setfan"
                  showTitle={false}
                  showArchived={false}
                  onChange={(release, action) => {
                    if (
                      action !== "select" ||
                      release.id !== fields.preSaveRelease.content.id
                    ) {
                      setField("preSaveRelease", release);
                    }
                  }}
                />
              </FormikProvider>
            </StackedModalProvider>
            <UpdateSweepsTextModal
              isOpen={updateSweepsModal}
              onClose={() => setUpdateSweepsModal(false)}
              onConfirm={() => {
                setField("preSaveContestMessage", {
                  en: buildPreSaveContestMessage(
                    fields.preSaveExtraContestEntries.content,
                  ),
                });

                setField("preSaveContestThankYouMessage", {
                  en: buildPreSaveContestThankYouMessage(
                    fields.preSaveExtraContestEntries.content,
                  ),
                });

                setUpdateSweepsModal(false);
              }}
            />
            {fields.isContest.content && (
              <div>
                <SweepsOptions>
                  <Switch
                    value={!!fields.hasPreSaveExtraContestEntries.content}
                    onChange={(v) => {
                      setField("hasPreSaveExtraContestEntries", v);
                      setFieldTouched("preSaveExtraContestEntries");
                    }}
                  />
                  <Body1>
                    Allow respondents to earn additional sweepstakes entries
                    when pre-saving
                  </Body1>
                  <Select
                    data-entries-select
                    placeholder="Select"
                    value={
                      fields.preSaveExtraContestEntries
                        .content as unknown as string
                    }
                    disabled={!fields.hasPreSaveExtraContestEntries.content}
                    options={[1, 3, 5, 10].map((item) => ({
                      value: item,
                      label: item === 1 ? `+${item} Entry` : `+${item} Entries`,
                    }))}
                    onChange={(v) => {
                      const value = Number(v);

                      if (fields.preSaveExtraContestEntries.content !== value) {
                        setUpdateSweepsModal(true);
                      }

                      setField("preSaveExtraContestEntries", value);
                    }}
                  />
                </SweepsOptions>
                {fields.preSaveExtraContestEntries.isTouched &&
                  validation.preSaveExtraContestEntries && (
                    <Caption error>
                      {validation.preSaveExtraContestEntries}
                    </Caption>
                  )}
              </div>
            )}
            <SurveyInputListItem>
              <TextInput
                label="Pre-Save Message"
                placeholder="Enter a Pre-Save message..."
                hasError={
                  fields.preSaveMessage.isTouched && !!validation.preSaveMessage
                }
                helperText={
                  (fields.preSaveMessage.isTouched &&
                    validation.preSaveMessage) ||
                  ""
                }
                value={fields.preSaveMessage.content.en}
                onChange={(text) => setField("preSaveMessage", { en: text })}
                onBlur={() => setFieldTouched("preSaveMessage")}
              />
              <SurveyInputHelpText style={{ alignSelf: "" }}>
                Tell your fans when the pre-save will be available.
              </SurveyInputHelpText>
            </SurveyInputListItem>
            {fields.isContest.content &&
            fields.hasPreSaveExtraContestEntries.content ? (
              <>
                <SurveyInputListItem>
                  <TextInput
                    label="Sweepstakes Message"
                    placeholder="Enter a Pre-Save sweepstakes message..."
                    hasError={
                      fields.preSaveContestMessage.isTouched &&
                      !!validation.preSaveContestMessage
                    }
                    helperText={
                      (fields.preSaveContestMessage.isTouched &&
                        validation.preSaveContestMessage) ||
                      ""
                    }
                    value={fields.preSaveContestMessage.content.en}
                    onChange={(text) =>
                      setField("preSaveContestMessage", { en: text })
                    }
                    onBlur={() => setFieldTouched("preSaveContestMessage")}
                  />
                  <SurveyInputHelpText style={{ alignSelf: "" }}>
                    Tell your fans about the sweepstakes.
                  </SurveyInputHelpText>
                </SurveyInputListItem>
                <SurveyInputListItem>
                  <TextInput
                    label="Success Message (Bonus Entries)"
                    placeholder="Enter a Pre-Save sweepstakes success message..."
                    hasError={
                      fields.preSaveContestThankYouMessage.isTouched &&
                      !!validation.preSaveContestThankYouMessage
                    }
                    helperText={
                      (fields.preSaveContestThankYouMessage.isTouched &&
                        validation.preSaveContestThankYouMessage) ||
                      ""
                    }
                    value={fields.preSaveContestThankYouMessage.content.en}
                    onChange={(text) =>
                      setField("preSaveContestThankYouMessage", { en: text })
                    }
                    onBlur={() =>
                      setFieldTouched("preSaveContestThankYouMessage")
                    }
                  />
                  <SurveyInputHelpText style={{ alignSelf: "" }}>
                    Tell fans they pre-saved and earned bonus entries.
                  </SurveyInputHelpText>
                </SurveyInputListItem>
              </>
            ) : (
              <SurveyInputListItem>
                <TextInput
                  label="Success Message"
                  placeholder="Enter a Pre-Save success message..."
                  hasError={
                    fields.preSaveThankYouMessage.isTouched &&
                    !!validation.preSaveThankYouMessage
                  }
                  helperText={
                    (fields.preSaveThankYouMessage.isTouched &&
                      validation.preSaveThankYouMessage) ||
                    ""
                  }
                  value={fields.preSaveThankYouMessage.content.en}
                  onChange={(text) =>
                    setField("preSaveThankYouMessage", { en: text })
                  }
                  onBlur={() => setFieldTouched("preSaveThankYouMessage")}
                />
                <SurveyInputHelpText style={{ alignSelf: "" }}>
                  Tell your fans that they have successfully pre-saved.
                </SurveyInputHelpText>
              </SurveyInputListItem>
            )}
          </Content>
        )}
        {fields.hasSpotifyPlaylist.content && (
          <Content>
            {fields.isContest.content && (
              <SpotifyPlaylistSweepstakes>
                <SpotifyPlaylistSwitchContainer>
                  <Switch
                    value={
                      fields.spotifyPlaylistGivesSweepstakesEntries.content
                    }
                    onChange={(value) => {
                      setField("spotifyPlaylistGivesSweepstakesEntries", value);
                      buildSpotifyPlaylistFields(
                        setField,
                        value && nextSweepstakesEntriesCount.current,
                      );
                    }}
                  />
                  <Body1>
                    Give respondents extra sweepstakes entries when sharing on
                    Spotify.
                  </Body1>
                </SpotifyPlaylistSwitchContainer>
                <SpotifyPlaylistSweepstakesSelect
                  value={
                    fields.spotifyPlaylistSweepstakesEntriesCount.content
                      ? `${fields.spotifyPlaylistSweepstakesEntriesCount.content}`
                      : ""
                  }
                  onChange={(value) => {
                    if (
                      +value !==
                      fields.spotifyPlaylistSweepstakesEntriesCount.content
                    ) {
                      nextSweepstakesEntriesCount.current = +value;
                      toggleSweepsTextConfirmModalOpen();
                    }
                  }}
                  placeholder="Select an option..."
                  options={[
                    { label: "+1 Entry", value: "1" },
                    { label: "+3 Entries", value: "3" },
                    { label: "+5 Entries", value: "5" },
                  ]}
                  style={{ maxWidth: 300 }}
                  disabled={
                    !fields.spotifyPlaylistGivesSweepstakesEntries.content
                  }
                  hasError={
                    fields.spotifyPlaylistGivesSweepstakesEntries.isTouched &&
                    !!validation.spotifyPlaylistGivesSweepstakesEntries
                  }
                />
                <UpdateSweepsTextModal
                  isOpen={updateSweepsModal}
                  onClose={() => setUpdateSweepsModal(false)}
                  onConfirm={() => {
                    setField(
                      "spotifyPlaylistSweepstakesEntriesCount",
                      nextSweepstakesEntriesCount.current,
                    );
                    buildSpotifyPlaylistFields(
                      setField,
                      nextSweepstakesEntriesCount.current,
                    );
                    toggleSweepsTextConfirmModalOpen();
                  }}
                />
              </SpotifyPlaylistSweepstakes>
            )}
            <SurveyInputListItem>
              <TextInput
                label="Create Spotify Playlist Text"
                placeholder="Enter spotify Playlist text"
                hasError={
                  fields.spotifyPlaylistText.isTouched &&
                  !!validation.spotifyPlaylistText
                }
                helperText={
                  (fields.spotifyPlaylistText.isTouched &&
                    validation.spotifyPlaylistText) ||
                  ""
                }
                value={fields.spotifyPlaylistText.content.en}
                onChange={(text) =>
                  setField("spotifyPlaylistText", { en: text })
                }
                onBlur={() => setFieldTouched("spotifyPlaylistText")}
              />
              <SurveyInputHelpText style={{ alignSelf: "center" }}>
                Encourage your fans to share their playlist on Spotify.
              </SurveyInputHelpText>
            </SurveyInputListItem>
            <SurveyInputListItem>
              <TextInput
                label="Success Message"
                placeholder="Enter success message"
                hasError={
                  fields.spotifyPlaylistSuccessMessage.isTouched &&
                  !!validation.spotifyPlaylistSuccessMessage
                }
                helperText={
                  (fields.spotifyPlaylistSuccessMessage.isTouched &&
                    validation.spotifyPlaylistSuccessMessage) ||
                  ""
                }
                value={fields.spotifyPlaylistSuccessMessage.content.en}
                onChange={(text) =>
                  setField("spotifyPlaylistSuccessMessage", { en: text })
                }
                onBlur={() => setFieldTouched("spotifyPlaylistSuccessMessage")}
              />
              <SurveyInputHelpText style={{ alignSelf: "center" }}>
                Tell your fans that their playlist was successfully created.
              </SurveyInputHelpText>
            </SurveyInputListItem>
          </Content>
        )}
      </SectionContainer>
    </SurveyInputList>
  );
};

const SectionContainer = styled(FlexColumn)`
  margin-bottom: -4px;
  padding: 0 0 2px 0;
  ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 0;
    margin-bottom: -8px;
  }
`;

const SurveyBodyTextArea = styled(Textarea)`
  textarea {
    height: 137px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    font-family: var(--max-font-primary);
    color: var(--text-color);

    ::placeholder {
      color: #999999;
      font-family: var(--max-font-primary);
      font-weight: 400;
      font-size: 12px;
      line-height: normal;
      user-select: none;
    }
  }
`;

const Divider = styled.div`
  background-color: var(--box-shadow-color);
  height: 1px;
  margin: 12px -32px 26px;

  ${({ theme }) => theme.mediaQueries.mobile} {
    margin: 12px -20px 16px;
  }
`;

const Content = styled(FlexColumn)`
  margin-top: 20px;
  padding-bottom: 16px;
  gap: 20px;
`;

const SpotifyPlaylistSweepstakes = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  background: #f2f5f7;
  padding: 20px;
  gap: 20px;

  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
  }
`;

const SpotifyPlaylistSwitchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  ${Body1} {
    color: var(--text-color);
  }
`;

const SpotifyPlaylistSweepstakesSelect = styled(Select)`
  max-width: 300px;
`;

interface PromotionGridProps {
  hasSongsQuestion: boolean;
}
const PromotionGrid = styled.div<PromotionGridProps>`
  display: grid;
  grid-template-columns: repeat(
    ${({ hasSongsQuestion }) => (hasSongsQuestion ? 4 : 3)},
    minmax(0, 1fr)
  );
  grid-auto-rows: minmax(0, 1fr);
  gap: 20px;
  margin-top: 20px;

  ${({ hasSongsQuestion }) =>
    hasSongsQuestion &&
    css`
      @media (max-width: 1500px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
    `}

  ${({ theme }) => theme.mediaQueries.mobile} {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
`;

const PromotionOption = styled.button<{ $active?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 14px;
  text-align: left;
  padding: 16px 20px;
  background: #ffffff;
  border: ${(p) =>
    p.$active ? "1px solid var(--main-color)" : "1px solid #d4d4d4"};
  outline: ${(p) => p.$active && "var(--main-color) solid 1px"};
  border-radius: 12px;
  font-weight: ${(p) => (p.$active ? "600" : "")};
  color: var(--main-color);
  cursor: pointer;
  position: relative;

  ${({ theme }) => theme.mediaQueries.mobile} {
    gap: 8px;
  }
`;

const PromotionIcon = styled.svg<{ $active?: boolean }>`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  stroke: ${(p) => (p.$active ? "var(--main-color)" : "")};

  ${({ theme }) => theme.mediaQueries.mobile} {
    width: 18px;
    height: 18px;
  }
`;

const StyledCheckIcon = styled(CheckIcon)`
  width: 18px;
  height: 18px;
  position: absolute;
  top: -8px;
  right: -8px;
  color: var(--main-color);
  border: 1px solid white;
  border-radius: 50%;
`;

const SweepsOptions = styled.div`
  padding: 20px 25px;
  background: #f2f5f7;
  border-radius: 12px;
  display: grid;
  grid-template-columns: min-content auto 245px;
  gap: 20px;
  align-items: center;

  ${({ theme }) => theme.mediaQueries.mobile} {
    grid-template-columns: min-content auto;
    grid-template-rows: 1fr 1fr;

    [data-entries-select] {
      grid-column: 1 / span 2;
    }
  }
`;
