import React from "react";
import styled from "styled-components";
import { Card } from "melodies-source/Card";
import { H2, H4 } from "melodies-source/Text";
import { GtbRegion } from "@max/common/src/thirdparty/gtb";
import { formatNumber } from "Utils/format";
import { useHistory } from "react-router-dom";
import { useCustomAppContext } from "contexts/CustomAppContext";
import { GtbCustomApp } from "custom/companies/gtb/types";

interface Props {
  programs: GtbRegion["programs"];
}

export const TopPrograms: React.FC<Props> = ({ programs }) => {
  const history = useHistory();
  const { customApp } = useCustomAppContext<GtbCustomApp>();

  return (
    <StyledCard isElevated>
      <HeaderContainer>
        <H2>TOP {customApp?.event.owner.plural.toUpperCase()}</H2>
        <span>Past 30 Days</span>
      </HeaderContainer>
      <Divider />
      <Programs>
        {Object.entries(programs)
          .sort(
            ([, programA], [, programB]) =>
              programB.contacts30day - programA.contacts30day,
          )
          .slice(0, 5)
          .map(([programId, program], position) => (
            <Program key={programId}>
              <ProgramPosition>{position + 1}.</ProgramPosition>
              <div>
                <ProgramName onClick={() => history.push(`/${programId}`)}>
                  {program.name}
                </ProgramName>
                <ProgramContactsAdded>
                  {formatNumber(program.contacts30day)}{" "}
                  {program.contacts30day === 1 ? "Contact" : "Contacts"} Added
                </ProgramContactsAdded>
              </div>
            </Program>
          ))}
      </Programs>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  flex: 1;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${H2} {
    font-weight: 400;
  }

  span {
    color: #999;
    font-size: 14px;
  }
`;

const Divider = styled.div`
  width: 44px;
  height: 3px;
  background: var(--tertiary-color);
  margin: 7px 0 25px;
`;

const Programs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Program = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const ProgramPosition = styled(H4)`
  color: var(--tertiary-color);
  font-weight: 700;
  line-height: 0.95;
`;

const ProgramName = styled(H4)`
  line-height: 20px;
  cursor: pointer;

  &:hover {
    color: var(--primary-button-hover-color);
  }
`;

const ProgramContactsAdded = styled.span`
  font-size: 12px;
  font-weight: 400;
`;
