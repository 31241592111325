import { useUser } from "auth";
import { useQueryParams } from "hooks";
import { Redirect, Route, Switch } from "react-router-dom";
import { SessionLayout } from "./Layout";
import { LoginWithPassword } from "./LoginWithPassword";
import { LoginWithSSO } from "./LoginWithSSO";
import { SESSION_PATHS } from "./paths";
import { ForgotPassword } from "./ForgotPassword";
import { useEffect, useLayoutEffect, useMemo } from "react";
import * as sessionApi from "auth/session";
import { AppLoading } from "melodies-source/AppLoading";
import { Toaster } from "Components/Toaster";
import styled from "styled-components";
import { LoginWithMFA } from "./LoginWithMFA";
import { LoginWithEmailToSetupMFA } from "./LoginWithEmailToSetupMFA";

export const SessionRouter = () => {
  const { user, isLoggedIn } = useUser();
  const { redirect } = useQueryParams(["redirect"]);
  const params = useMemo(() => new URLSearchParams(), []);
  const isAnonUser = !!user?.isAnonymous;

  if (redirect) {
    params.append("redirect", redirect);
  }

  useEffect(() => {
    if (isLoggedIn && !isAnonUser) {
      const redirect = params.get("redirect")?.match(/^https?/i)
        ? sessionApi.go(decodeURIComponent(params.get("redirect")))
        : params.get("redirect") ?? "/";

      window.location.href = redirect;
    }
  }, [isLoggedIn, isAnonUser, params]);

  useLayoutEffect(() => {
    document.getElementById("loader")?.remove();
  }, []);

  if (isLoggedIn && !isAnonUser) {
    return null;
  }

  const cssVariablesLoaded = getComputedStyle(
    document.getElementById("root"),
  ).getPropertyValue("--variables-loaded");

  if (!cssVariablesLoaded) {
    return <AppLoading />;
  }

  return (
    <SessionLayout size={476} light={false} header={false}>
      <Toaster />
      <Container>
        <Switch>
          <Route path={SESSION_PATHS.LOGIN_WITH_MFA} component={LoginWithMFA} />
          <Route
            path={SESSION_PATHS.LOGIN_WITH_PASSWORD}
            component={LoginWithPassword}
          />
          <Route path={SESSION_PATHS.LOGIN_WITH_SSO} component={LoginWithSSO} />
          <Route
            path={SESSION_PATHS.FORGOT_PASSWORD}
            component={ForgotPassword}
          />
          <Route
            path={SESSION_PATHS.MFA_REQUIRED}
            component={LoginWithEmailToSetupMFA}
          />
          <Redirect
            to={{
              pathname: SESSION_PATHS.LOGIN_WITH_PASSWORD,
              search: `?${params}`,
            }}
          />
        </Switch>
      </Container>
    </SessionLayout>
  );
};

const Container = styled.div`
  padding: 40px 48px;

  ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 32px 20px;
  }
`;
