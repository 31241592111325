import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import { H2, H4 } from "melodies-source/Text";
import { Card } from "Components";
import { Modal } from "melodies-source/Modal";
import { Button } from "melodies-source/Button";
import { SetLiveEvent } from "models/event";
import { ReactComponent as CalendarIcon } from "assets/svg/calendar.svg";
import { Spinner } from "melodies-source/Spinner";
import {
  useTypesenseProxyContext,
  ProxySearchParams,
} from "contexts/TypesenseProxyContext";
import { DateTime } from "luxon";
import { useCustomAppContext } from "contexts/CustomAppContext";
import { GtbCustomApp } from "custom/companies/gtb/types";

const MAX_EVENTS_IN_CARD = 4;

interface Props {
  programIds: string[];
}

export const UpcomingEvents: React.FC<Props> = ({ programIds }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [events, setEvents] = useState<SetLiveEvent[]>([]);
  const [loading, setLoading] = useState(true);
  const { proxySearch } = useTypesenseProxyContext();
  const { customApp } = useCustomAppContext<GtbCustomApp>();

  const toggleModal = () => setModalIsOpen((open) => !open);

  const getEvents = useCallback(async () => {
    const searchParameters: ProxySearchParams = {
      q: "*",
      query_by: "venue",
      filter_by: `groupId:[${programIds.map(
        (id) => `"${id}"`,
      )}] && endedAt:=0 && deletedAt:=0`,
      sort_by: "startsAt:asc",
    };

    try {
      const resp = await proxySearch("setlive_events", searchParameters);
      const events = resp?.hits?.map((event) => event.document) || [];
      setEvents(events);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }, [proxySearch, programIds]);

  const formatDate = (millis: number) =>
    millis < Date.now()
      ? "LIVE NOW"
      : DateTime.fromMillis(millis)
          .toLocaleString()
          .split("/")
          .slice(0, 2)
          .join("/");

  const handleEventOpen = (eventId: string) => {
    window.open(
      `/go?url=${encodeURIComponent(`${customApp?.onsiteAppUrl}/${eventId}`)}`,
      "_blank",
    );
  };

  useEffect(() => {
    (async () => {
      if (!proxySearch || !getEvents) return;
      await getEvents();
    })();
  }, [proxySearch, getEvents]);

  return (
    <StyledCard
      isElevated
      {...(events.length > MAX_EVENTS_IN_CARD && {
        action: { text: "View All", onClick: toggleModal },
      })}
    >
      <Container>
        <HeaderContainer>
          <H2>UPCOMING {customApp?.event.type.plural.toUpperCase()}</H2>
          <span>Next 30 Days</span>
        </HeaderContainer>
        <Divider />
        {loading ? (
          <Spinner />
        ) : events.length > 0 ? (
          <Events>
            {events.slice(0, MAX_EVENTS_IN_CARD).map((event) => (
              <Event key={event.id}>
                <EventLogo
                  src={`${process.env.REACT_APP_ASSETS_ROCK_PATH}/g/${event.groupId}`}
                  alt="event logo"
                />
                <div>
                  <EventName onClick={() => handleEventOpen(event.id)}>
                    {event.artistName}
                  </EventName>
                  <EventLocation>
                    {event.venue} | {event.displayAddress}
                  </EventLocation>
                </div>
                <EventDate>{formatDate(event.startsAt)}</EventDate>
              </Event>
            ))}
          </Events>
        ) : (
          <NoEvents>
            <CalendarIcon width="25px" />
            <span>No {customApp?.event.type.plural}</span>
          </NoEvents>
        )}
      </Container>
      <Modal
        isOpen={modalIsOpen}
        header={`Upcoming ${customApp?.event.type.plural}`}
        onClose={toggleModal}
      >
        <ModalContainer>
          <Events>
            {events.map((event) => (
              <Event key={event.id}>
                <div>
                  <EventName onClick={() => handleEventOpen(event.id)}>
                    {event.artistName}
                  </EventName>
                  <EventLocation>
                    {event.venue} | {event.displayAddress}
                  </EventLocation>
                </div>
                <EventDate>{formatDate(event.startsAt)}</EventDate>
              </Event>
            ))}
          </Events>
          <ModalFooter>
            <Button variant="outlined" onClick={toggleModal}>
              Close
            </Button>
          </ModalFooter>
        </ModalContainer>
      </Modal>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  margin: 0;
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: auto auto 1fr;
  height: 100%;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${H2} {
    font-weight: 400;
    flex-shrink: 0;
  }

  span {
    color: #999;
    font-size: 14px;
    text-align: right;
  }
`;

const Divider = styled.div`
  width: 44px;
  height: 3px;
  background: var(--main-color);
  margin: 7px 0 25px;
`;

const Events = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Event = styled.div`
  display: flex;
  gap: 10px;
`;

const EventName = styled(H4)`
  color: var(--main-color);
  line-height: 16px;
  cursor: pointer;

  &:hover {
    color: var(--primary-button-hover-color);
  }
`;

const EventLogo = styled.img`
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border: 1px solid white;
`;

const EventLocation = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
`;

const EventDate = styled.span`
  font-size: 14px;
  font-weight: 500;
  margin-left: auto;
  flex-shrink: 0;
`;

const ModalContainer = styled.div`
  padding: 5px 0;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 35px;
`;

const NoEvents = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 10px;

  svg {
    padding: 0 !important;
  }

  ${({ theme }) => theme.media.desktop} {
    margin-top: -50px;
  }
`;
