import React, { useState } from "react";
import styled, { css } from "styled-components";
import { DateTime } from "luxon";
import { useUser } from "auth";
import { ContactUs } from "./ContactUs";
import { useCustomAppContext } from "contexts/CustomAppContext";
import Logo from "svg/Logo";

interface Props {
  className?: string;
  withLogo?: boolean;
}

export const Footer: React.FC<Props> = ({ className, withLogo = true }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { claims } = useUser();
  const date = DateTime.now().toFormat("yyyy");

  const { customApp } = useCustomAppContext();

  const gitHash = process.env.REACT_APP_SHA || "dev";

  const toggleModal = () => setModalIsOpen((open) => !open);
  return (
    <>
      <TopContainer className={className}>
        <SetLiveContainer>
          {withLogo && <StyledLogo />}
          <Copyright>
            © {date} {customApp?.company.name || "SET"}{" "}
            {gitHash && (
              <>
                ({""}
                <Link
                  href={`https://releases.set.live/${gitHash}`}
                  target="_blank"
                  style={{ fontWeight: 400 }}
                >
                  {gitHash.substring(0, 7)}
                </Link>{""}
                )
              </>
            )}
          </Copyright>
        </SetLiveContainer>
        <LinksContainer>
          {customApp ? (
            customApp.footerLinks.map((link) => (
              <Link key={link.text} href={link.url} target="_blank">
                {link.text}
              </Link>
            ))
          ) : (
            <>
              <Link
                onClick={() =>
                  window.open(`${process.env.REACT_APP_SL_URL}/privacy`)
                }
              >
                Privacy Policy
              </Link>
              <Link
                onClick={() =>
                  window.open(`${process.env.REACT_APP_SL_URL}/terms`)
                }
              >
                Fan Terms of Use
              </Link>
              <Link
                onClick={() =>
                  window.open(`${process.env.REACT_APP_SL_URL}/artist-terms`)
                }
              >
                Artist Terms of Use
              </Link>
              <Link onClick={toggleModal}>Contact Us</Link>
            </>
          )}
        </LinksContainer>
      </TopContainer>
      {/* <BottomContainer>
        {isDesktop && (
          <Font10 fontWeight={300} color="#666">
            Language
          </Font10>
        )}
        <Font11
          fontWeight={500}
          color="#999"
          onClick={() => console.log("English selected")}
          style={{ cursor: "pointer" }}
        >
          English
        </Font11>
        <Font11
          fontWeight={600}
          color="#666"
          onClick={() => console.log("Espanol selected")}
          style={{ cursor: "pointer" }}
        >
          Español
        </Font11>
      </BottomContainer> */}
      <ContactUs open={modalIsOpen} toggle={toggleModal} />
    </>
  );
};

const TopContainer = styled.div(
  () => css`
    display: flex;
    justify-content: space-between;
    padding: 16px 20px;
    align-items: flex-end;
    background: ${({ theme }) =>
      theme.custom?.layout.footer.background || "#f2f5f7"};
    box-shadow: inset 0px -1px 0px var(--box-shadow-color);

    ${({ theme }) => theme.media.mobile} {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  `,
);

const LinksContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 40px;
  flex: 1;
  flex-wrap: wrap;
  max-width: 500px;

  ${({ theme }) => theme.media.desktop} {
    margin-right: 10px;
  }

  ${({ theme }) => theme.media.mobile} {
    text-align: center;
    justify-content: center;
    row-gap: 10px;
  }
`;

const Link = styled.a(
  () => css`
    text-decoration: none;
    cursor: pointer;
    color: ${({ theme }) => theme.custom?.layout.footer.color || "#666"};
    font-weight: 600;
    font-size: 10px;
    line-height: 15px;

    ${({ theme }) => theme.media.mobile} {
      flex: 0 0 33.333333%;
    }
  `,
);

const SetLiveContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${({ theme }) => theme.media.mobile} {
    align-items: center;
    margin-bottom: 18px;
  }
`;

const StyledLogo = styled(Logo)`
  cursor: pointer;
  height: 24px;
  margin-bottom: 7px;

  ${({ theme }) => theme.media.mobile} {
    height: 19px;
  }
`;

const Copyright = styled.span`
  font-size: 10px;
  color: ${({ theme }) => theme.custom?.layout.footer.color || "#666"};
`;
