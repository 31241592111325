import { Modal, ModalProps } from "melodies-source/Modal";
import {
  Modal as InternalModal,
  ModalProps as InternalModalProps,
} from "Components/Modal";
import {
  createContext,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

const Context = createContext<{
  stack: string[];
  push: (id: string) => void;
  pop: (id: string) => void;
}>({
  stack: [],
  push: () => {},
  pop: () => {},
});

const last = (id: string, stack: string[]) => {
  return stack.length > 0 && stack[stack.length - 1] === id;
};

export const StackedModalProvider = ({
  children,
}: {
  children?: ReactNode;
}) => {
  const [stack, setStack] = useState([]);

  const push = useCallback((id: string) => {
    setStack((p) => p.concat([id]));
  }, []);

  const pop = useCallback((id: string) => {
    setStack((p) => {
      if (last(id, p)) {
        return p.slice(0, p.length - 1);
      }

      return p;
    });
  }, []);

  return (
    <Context.Provider value={{ stack, push, pop }}>{children}</Context.Provider>
  );
};

const useStackedModal = ({ isOpen }: { isOpen: boolean }) => {
  const { stack, push, pop } = useContext(Context);
  const id = useRef((Math.random() + 1).toString(36).substring(7));

  useEffect(() => {
    const currentId = id.current;
    if (isOpen) {
      push(currentId);
    } else {
      pop(currentId);
    }
    return () => pop(currentId);
  }, [push, pop, isOpen]);

  return { stack, id };
};

export const StackedModal = ({
  isOpen,
  ...props
}: PropsWithChildren<Omit<ModalProps, "withBackdropClose">>) => {
  const { stack, id } = useStackedModal({ isOpen });

  return (
    <Modal
      {...props}
      isOpen={isOpen}
      withBackdropClose={last(id.current, stack)}
    />
  );
};

export const StackedInternalModal = ({
  isOpen,
  ...props
}: PropsWithChildren<Omit<InternalModalProps, "withBackdropClose">>) => {
  const { stack, id } = useStackedModal({ isOpen });

  return (
    <InternalModal
      {...props}
      isOpen={isOpen}
      withBackdropClose={last(id.current, stack)}
    />
  );
};
