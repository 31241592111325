import styled from "styled-components";
import Modal from "../modal";
import { Button } from "melodies-source/Button";
import { useState } from "react";
import { useDesktopMediaQuery } from "hooks";
import { CircularLoader, useArtistContext } from "Components";
import { TextInput } from "melodies-source/TextInput";
import CheckCircleIcon from "../CheckCircleIcon";
import { httpsCallable } from "firebase/functions";
import { collection, addDoc } from "firebase/firestore"; 
import { functions, firestore } from "firebase-internal";

interface ConnectRequest {
  action: "connect";
  artistGroupId: string;
  apiKey: string;
  clientId: string;
}

interface UnlinkRequest {
  action: "remove";
  artistGroupId: string;
}

type Response = boolean;

export const RivetIntegrationModal = ({
  artistGroupId,
  isOpen,
  onClose,
}: {
  artistGroupId: string;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const [status, setStatus] = useState<
    "addApiKey" | "testingApiKey" | "apiKeyAdded"
  >("addApiKey");
  const [apiAddError, setApiAddError] = useState("");
  const [clientId, setClientId] = useState("");
  const [apiKey, setApiKey] = useState("");
  const { logAction } = useArtistContext();

  const isDesktop = useDesktopMediaQuery();

  const onSubmitApiKey = async () => {
    setApiAddError("");
    setStatus("testingApiKey");

    try {
      const connect = httpsCallable<ConnectRequest, Response>(
        functions,
        "services-rivetActions",
      );
      const response = await connect({
        action: "connect",
        artistGroupId,
        apiKey,
        clientId,
      });

      if (!response.data) {
        throw new Error("Error testing api key, please verify input.");
      }

      await logAction("portal_connect_rivet");
      setStatus("apiKeyAdded");
    } catch (error) {
      console.error(error);

      setApiAddError((error as Error).message);

      setStatus("addApiKey");
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} service="rivet">
      {status === "addApiKey" && (
        <>
          <HeaderText isDesktop={isDesktop}>Create API Key</HeaderText>
          <br />
          <BodyText isDesktop={isDesktop}>
            Please generate your Rivet access key and enter it below.
            For security reasons, do not share your key via email; our system will encrypt it.
          </BodyText>
          <br />
          <InputLabel isDesktop={isDesktop}>Rivet Client ID</InputLabel>
          <TextInput
            placeholder="Enter Client ID"
            value={clientId}
            onChange={(text) => setClientId(text)}
            hasError={!!apiAddError}
          />
          <br />
          <InputLabel isDesktop={isDesktop}>Rivet Access Key</InputLabel>
          <TextInput
            placeholder="Enter Access Key"
            value={apiKey}
            onChange={(text) => setApiKey(text)}
            hasError={!!apiAddError}
          />
          {!!apiAddError && (
            <ErrorText isDesktop={isDesktop}>{apiAddError}</ErrorText>
          )}
          <br />
          <FooterText isDesktop={isDesktop}>
            If you need help with creating one,{" "}
            <a href="https://www.rivetapp.co/find-fans/integrations/d/set/settings" target="__blank">
              click here
            </a>{" "}
            to read Rivet's guide.
          </FooterText>
          <br />
          <StyledButton disabled={!apiKey || !clientId} onClick={onSubmitApiKey}>
            Add key and test connection
          </StyledButton>
        </>
      )}
      {status === "testingApiKey" && (
        <>
          <CircularLoader
            height={isDesktop ? 60 : 41}
            sx={{ marginBottom: 10 }}
          />
          <BodyText isDesktop={isDesktop}>Please wait...</BodyText>
          <BodyText isDesktop={isDesktop}>
            We're adding the API Key and testing connection
          </BodyText>
        </>
      )}
      {status === "apiKeyAdded" && (
        <>
          <CheckCircleIcon />
          <BodyText isDesktop={isDesktop}>Success!</BodyText>
          <BodyText isDesktop={isDesktop}>
            Connection stable and API Key added
          </BodyText>
          <Button
            style={{ marginTop: 20 }}
            onClick={() => onBackfillClickHandler(artistGroupId, onClose)}
          >
            <span style={{ marginRight: "0.35rem" }}>
              Sync all existing fans to Rivet
            </span>
          </Button>
        </>
      )}
    </Modal>
  );
};

const onBackfillClickHandler = async (
  artistGroupId: string,
  onClose: () => void,
) => {
  await addDoc(collection(firestore, `/artist_groups/${artistGroupId}/artist_group_private/rivet/backfills`), {
    backfillRequest: new Date(),
  });

  onClose();
};

export const RivetRemovalModal = ({
  artistGroupId,
  onClose,
  isOpen,
}: {
  artistGroupId: string;
  onClose: () => void;
  isOpen: boolean;
}) => {
  const [removingRivet, setRemovingRivet] = useState(false);
  const [removalError, setRemovalError] = useState("");
  const { logAction } = useArtistContext();

  const isDesktop = useDesktopMediaQuery();

  const onRemoveRivet = async () => {
    setRemovingRivet(true);

    setRemovalError("");

    try {
      const remove = httpsCallable<UnlinkRequest, Response>(
        functions,
        "services-rivetActions",
      );
      await remove({
        action: "remove",
        artistGroupId,
      });

      await logAction("portal_remove_rivet");

      onClose();
    } catch (error) {
      console.error((error as Error).message);

      setRemovalError(
        `Error removing Rivet account: ${(error as Error).message}`,
      );
    }

    setRemovingRivet(false);
  };

  const modalBody = () => {
    if (removalError) {
      return (
        <HeaderText style={{ color: "#e45c52" }} isDesktop={isDesktop}>
          {removalError}
        </HeaderText>
      );
    }

    if (removingRivet) {
      return (
        <>
          <CircularLoader height={30} sx={{ marginRight: "0.5rem" }} />
          <br />
          <BodyText isDesktop={isDesktop}>Removing Rivet account...</BodyText>
        </>
      );
    }
    return (
      <HeaderText isDesktop={isDesktop}>
        Are you sure you want to unlink your Rivet account?
      </HeaderText>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} service="rivet">
      {modalBody()}
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          marginTop: 40,
        }}
      >
        <Button
          onClick={() => onRemoveRivet()}
          style={{ marginRight: "2rem" }}
          disabled={removingRivet}
        >
          Confirm
        </Button>
        <Button variant="secondary" onClick={onClose} disabled={removingRivet}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

const BodyText = styled.div<{ isDesktop: boolean }>`
  font-weight: 400;
  font-size: ${({ isDesktop }) => (isDesktop ? "17px" : "12px")};
  line-height: ${({ isDesktop }) => (isDesktop ? "25.5px" : "18px")};
`;

const ErrorText = styled.div<{ isDesktop: boolean }>`
  color: #e45c52;
  font-weight: 500;
  font-size: ${({ isDesktop }) => (isDesktop ? "min(1.3vw, 12px)" : "13px")};
  line-height: 19.5px;
  margin-top: 5px;
  align-self: flex-start;
  margin-left: 10px;
`;

const FooterText = styled.div<{ isDesktop: boolean }>`
  font-weight: 500;
  font-size: ${({ isDesktop }) => (isDesktop ? "13px" : "10px")};
  line-height: 15px;
`;

const HeaderText = styled.div<{ isDesktop: boolean }>`
  font-weight: 600;
  font-size: ${({ isDesktop }) => (isDesktop ? "20px" : "17px")};
  line-height: ${({ isDesktop }) => (isDesktop ? "30px" : "25.5px")};
`;

const StyledButton = styled(Button)`
  max-width: 385px;
`;

const InputLabel = styled.div<{ isDesktop: boolean }>`
  font-weight: 400;
  margin-right: auto;
  font-size: ${({ isDesktop }) => (isDesktop ? "14px" : "10px")};
  line-height: ${({ isDesktop }) => (isDesktop ? "25.5px" : "18px")};
`;