"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getConfigFromHost = exports.companies = void 0;
const companies_1 = require("./companies");
exports.companies = [
    [/gtbevents.live$/, companies_1.gtb],
    [/towne.live$/, companies_1.towne],
    [/fizzevents.live$/, companies_1.fizz],
    [/handh.live$/, companies_1.hearthHarmony],
    [/strawfest.live$/, companies_1.rogueValleyStrawfest],
    [/synthscape.live$/, companies_1.synthscape],
    [/bakersfieldfilm.live$/, companies_1.bakersfieldFilmFestival],
];
const getConfigFromHost = (host, config) => {
    for (const [k, v] of config) {
        if (host.match(k)) {
            return v;
        }
    }
    return undefined;
};
exports.getConfigFromHost = getConfigFromHost;
