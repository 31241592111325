import { getConfigFromHost } from "@max/thirdparty";
import {
  gtb,
  towne,
  fizz,
  hearthHarmony,
  rogueValleyStrawfest,
  synthscape,
  bakersfieldFilmFestival,
} from "./companies";

const companies = [
  [/gtbevents.live$/, gtb] as const,
  [/towne.live$/, towne] as const,
  [/fizzevents.live$/, fizz] as const,
  [/handh.live$/, hearthHarmony] as const,
  [/strawfest.live$/, rogueValleyStrawfest] as const,
  [/synthscape.live$/, synthscape] as const,
  [/bakersfieldfilm.live$/, bakersfieldFilmFestival] as const,
];

export const getConfig = (host: string) => getConfigFromHost(host, companies);
