import { typedFields, useConfigSlice } from "../Components/BuilderContext";
import { FlexColumn } from "Routes/styled";
import { Select } from "Components/Select";
import { Selectable } from "melodies-source/Selectable";
import { Body2, Caption, Td } from "melodies-source/Text";
import { TextInput } from "melodies-source/TextInput";
import styled from "styled-components";
import { customPrizeOption, prizes } from "../constants/prizes";
import { Datepicker } from "Components/DatePicker";
import { DateTime } from "luxon";
import { Timestamp } from "firebase/firestore";
import { buildSpotifyPlaylistFields } from "../util";

export const CONTEST_FIELDS = typedFields([
  "isContest",
  "contestPrize",
  "contestEndDate",
  "contestPrizeValue",
  "contestCustomPrizeDescription",
  "hasContestCustomRules",
  "contestCustomRulesUrl",
  "spotifyPlaylistGivesSweepstakesEntries",
  "spotifyPlaylistSweepstakesEntriesCount",
]);

export const Contest = () => {
  const { fields, validation, setField, setFieldTouched } =
    useConfigSlice(CONTEST_FIELDS);

  const handleIsContestChange = (value: boolean) => {
    setField("isContest", value);
    // update spotify playlist fields if isContest value is changed
    if (value) {
      if (fields.spotifyPlaylistGivesSweepstakesEntries.content) {
        buildSpotifyPlaylistFields(
          setField,
          fields.spotifyPlaylistSweepstakesEntriesCount?.content,
        );
      }
    } else {
      buildSpotifyPlaylistFields(setField);
    }
  };

  return (
    <Wrapper>
      <Container>
        <FlexColumn>
          <Label>Do you want to make this a sweepstakes?</Label>
          <Body2 style={{ color: "#999" }}>
            Your fans are more likely to complete the survey if a sweepstakes is
            offered.
          </Body2>
        </FlexColumn>
        <RadioContainer>
          <Selectable
            variant="radio"
            onChange={() => handleIsContestChange(true)}
            label="Yes"
            value={fields.isContest.content}
            style={{ margin: 0 }}
          />
          <Selectable
            variant="radio"
            onChange={() => handleIsContestChange(false)}
            label="No"
            value={!fields.isContest.content}
            style={{ margin: 0 }}
          />
        </RadioContainer>
      </Container>
      {fields.isContest.content && (
        <>
          <Container>
            <FlexColumn>
              <Label>What prize will you offer?</Label>
              <Body2 style={{ color: "#999" }}>
                Pick from popular options from our artists or create your own.
              </Body2>
            </FlexColumn>
            <FlexColumn>
              <Select
                label="Select the Prize You Will Offer"
                placeholder="Select an option..."
                options={prizes}
                onChange={(v) => setField("contestPrize", { en: v })}
                value={fields.contestPrize.content.en}
              />
              {fields.contestPrize.isTouched && validation.contestPrize && (
                <Caption hasError>{validation.contestPrize}</Caption>
              )}
              {fields.contestPrize.content.en === customPrizeOption.value && (
                <TextInput
                  label="Prize Description"
                  placeholder="What will the prize be?"
                  value={fields.contestCustomPrizeDescription.content.en}
                  style={{ marginTop: 16 }}
                  helperText={
                    (fields.contestCustomPrizeDescription.isTouched &&
                      validation.contestCustomPrizeDescription) ||
                    ""
                  }
                  hasError={
                    fields.contestCustomPrizeDescription.isTouched &&
                    !!validation.contestCustomPrizeDescription
                  }
                  onChange={(v) =>
                    setField("contestCustomPrizeDescription", { en: v })
                  }
                  onBlur={() =>
                    setFieldTouched("contestCustomPrizeDescription")
                  }
                />
              )}
            </FlexColumn>
          </Container>
          <Container>
            <FlexColumn>
              <Label>What official rules would you like to use?</Label>
              <Caption style={{ color: "#999" }}>
                I have reviewed and accept the terms of use and will only
                operate promotions (including sweepstakes) in accordance with
                applicable laws. You are expected and encouraged to have your
                legal counsel review and such promotion terms or rules and you
                hereby agree to indemnify and hold harmless MAX from any
                liability arising out of your promotions. If you choose to use
                SET's sweepstakes rules, please note the official rules template
                is provided “as is” without warranty of any kind. SET/MAX makes
                no representations that the official rules are legally
                sufficient or otherwise appropriate for your promotion.
              </Caption>
            </FlexColumn>
            <FlexColumn>
              <SelectableList>
                <div>
                  <Selectable
                    variant="radio"
                    onChange={() => setField("hasContestCustomRules", true)}
                    label="I want to use my sweepstakes rules:"
                    value={fields.hasContestCustomRules.content === true}
                  />
                  {fields.hasContestCustomRules.content === true && (
                    <ExtraFields>
                      <TextInput
                        helperText={
                          (fields.contestCustomRulesUrl.isTouched &&
                            validation.contestCustomRulesUrl) ||
                          ""
                        }
                        hasError={
                          fields.contestCustomRulesUrl.isTouched &&
                          !!validation.contestCustomRulesUrl
                        }
                        onChange={(v) =>
                          setField("contestCustomRulesUrl", { en: v })
                        }
                        onBlur={() => setFieldTouched("contestCustomRulesUrl")}
                        value={fields.contestCustomRulesUrl.content.en}
                        label="My Sweepstakes Rules"
                        placeholder="Paste rules here..."
                      />
                    </ExtraFields>
                  )}
                </div>
                <div>
                  <Selectable
                    variant="radio"
                    onChange={() => setField("hasContestCustomRules", false)}
                    label="I'd like to use SET's sweepstakes rules"
                    description="Note: SET's rules cover only the United States"
                    value={fields.hasContestCustomRules.content === false}
                    style={{ margin: "10px 0 0 0" }}
                  />
                  {fields.hasContestCustomRules.content === false && (
                    <ExtraFields>
                      <Datepicker
                        label="Sweepstakes End Date"
                        placeholder="Pick a date"
                        value={
                          fields.contestEndDate.content
                            ? DateTime.fromJSDate(
                                fields.contestEndDate.content.toDate(),
                              )
                            : undefined
                        }
                        helperText={
                          (fields.contestEndDate.isTouched &&
                            validation.contestEndDate) ||
                          ""
                        }
                        hasError={
                          fields.contestEndDate.isTouched &&
                          !!validation.contestEndDate
                        }
                        onChange={(v) => {
                          setField(
                            "contestEndDate",
                            v ? Timestamp.fromDate(v.toJSDate()) : null,
                          );
                        }}
                        onClose={() => {
                          setFieldTouched("contestEndDate");
                        }}
                      />
                      <TextInput
                        helperText={
                          (fields.contestPrizeValue.isTouched &&
                            validation.contestPrizeValue) ||
                          ""
                        }
                        hasError={
                          fields.contestPrizeValue.isTouched &&
                          !!validation.contestPrizeValue
                        }
                        onChange={(v) => {
                          if (v) {
                            setField("contestPrizeValue", Number(v));
                          } else {
                            setField("contestPrizeValue", null);
                          }
                        }}
                        onBlur={() => setFieldTouched("contestPrizeValue")}
                        value={
                          typeof fields.contestPrizeValue.content == null
                            ? ""
                            : String(fields.contestPrizeValue.content)
                        }
                        type="number"
                        label="Estimated Prize Value ($USD)"
                        placeholder="Please enter the value of your prize"
                      />
                    </ExtraFields>
                  )}
                </div>
                {fields.hasContestCustomRules.isTouched &&
                  validation.hasContestCustomRules && (
                    <Caption hasError>
                      {validation.hasContestCustomRules}
                    </Caption>
                  )}
              </SelectableList>
            </FlexColumn>
          </Container>
        </>
      )}
    </Wrapper>
  );
};

const Label = styled(Td)`
  color: #333;
`;

const Wrapper = styled.div`
  display: grid;
  row-gap: 34px;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr min(50%, 400px);
  column-gap: 44px;

  ${({ theme }) => theme.mediaQueries.mobile} {
    grid-template-columns: 1fr;
    row-gap: 12px;
  }
`;

const RadioContainer = styled.div`
  display: grid;
  grid-template-columns: 40% 40%;
  ${({ theme }) => theme.mediaQueries.mobile} {
    grid-template-columns: 30% 30%;
  }
`;

const SelectableList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const ExtraFields = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 32px;
  margin-top: 15px;
  gap: 15px;
`;
