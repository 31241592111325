import { Radio } from "melodies-source/Selectable";
import { Body2 } from "melodies-source/Text";

interface ControlsProps {
  single: boolean;
  onSingleChange?: (single: boolean) => void;
}

export const Controls = ({ single, onSingleChange }: ControlsProps) => {
  return (
    <div>
      <Radio
        value={single}
        label="Manually add a show"
        onChange={() => onSingleChange?.(true)}
      />
      <Radio
        value={!single}
        label="Paste one or multiple shows"
        onChange={() => onSingleChange?.(false)}
      />
      {!single && (
        <Body2 style={{ marginTop: 20, color: "var(--secondary-text-color)" }}>
          This tool will intelligently add one or multiple shows to your SET
          account that have been copied from another source.
          <br /> <br />
          Start by selecting a list of shows from a webpage or document that you
          want to import. Then copy and paste that information into the text
          field.
        </Body2>
      )}
    </div>
  );
};
