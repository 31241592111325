import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";
import {
  SurveyTemplate,
  SurveyTemplateCategoryName,
  SurveyTemplateConfig,
} from "./types";
import { useArtistContext } from "Components";
import { useHistory } from "react-router";
import { getArtistInfo } from "Routes/SetFan/firestore/getArtistInfo";
import { SelectTemplateModal } from "./SelectTemplateModal";
import { useScrollLockContext } from "contexts/ScrollLockContext";
import { SelectCategoryModal } from "./SelectCategoryModal";
import { AiPromptModal } from "./Prompts/AiPromptModal";
import { createSurveyBuilder } from "Routes/SetFan/firestore/createSurveyBuilder";
import { PromptFormState } from "./defaults/types";
import toast from "react-hot-toast";
import { useExceptionLogging } from "hooks/useExceptionLogging";
import { convertTemplateToConfig } from "./templateToConfig";
import { ArtistInfo } from "Routes/SetFan/Components/SongsQuestion/types";
import { SelectPreSaveReleaseModal } from "./SelectPreSaveReleaseModal";
import { SetPageRelease } from "@max/common/src/setpage";
import { api } from "api";
import { PreSaveReleaseTemplateProps } from "./defaults/musicDrop";

export interface HandleCreateProps {
  config?: SurveyTemplateConfig;
  formState?: PromptFormState;
}

interface TemplateContextProps {
  handleCreate: (v?: HandleCreateProps) => Promise<void>;
  setSelectedTemplate: Dispatch<SetStateAction<SurveyTemplate<unknown>>>;
  selectedTemplate?: SurveyTemplate<unknown>;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  templateCategory: SurveyTemplateCategoryName;
  setTemplateCategory: Dispatch<SetStateAction<SurveyTemplateCategoryName>>;
  setModal: Dispatch<SetStateAction<ModalProps>>;
  modal: ModalProps;
  selectedPreSaveRelease: SetPageRelease;
  setSelectedPreSaveRelease: Dispatch<SetStateAction<SetPageRelease>>;
}

interface ModalProps {
  aiPrompt?: boolean;
  selectCategory?: boolean;
  selectTemplate?: boolean;
  selectRelease?: boolean;
}

export const TemplateContext = createContext<TemplateContextProps>(
  {} as TemplateContextProps,
);

interface TemplateProviderProps {
  children?: React.ReactNode;
}

export const TemplateProvider = ({ children }: TemplateProviderProps) => {
  const [selectedPreSaveRelease, setSelectedPreSaveRelease] =
    useState<SetPageRelease>();
  const [selectedTemplate, setSelectedTemplate] = useState<
    SurveyTemplate<unknown> | undefined
  >();
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState<ModalProps>({});
  const [templateCategory, setTemplateCategory] =
    useState<SurveyTemplateCategoryName>();
  const { id: artistGroupId, name: artist } = useArtistContext();
  const { unlockScroll } = useScrollLockContext();
  const history = useHistory();
  const { logException } = useExceptionLogging();

  const handleCreate = async (options: HandleCreateProps) => {
    if (!selectedTemplate?.template) {
      return;
    }
    setIsLoading(true);
    const { config: patch, formState } = options;
    let artistInfo: ArtistInfo;

    if (selectedTemplate.templateId === "dreamSetlist") {
      try {
        const artistAlbums = await getArtistInfo({
          artistGroupId,
          artistName: artist,
        });
        if (artistAlbums) {
          artistInfo = artistAlbums;
        }
      } catch (error) {
        console.error(error);
      }
    }

    const preSaveTemplateProps: PreSaveReleaseTemplateProps = {};
    if (
      selectedTemplate.templateId === "preSaveRelease" &&
      selectedPreSaveRelease
    ) {
      const { data } = await api.call("portal.presave:release", {
        id: selectedPreSaveRelease.id,
      });
      preSaveTemplateProps.preSaveRelease = selectedPreSaveRelease;
      preSaveTemplateProps.releaseDate = data?.data?.releaseDate;
    }

    const template = selectedTemplate.template(
      {
        ...artistInfo,
        ...preSaveTemplateProps,
        artist,
      },
      formState,
    );

    const config = convertTemplateToConfig({
      template,
      patch,
      formState,
    });

    try {
      const { survey } = await createSurveyBuilder({
        ...config,
        artistGroupId,
        artistName: artist,
        template: selectedTemplate.templateId,
      });

      history.push(`surveys/${survey.id}/wizard`);
    } catch (error) {
      console.error(error);
      logException(error, {
        action: "template-create",
        promptId: selectedTemplate.prompt?.promptId(formState),
      });

      toast.error(
        "We encountered an issue when creating your survey. Please try again or contact our support team for help.",
        { duration: 15000 },
      );
    } finally {
      setIsLoading(false);
    }
  };

  const values: TemplateContextProps = {
    selectedTemplate,
    setSelectedTemplate,
    templateCategory,
    setTemplateCategory,
    handleCreate,
    isLoading,
    setIsLoading,
    modal,
    setModal,
    selectedPreSaveRelease,
    setSelectedPreSaveRelease,
  };

  return (
    <TemplateContext.Provider value={values}>
      <SelectCategoryModal
        onClose={() => {
          unlockScroll();
          setModal({ selectCategory: false });
        }}
        onSelect={() => setModal({ selectTemplate: true })}
      />
      <SelectTemplateModal
        onBack={() => {
          setSelectedTemplate(undefined);
          setModal({ selectCategory: true });
        }}
      />
      <SelectPreSaveReleaseModal />
      <AiPromptModal
        onBack={() => {
          if (selectedTemplate?.templateId === "preSaveRelease") {
            setModal({ selectRelease: true });
          } else {
            setModal({ selectTemplate: true });
          }
        }}
      />
      {children}
    </TemplateContext.Provider>
  );
};

export const useTemplates = () => useContext(TemplateContext);
