import { bakersfieldFilmFestival as bakersfieldFilmFestivalConfig } from "@max/thirdparty/companies/bakersfieldFilmFestival";
import { GtbAffiliationMap } from "@max/common/dist/thirdparty/gtb";
import { GtbCustomApp } from "../gtb/types";
import { hearthHarmony } from "../hearthHarmony";

export const bakersfieldFilmFestival: GtbCustomApp = {
  ...bakersfieldFilmFestivalConfig,
  routes: hearthHarmony.routes,
  userRoles: JSON.parse(
    JSON.stringify(GtbAffiliationMap).replaceAll(
      "GTB",
      "Bakersfield Film Festival",
    ),
  ),
};
