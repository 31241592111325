import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { CustomAppProvider } from "contexts/CustomAppContext";
import * as Sentry from "@sentry/react";
import TagManager from "react-gtm-module";
import { script } from "Utils/script";
import setupLogRocketReact from "logrocket-react";
import { auth } from "firebase-internal";

if (process.env.REACT_APP_GTM_ID) {
  TagManager.initialize({
    gtmId: process.env.REACT_APP_GTM_ID,
  });
}

if (process.env.NODE_ENV === "production" && process.env.REACT_APP_SENTRY_DSN) {
  const isPreview = window.location.host.endsWith(".artist-portal.pages.dev");

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: isPreview ? "preview" : process.env.REACT_APP_ENV,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        networkDetailAllowUrls: [/(dev-)?set.(live|rocks)/i],
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // TODO: THis should also send traces to the search.set.live domains but currently
    // those won't work because of CORS issues
    tracePropagationTargets: ["localhost", /^https:\/\/artists\./],
    // Session Replay
    replaysSessionSampleRate: 0,
    // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    replaysOnErrorSampleRate: 1.0,
    release: process.env.REACT_APP_SHA || "unknown",
  });
}

script("https://cdn.lrkt-in.com/LogRocket.min.js", () => {
  if (window.LogRocket && process.env.NODE_ENV === "production") {
    setupLogRocketReact(window.LogRocket);

    window.LogRocket.init("fyqxo2/artist-portal", {
      release: process.env.REACT_APP_SHA || "unknown",
      network: { responseSanitizer: (response) => response },
      mergeIframes: true,
      shouldDebugLog: false,
    });
  }
});

auth.onAuthStateChanged((user) => {
  if (user) {
    Sentry.setUser({
      id: user.uid,
      email: user.email,
    });

    window.LogRocket?.identify(user.uid, {
      name: user.displayName,
      email: user.email,
      phone: user.phoneNumber,
    });
  } else {
    Sentry.setUser(null);
  }
});

ReactDOM.render(
  <React.StrictMode>
    <CustomAppProvider>
      <App />
    </CustomAppProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
