import React from "react";
import { BackLink, TopBarLayout } from "Components";
import styled from "styled-components";
import { H2 } from "melodies-source/Text";
import { Header } from "./Header";

interface Props {
  children: React.ReactNode;
  back?: { path: string; text: string };
  header?: string;
}

export const Layout: React.FC<Props> = ({ children, back, header }) => {
  return (
    <TopBarLayout withFooter>
      <Container>
        {back && <StyledBackLink path={back.path}>{back.text}</StyledBackLink>}
        {header && <Header withBack={!!back}>{header}</Header>}
        {children}
      </Container>
    </TopBarLayout>
  );
};

export * from "./Header";

const Container = styled.div`
  max-width: 1400px;
  margin: 0px auto;
  padding: 30px 40px;
  width: 100%;

  ${({ theme }) => theme.media.mobile} {
    ${H2} {
      font-size: 24px;
      line-height: 30px;
    }
  }
`;

const StyledBackLink = styled(BackLink)`
  color: var(--text-color);
  margin-bottom: 40px;
  width: fit-content;

  svg {
    color: var(--secondary-color);
  }
`;
