import { QuestionModalProps, QuestionTypeMeta } from "./BaseQuestionModal";
import { Button } from "melodies-source/Button";
import * as yup from "yup";
import { REQUIRED_MSG } from "Utils/yupStrictPassword";
import { Field, FieldProps, Form, Formik } from "formik";
import { TextInput } from "Components";
import { useMemo } from "react";
import { TextModule } from "@max/common/dist/setfan";
import { ResponsiveFooterActions } from "Components/ResponsiveModal";

interface Values {
  label: string;
}

const VALIDATION_SCHEMA = yup.object().shape({
  label: yup.string().required(REQUIRED_MSG),
  caption: yup.string(),
});

const AddTextModal = ({
  dispatch,
  existing,
  onClose,
}: Omit<QuestionModalProps<TextModule>, "state">) => {
  const initialValues: Values = useMemo(
    () => ({
      label: existing?.label.en ?? "",
    }),
    [existing],
  );

  const onSubmit = ({ label }: Values) => {
    const textModule = new TextModule({
      id: existing?.id,
      variants: { mobile: "Headline4", desktop: "Headline3" },
      label: { en: label },
      style: { paddingBottom: 20 },
    });

    dispatch(
      existing
        ? {
            type: "updateModule",
            payload: { idToReplace: existing.id, module: textModule },
          }
        : {
            type: "addModule",
            payload: { module: textModule },
          },
    );
    onClose();
  };

  return (
    <Formik<Values>
      initialValues={initialValues}
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={onSubmit}
    >
      <Form>
        <div style={{ marginBottom: 26 }}>
          <Field name="label">
            {({ field, meta }: FieldProps) => (
              <TextInput
                label="Header Text"
                placeholder="Type a title..."
                hasError={meta.touched && !!meta.error}
                helperText={meta.touched && meta.error}
                asString={false}
                {...field}
              />
            )}
          </Field>
        </div>
        <ResponsiveFooterActions>
          <Button type="button" variant="tertiary" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit">{existing ? "Update" : "Add"}</Button>
        </ResponsiveFooterActions>
      </Form>
    </Formik>
  );
};

export const text: QuestionTypeMeta = {
  icon: null,
  key: "text",
  header: "Text",
  subHeader: "",
  body: AddTextModal,
  variant: "small",
};
