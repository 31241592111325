import { useUser } from "auth";
import { Button } from "melodies-source/Button";
import { Modal } from "melodies-source/Modal";
import React from "react";
import styled from "styled-components";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const ExpiredSessionModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const { clearSession } = useUser();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      withCloseIcon={false}
      withBackdropClose={false}
    >
      <Container>
        <p>
          Too much time has expired since your last login. Logout and log back
          in to set your MFA verification method.
        </p>
        <Button onClick={clearSession}>Log Out</Button>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
