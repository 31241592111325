import React, { useState } from "react";
import { SetPageRelease, SetPageReleaseForm } from "@max/common/src/setpage";
import styled, { css } from "styled-components";
import { Body1, Subtitle2 } from "melodies-source/Text";
import { getDateTime } from "Utils/date";
import { Button } from "melodies-source/Button";
//  import { SvgDelete } from "melodies-source/Svgs/Delete";
import { EditPreSaveModal } from "../../../Modals/EditPreSaveModal";
// import { DeletePreSaveModal } from "../../../Modals/DeletePreSaveModal";
import { ReactComponent as CheckIcon } from "assets/svg/check-filled.svg";

interface Props {
  release: SetPageReleaseForm;
  isSelected: boolean;
  onSelect: () => void;
  isTakeover?: boolean;
  onChange?: (release: SetPageRelease) => void;
}

export const UpcomingReleaseCard: React.FC<Props> = ({
  release,
  isTakeover,
  isSelected,
  onSelect,
  onChange,
}) => {
  const [editPreSaveModalIsOpen, setEditPreSaveModalIsOpen] = useState(false);
  // const [deletePreSaveModalIsOpen, setDeletePreSaveModalIsOpen] =
  //   useState(false);

  const toggleEditPreSaveModalOpen = () =>
    setEditPreSaveModalIsOpen((open) => !open);

  // const toggleDeletePreSaveModalOpen = () =>
  //   setDeletePreSaveModalIsOpen((open) => !open);

  return (
    <>
      <Container isSelected={isSelected} onClick={onSelect}>
        {isSelected && <StyledCheckIcon />}
        <Cover src={release.image} alt="cover" />
        <Detail>
          <div>
            <Title>{release.title}</Title>
            <Body1>
              Releases {getDateTime(release.date).toFormat("LLL d, yyyy")}
            </Body1>
          </div>
          <div>
            <Subtitle2>UPC# {release.upc}</Subtitle2>
            {release.isrc && <Subtitle2>ISRC# {release.isrc}</Subtitle2>}
          </div>
        </Detail>
        <Actions>
          {isSelected && (
            <Button onClick={toggleEditPreSaveModalOpen} text>
              Edit
            </Button>
          )}
          {/* <Button onClick={toggleDeletePreSaveModalOpen} text>
            <SvgDelete />
          </Button> */}
        </Actions>
      </Container>
      <EditPreSaveModal
        key={`edit-pre-save-modal-${editPreSaveModalIsOpen}`}
        release={release}
        isOpen={editPreSaveModalIsOpen}
        onClose={toggleEditPreSaveModalOpen}
        isTakeover={isTakeover}
        onChange={onChange}
      />
      {/* <DeletePreSaveModal
        releaseId={release.id}
        isOpen={deletePreSaveModalIsOpen}
        onClose={toggleDeletePreSaveModalOpen}
        isTakeover={isTakeover}
      /> */}
    </>
  );
};

interface ContainerProps {
  isSelected: boolean;
}

const Container = styled.div<ContainerProps>`
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 20px;
  padding: 15px;
  border: 2px solid #d5d5d5;
  border-radius: 12px;
  background: #f2f5f7;
  color: var(--text-color);
  position: relative;
  cursor: pointer;

  ${({ isSelected }) =>
    isSelected &&
    css`
      border-color: var(--main-color);
    `};

  ${({ theme }) => theme.media.mobile} {
    gap: 10px;
  }
`;

const StyledCheckIcon = styled(CheckIcon)`
  position: absolute;
  top: -10px;
  right: -10px;
  color: var(--main-color);
  border: 1px solid white;
  border-radius: 50%;
`;

const Cover = styled.img`
  width: 95px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.5);

  ${({ theme }) => theme.media.mobile} {
    width: 65px;
  }
`;

const Detail = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 2px 0;

  ${Subtitle2} {
    font-size: 12px;
  }

  ${({ theme }) => theme.media.mobile} {
    ${Body1} {
      font-size: 14px;
    }

    ${Subtitle2} {
      font-size: 11px;
    }
  }
`;

const Title = styled.span`
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;

  ${({ theme }) => theme.media.mobile} {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  }
`;

const Actions = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-right: 10px;

  button {
    padding: 5px;
    height: auto;
    color: var(--main-color);
  }

  ${({ theme }) => theme.media.mobile} {
    gap: 5px;
    margin-right: 0;
  }
`;
