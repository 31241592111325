import React, { useEffect, useState } from "react";
import {
  multiFactor,
  TotpMultiFactorGenerator,
  TotpSecret,
  getAuth,
} from "firebase/auth";
import { useCustomAppContext } from "contexts/CustomAppContext";
import { Body1, Subtitle1 } from "melodies-source/Text";
import QRCode from "react-qr-code";
import styled from "styled-components";
import * as yup from "yup";
import { yupRequired } from "Utils/yup";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { TextInput } from "melodies-source/TextInput";
import { Button } from "melodies-source/Button";
import { Step, useSetupMFAContext } from "..";
import { FirebaseError } from "firebase-admin";
import { ExpiredSessionModal } from "../Components/ExpiredSessionModal";
import { useLocation } from "react-router-dom";

const INITIAL_VALUES = { code: "" };

const VALIDATION_SCHEMA = yup.object().shape({
  code: yupRequired.matches(/^\d{6}$/, "Invalid code"),
});

export const TotpMFA: React.FC = () => {
  const [secret, setSecret] = useState<TotpSecret>(null);
  const [uri, setUri] = useState<string>(null);
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    validationSchema: VALIDATION_SCHEMA,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: handleConfirm,
  });
  const { customApp } = useCustomAppContext();
  const { setStep, onClose, onSuccess } = useSetupMFAContext();
  const location = useLocation();
  const [expiredSessionModalIsOpen, setExpiredSessionModalIsOpen] =
    useState(false);

  const toggleExpiredSessionModalOpen = () =>
    setExpiredSessionModalIsOpen((open) => !open);

  async function handleConfirm() {
    try {
      setLoading(true);
      const multiFactorAssertion =
        TotpMultiFactorGenerator.assertionForEnrollment(
          secret,
          formik.values.code,
        );
      await multiFactor(getAuth().currentUser).enroll(
        multiFactorAssertion,
        "TOTP",
      );
      onSuccess();
      toast.success("TOTP set up successfully");
    } catch (err) {
      const error = err as FirebaseError;
      switch (error.code) {
        case "auth/requires-recent-login": {
          if (location.pathname === "/signup") {
            toggleExpiredSessionModalOpen();
          } else {
            toast("Your session is not recent. Log in again to set up MFA");
            onClose();
          }
          break;
        }
        default: {
          console.error(error);
          toast.error("There was an error");
        }
      }
    } finally {
      setLoading(false);
    }
  }

  const getSecret = async () => {
    try {
      const multiFactorSession = await multiFactor(
        getAuth().currentUser,
      ).getSession();
      const totpSecret = await TotpMultiFactorGenerator.generateSecret(
        multiFactorSession,
      );
      const totpUri = totpSecret.generateQrCodeUrl(
        getAuth().currentUser.email,
        customApp?.company.name || "SET",
      );
      setSecret(totpSecret);
      setUri(totpUri);
    } catch (err) {
      const error = err as FirebaseError;
      switch (error.code) {
        case "auth/requires-recent-login": {
          if (location.pathname === "/signup") {
            toggleExpiredSessionModalOpen();
          } else {
            toast("Your session is not recent. Log in again to set up MFA");
            onClose();
          }
          break;
        }
        default: {
          console.error(error);
          toast.error("There was an error");
        }
      }
    }
  };

  useEffect(() => {
    getSecret();
  }, []);

  return (
    <>
      <Body1>Use your authenticator app to scan this QR code:</Body1>
      {uri && <StyledQRCode value={uri} />}
      <Body1>Or enter this code: </Body1>
      <Secret>{secret?.secretKey}</Secret>
      <Body1>
        Now, enter the 6-digit code displayed in your authenticator app.
      </Body1>
      <Form onSubmit={formik.handleSubmit}>
        <TextInput
          value={formik.values.code}
          onChange={formik.handleChange("code")}
          label="Code"
          type="number"
          {...(formik.errors.code && {
            hasError: true,
            helperText: formik.errors.code,
          })}
        />
        <Button type="submit" loading={loading}>
          Confirm
        </Button>
      </Form>
      <Button variant="tertiary" onClick={() => setStep(Step.SelectMFA)}>
        Back
      </Button>
      <ExpiredSessionModal
        isOpen={expiredSessionModalIsOpen}
        onClose={toggleExpiredSessionModalOpen}
      />
    </>
  );
};

const StyledQRCode = styled(QRCode)`
  text-align: center;
  display: block;
  margin: 20px auto;
`;

const Secret = styled(Subtitle1)`
  text-align: center;
  margin: 10px 0;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  width: 100%;
`;
