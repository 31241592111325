import { gtb as gtbConfig } from "@max/thirdparty/companies/gtb";
import { GtbAffiliationMap } from "@max/common/dist/thirdparty/gtb";
import { Routes } from "./Routes";
import { GtbCustomApp } from "./types";

export const gtb: GtbCustomApp = {
  ...gtbConfig,
  routes: {
    paths: ["/", "/regions/:regionId"],
    Component: Routes,
  },
  userRoles: GtbAffiliationMap,
};
