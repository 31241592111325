import { towne as towneConfig } from "@max/thirdparty/companies/towne";
import { GtbAffiliationMap } from "@max/common/dist/thirdparty/gtb";
import { GtbCustomApp } from "../gtb/types";
import { gtb } from "../gtb";

export const towne: GtbCustomApp = {
  ...towneConfig,
  routes: gtb.routes,
  userRoles: JSON.parse(
    JSON.stringify(GtbAffiliationMap).replaceAll("GTB", "Towne"),
  ),
};
