"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.bakersfieldFilmFestival = void 0;
const fizz_1 = require("./fizz");
const hearthHarmony_1 = require("./hearthHarmony");
exports.bakersfieldFilmFestival = Object.assign(Object.assign({}, fizz_1.fizz), { group: hearthHarmony_1.hearthHarmony.group, parentGroup: hearthHarmony_1.hearthHarmony.parentGroup, app: { name: "SPONSORSHIP PROGRAMS" }, company: {
        name: "Bakersfield Film Festival",
        logo: {
            url: "/custom/bakersfield-film-festival/logo.svg",
            description: "tri-city film festival",
        },
        icon: "/custom/bakersfield-film-festival/tags/favicon.ico",
        image: "/custom/bakersfield-film-festival/tags/logo.png",
    }, footerLinks: [
        {
            text: "Bakersfield Film Festival Privacy Policy",
            url: "https://www.max.live/privacy-policy",
        },
        { text: "MAX Privacy Policy", url: "https://www.max.live/privacy-policy" },
    ], fanAppUrl: `https://bakersfieldfilm.live`, onsiteAppUrl: `https://onsite.bakersfieldfilm.live`, privacyPolicyUrl: "https://www.max.live/privacy-policy", regionsCollection: "bakersfield_film_festival_regions", menu: {
        sections: {
            engagement: {
                header: "User Engagement",
                items: {
                    live: {
                        label: "BakersfieldFilmFestival.Live",
                    },
                },
            },
        },
    } });
