"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.towne = exports.colors = void 0;
const gtb_1 = require("./gtb");
exports.colors = {
    main: "#006610",
    secondary: "#00BD1D",
    tertiary: "#006610",
    background: "white",
    text: "#333333",
    headerText: "#343a40",
    secondaryText: "#999999",
    primaryButtonText: "white",
    primaryButtonHover: "#288b39",
    componentBackground: "#FFFFFF",
    border: "#ced4da",
    inputBorder: "#ced4da",
    hoverBackground: "#b2f2bb",
    boxShadow: "rgba(0,0,0,0.1)",
    selected: "#51cf66",
    contentBackground: "#E7F3E6",
    disabled: "#565656",
    spinner: "#2f9e44",
    liveEventCardBackground: "#d3f9d8",
};
exports.towne = Object.assign(Object.assign({}, gtb_1.gtb), { app: { name: "SPONSORSHIP PROGRAMS" }, company: {
        name: "Towne",
        logo: {
            url: "/custom/towne/logo.svg",
            description: "towne",
        },
        icon: "/custom/towne/tags/favicon.ico",
        image: "/custom/towne/tasg/logo.png",
    }, event: {
        owner: {
            singular: "Program",
            plural: "Programs",
            singularWithArticle: "a Program",
        },
        type: {
            singular: "Event",
            plural: "Events",
            singularWithArticle: "an Event",
        },
        audience: {
            singular: "Attendee",
            plural: "Attendees",
            singularWithArticle: "an Attendee",
        },
    }, footerLinks: [
        {
            text: "Towne Privacy Policy",
            url: "https://www.max.live/privacy-policy",
        },
        { text: "MAX Privacy Policy", url: "https://www.max.live/privacy-policy" },
    ], fanAppUrl: `https://towne.live`, onsiteAppUrl: `https://onsite.towne.live`, privacyPolicyUrl: "https://www.max.live/privacy-policy", regionsCollection: "towne_regions", menu: {
        sections: {
            engagement: {
                header: "User Engagement",
                items: {
                    live: {
                        label: "Towne.Live",
                    },
                },
            },
        },
    }, theme: {
        colors: exports.colors,
        layout: {
            menuBar: {
                background: exports.colors.background,
                color: exports.colors.main,
            },
            sideBar: {
                background: exports.colors.componentBackground,
                color: exports.colors.text,
                active: {
                    background: exports.colors.main,
                    color: "white",
                },
                sectionHeader: {
                    color: exports.colors.text,
                },
            },
            content: {
                background: exports.colors.contentBackground,
                backgroundHeader: {
                    background: "linear-gradient(180deg, #2f9e44 20%, #E7F3E6 85%)",
                },
            },
            footer: {
                background: exports.colors.background,
                color: exports.colors.text,
            },
            boxShadowColor: exports.colors.border,
        },
        pages: {
            dashboard: {
                cards: {
                    nextShow: {
                        background: exports.colors.background,
                        color: exports.colors.main,
                    },
                    fanContacts: {
                        background: exports.colors.background,
                        color: exports.colors.main,
                    },
                    fanProfile: {
                        background: "gray",
                        color: "white",
                    },
                    shows: {
                        background: "linear-gradient(180deg, #00D120 -20%, #007913 110%)",
                    },
                },
            },
            contacts: {
                table: {
                    row: {
                        hover: {
                            background: "#69db7c",
                        },
                        border: { color: "#adb5bd" },
                    },
                },
                noContacts: {
                    background: exports.colors.componentBackground,
                    icon: {
                        color: exports.colors.main,
                    },
                },
            },
            reporting: {
                setlive: {
                    fanReportCard: {
                        background: exports.colors.contentBackground,
                    },
                },
            },
            account: {
                header: {
                    background: `linear-gradient(180deg, ${exports.colors.main} 0%, ${exports.colors.secondary} 100%), #20222A`,
                },
            },
            signup: {
                content: {
                    background: "linear-gradient(155deg, #046f91, #080a12 55%)",
                },
            },
            region: {
                cards: {
                    contactsTotal: {
                        background: "linear-gradient(180deg, #00D120 -20%, #007913 110%)",
                        color: "white",
                    },
                    contactsLast30Days: {
                        background: "linear-gradient(180deg, #00D120 -20%, #007913 110%)",
                        color: "white",
                    },
                },
            },
        },
        charts: {
            bar: {
                bar: { background: "#d8d8d8" },
            },
            donut: {
                colors: [
                    exports.colors.main,
                    exports.colors.secondary,
                    "#4d9458",
                    "#4dd161",
                    "#00470b",
                    "#008414",
                ],
            },
        },
        icon: {
            background: exports.colors.contentBackground,
        },
    } });
