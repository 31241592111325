import React from "react";

interface Props {
  fill?: string;
}

const RivetLogo: React.FC<Props> = (props) => {
  const fill = props.fill || "black";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -5 180 60"
      height={40}
      fill={fill}
    >
    <path
      fill="white"
      d="M21.336 31.918a5.492 5.492 0 0 0 2.855 1.725c3.882.839 6.275-3.855 11.518-3.659 1.3-.008 2.57.375 3.634 1.096 2.19 1.586 3.099 4.937 1.948 7.633-1.378 3.226-5.058 3.98-5.75 4.105-3.991.717-7.206-1.785-8.85-3.067-2.554-2.035-4.42-4.763-5.355-7.833ZM39.728 2.598C35.922-.446 30.568-.513 27.262.853c-6.74 2.78-10.437 13.815-6.79 26.896 13.513-1.38 22.918-8.117 23.565-15.219.28-3.06-.969-7.274-4.309-9.932ZM23.68 22.889c-1.122-9.23 1.508-16.446 5.947-17.904 1.626-.537 4.526-.632 6.698.995 2.172 1.626 2.717 4.26 2.679 5.749-.136 4.613-6.264 9.246-15.324 11.16ZM15.642 28.71C13.904 21.846 9.83 17.315 5.89 17.315c-1.483 0-3.791.642-5.026 2.468-1.357 2-.892 4.57-.126 5.983 1.83 3.385 7.884 4.799 14.904 2.942ZM16.79 33.17c-7.168 1.353-12.057 5.073-12.243 8.884-.07 1.434.493 3.686 2.326 4.971 2.011 1.404 4.69 1.066 6.188.39 3.578-1.597 5.327-7.376 3.728-14.245Z"
    />
    <g fill="white" clipPath="url(#a)">
      <path d="M72.807 11.916c7.63 0 11.445 3.916 11.445 8.742 0 3.48-2.13 6.764-6.92 8.03l7.186 10.996h-8.566l-6.477-10.482h-1.819v10.482h-7.585V11.916h12.736Zm-.444 5.617h-4.702v6.878h4.702c2.84 0 4.17-1.305 4.17-3.48 0-2.057-1.33-3.398-4.17-3.398ZM87.535 11.679C87.535 9.622 89.354 8 92.06 8c2.706 0 4.48 1.622 4.48 3.679 0 2.056-1.819 3.639-4.48 3.639-2.662 0-4.525-1.622-4.525-3.64Zm.71 5.933h7.585v22.072h-7.586V17.612ZM106.344 17.612l5.367 15.743 5.368-15.743h8.074l-8.784 22.072h-9.364l-8.784-22.072h8.123ZM138.594 40c-7.275 0-12.51-4.351-12.51-11.352 0-7.002 5.146-11.353 12.51-11.353 7.231 0 12.332 4.272 12.332 10.997a15.226 15.226 0 0 1-.133 1.977h-17.172c.266 2.888 2.262 4.233 4.702 4.233 2.129 0 3.327-.95 3.948-2.136h8.074C149.147 36.673 144.76 40 138.594 40Zm-4.924-13.488h9.493c0-2.413-2.129-3.797-4.658-3.797-2.529 0-4.392 1.344-4.835 3.797ZM155.358 23.225h-3.017v-5.617h3.017v-5.38h7.586v5.38h4.968v5.617h-4.968v8.86c0 1.305.621 1.86 2.306 1.86h2.706v5.735h-3.859c-5.146 0-8.739-1.939-8.739-7.674v-8.781Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="white" d="M60.075 8h107.886v32H60.075z" />
      </clipPath>
    </defs>
  </svg>
  );
};

export default RivetLogo;
