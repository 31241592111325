import { rogueValleyStrawfest as rogueValleyStrawfestConfig } from "@max/thirdparty/companies/rogueValleyStrawfest";
import { GtbAffiliationMap } from "@max/common/dist/thirdparty/gtb";
import { GtbCustomApp } from "../gtb/types";
import { hearthHarmony } from "../hearthHarmony";

export const rogueValleyStrawfest: GtbCustomApp = {
  ...rogueValleyStrawfestConfig,
  routes: hearthHarmony.routes,
  userRoles: JSON.parse(
    JSON.stringify(GtbAffiliationMap).replaceAll(
      "GTB",
      "Rogue Valley Strawfest",
    ),
  ),
};
