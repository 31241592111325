import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import {
  SurveyBuilderFields,
  SurveyBuilderConfig,
  SurveyBuilderVersionDocument,
  SurveyBuilderRootDocument,
  SurveyBuilderFieldsContentOnly,
  SurveyStatus,
  SurveyBuilderFlags,
  SurveyTranslatableContent,
} from "@max/common/dist/setfan";
import {
  collection,
  doc,
  DocumentSnapshot,
  getFirestore,
  query,
  setDoc,
  updateDoc,
  where,
  Timestamp,
} from "firebase/firestore";
import { buildSurveyBuilderConfig } from "./buildBaseConfig";
import { getFunctions } from "firebase/functions";
import { addToast } from "melodies-source/Toast";

import { getPublishFunction } from "@max/common/dist/functions/setfan/publish";
import { validateConfig } from "./builderConfigSchema";
import { useCollection, useDocumentData } from "react-firebase-hooks/firestore";
import { Button, useArtistContext } from "Components";
import { Modal } from "melodies-source/Modal";
import { Radio } from "melodies-source/Selectable";
import styled from "styled-components";
import { Body1 } from "melodies-source/Text";
import { useHistory } from "react-router-dom";
import { useScrollLockContext } from "contexts/ScrollLockContext";
import { addHttpsProtocolToUrl } from "Utils/url";
import { cloneDeep } from "lodash";

const publish = getPublishFunction(getFunctions());

export type Fields = keyof SurveyBuilderFields;
export const typedFields = <T extends Fields>(fields: T[]) => fields;
type PublishingStatus = "idle" | "draft" | "live";

interface BuilderI {
  data: SurveyBuilderConfig;
  setData: Dispatch<SetStateAction<SurveyBuilderConfig>>;
  onPublish: (args: { status: "draft" | "live" }) => Promise<boolean>;
  publishingStatus: PublishingStatus;
  status: Extract<SurveyStatus, "live" | "draft" | "completed" | "deleted">;
}

const BuilderContext = createContext<BuilderI>({} as BuilderI);

export const useBuilderContext = () => useContext(BuilderContext);

interface BuilderProviderProps {
  survey: DocumentSnapshot<SurveyBuilderRootDocument>;
  version: DocumentSnapshot<SurveyBuilderVersionDocument>;
}

export const BuilderProvider = ({
  survey,
  version,
  children,
}: PropsWithChildren<BuilderProviderProps>) => {
  const [data, setData] = useState(
    buildSurveyBuilderConfig(survey.data(), version.data()),
  );
  const history = useHistory();
  const { lockScroll, unlockScroll } = useScrollLockContext();

  const [subdomain, setSubdomain] = useState<boolean | string>(false);
  const { id } = useArtistContext();
  const [subdomains] = useCollection(
    query(
      collection(getFirestore(), `artist_subdomain`),
      where("artistGroupId", "==", id),
    ),
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      const dirtyFields = getDirtyFields(data.fields);
      const dirtyKeys = Object.keys(dirtyFields) as Fields[];

      setDoc(
        version.ref,
        {
          surveyQuestions: data.surveyQuestions,
          flags: data.flags,
          ...(dirtyKeys.length ? { fields: dirtyFields } : {}),
        },
        { merge: true },
      );

      if (dirtyKeys.length) {
        setData((prevData) => {
          const nextData = cloneDeep(prevData);
          dirtyKeys.forEach((k) => (nextData.fields[k].isDirty = false));
          return nextData;
        });
      }
    }, 3000);

    return () => clearTimeout(timeout);
  }, [data, version]);

  const saveToBuilderDoc = async () => {
    const fields = Object.keys(data.fields).reduce((prev, c: Fields) => {
      const field = data.fields[c];

      // append the https: protocol to url if it does not have it (we do not require it when validing form field)
      if (c === "featuredCtaUrl") {
        const urlWithHttps = addHttpsProtocolToUrl(
          (field.content as SurveyTranslatableContent).en,
        );
        return {
          ...prev,
          [c]: { content: { en: urlWithHttps } },
        };
      }

      return { ...prev, [c]: { content: field.content } };
    }, {} as SurveyBuilderFieldsContentOnly);

    await setDoc(version.ref, { ...data, fields }, { merge: true });
  };

  const [publishingStatus, setPublishingStatus] =
    useState<PublishingStatus>("idle");

  const onPublish = async ({
    status,
  }: {
    status: "draft" | "live";
  }): Promise<boolean> => {
    try {
      if (status === "live") {
        const validToPublish = isPublishValid(data);
        if (!validToPublish) return;
      }

      if (subdomains?.docs[0]?.exists && status === "live") {
        lockScroll();
        setSubdomain(subdomains.docs[0].id);
      } else {
        const success = await callCloudPublish({ status });
        if (success) {
          onPublishSuccess({ status });
        }
      }
    } catch (err) {
      console.error(`there was an issue publishing: ${(err as Error).message}`);
      addToast(
        `Unable to ${
          status === "draft" ? "save survey as draft" : "publish survey"
        }. Your changes in this editor are saved, but the draft or published survey is not up to date.`,
        "error",
        { duration: 7000 },
      );
    }
  };

  const onPublishSubdomain = async () => {
    const success = await callCloudPublish({ status: "live" });
    if (success) {
      await updateDoc(subdomains.docs[0].ref, { surveyId: survey.id });
      setSubdomain(false);
      onPublishSuccess({ status: "live" });
    }
  };

  const onPublishSuccess = ({ status }: { status: SurveyStatus }) => {
    history.push(`/${id}/set-fan/surveys`);
    addToast(
      status === "draft"
        ? "Survey draft successfully saved"
        : "Survey successfully published",
      "success",
    );
  };

  const callCloudPublish = async ({ status }: { status: "draft" | "live" }) => {
    setPublishingStatus(status);
    try {
      await saveToBuilderDoc();
      const {
        data: { success },
      } = await publish({ surveyId: survey.id, status });

      if (!success) {
        throw new Error("the call to get publish failed");
      }

      return true;
    } catch (err) {
      console.error(`there was an issue publishing: ${(err as Error).message}`);
      addToast(
        `Unable to ${
          status === "draft" ? "save survey as draft" : "publish survey"
        }. Your changes in this editor are saved, but the draft or published survey is not up to date.`,
        "error",
        { duration: 7000 },
      );
      return false;
    } finally {
      setPublishingStatus("idle");
    }
  };

  return (
    <BuilderContext.Provider
      value={{
        data,
        setData,
        onPublish,
        publishingStatus,
        status: survey.data().status,
      }}
    >
      {children}
      <PublishModal
        handlePublish={async () => {
          const success = await callCloudPublish({ status: "live" });
          if (success) {
            onPublishSuccess({ status: "live" });
          }
        }}
        handlePublishSubdomain={onPublishSubdomain}
        subdomain={subdomain}
        current={subdomains?.docs[0]?.data()?.surveyId}
        onClose={() => {
          unlockScroll();
          setSubdomain(false);
        }}
      />
    </BuilderContext.Provider>
  );
};

const PublishModal = ({
  subdomain,
  handlePublish,
  handlePublishSubdomain,
  onClose,
  current,
}) => {
  const [value, setValue] = useState(false);
  const [currentSurvey, l, e] = useDocumentData(
    doc(getFirestore(), `sts3_surveys/${current}`),
  );
  const [loading, setLoading] = useState(false);

  return (
    <Modal
      isOpen={!!subdomain}
      onClose={onClose}
      header="How do you want to link to this survey?"
    >
      <Radio
        label="Use the unique link generated by SET"
        value={!value}
        onChange={() => setValue(false)}
      />
      <Radio
        label={`Use ${subdomain}.set.fan to point to this survey`}
        value={value}
        onChange={() => setValue(true)}
      />
      {currentSurvey ? (
        <>
          <Body1 style={{ margin: "12px 0 24px 0" }}>
            {subdomain}.set.fan is currently pointing to your{" "}
            <b>{currentSurvey?.title}</b> survey.
          </Body1>
          <Body1>
            Choosing this option will point {subdomain}.set.fan to this survey
            instead. The previous survey will still be accessible via its unique
            link.
          </Body1>
        </>
      ) : (
        <Body1>
          {subdomain}.set.fan is not currently pointing to any survey.
        </Body1>
      )}
      <ButtonContainer>
        <Button onClick={onClose} variant="tertiary">
          Cancel
        </Button>
        <Button
          loading={loading}
          onClick={async () => {
            setLoading(true);
            await (value ? handlePublishSubdomain() : handlePublish());
            setLoading(false);
          }}
        >
          Publish
        </Button>
      </ButtonContainer>
    </Modal>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
`;

const isPublishValid = (config: SurveyBuilderConfig): boolean => {
  const errors = validateConfig(config);

  if (errors) {
    addToast(
      <span style={{ display: "block", maxWidth: 400 }}>
        Survey is incomplete: {errors.join(", ")}
      </span>,
      "error",
    );
    return false;
  }

  const actionRequired = config.surveyQuestions.some((sq) => sq.actionRequired);
  if (actionRequired) {
    addToast("One or more of Survey Questions requires completion", "error");
    return false;
  }

  return true;
};

const getDirtyFields = (fields: SurveyBuilderFields) => {
  return Object.keys(fields).reduce((prev, key: Fields) => {
    const field = fields[key];

    return field.isDirty
      ? { ...prev, [key]: { content: field.content } }
      : prev;
  }, {} as SurveyBuilderFieldsContentOnly);
};

export const getBuilderFields = <T extends Fields>(
  config: SurveyBuilderConfig,
  fields: T[],
) => {
  return fields.reduce(
    (p, c) => ({ ...p, [c]: config.fields[c] }),
    {} as Pick<SurveyBuilderConfig["fields"], T>,
  );
};

export const validate = <T extends Fields>(
  config: SurveyBuilderConfig,
  fields: T[],
) => {
  const validation = fields.reduce(
    (p, c) => ({
      ...p,
      [c]: config.fields[c].getValidation(config),
    }),
    {} as Record<T, string | false>,
  );
  const isValid = !Object.values(validation).find((f) => !!f);
  return { validation, isValid };
};

export const useConfigSlice = <T extends Fields>(fields: T[]) => {
  const { data, setData } = useBuilderContext();
  const filtered = getBuilderFields(data, fields);
  const { validation, isValid } = validate(data, fields);

  const setField = useCallback(
    <F extends T>(
      fieldName: F,
      content: SurveyBuilderConfig["fields"][F]["content"],
    ) => {
      setData((prevData) => {
        const nextData = cloneDeep(prevData);
        const field = nextData.fields[fieldName];

        if (
          typeof field.content === "object" &&
          !Array.isArray(field.content) &&
          typeof content === "object" &&
          !Array.isArray(content) &&
          !(field.content instanceof Timestamp) &&
          !(content instanceof Timestamp)
        ) {
          field.content = { ...field.content, ...content };
        } else {
          field.content = content;
        }

        field.isDirty = true;
        return nextData;
      });
    },
    [setData],
  );

  const setFieldTouched = useCallback(
    <F extends T>(fieldName: F) => {
      setData((prevData) => {
        const nextData = cloneDeep(prevData);
        nextData.fields[fieldName].isTouched = true;
        return nextData;
      });
    },
    [setData],
  );

  const setFieldsTouched = () => {
    let ndata = { ...data };
    fields.forEach((fieldName) => (ndata.fields[fieldName].isTouched = true));
    setData(ndata);
  };

  return {
    fields: filtered,
    validation,
    setField,
    setFieldTouched,
    setFieldsTouched,
    isValid,
  };
};

export const useConfigFlags = () => {
  const { data, setData } = useBuilderContext();

  const setFlagValue = useCallback(
    <T extends keyof SurveyBuilderFlags>(flag: T, value: boolean) => {
      setData((prev) => {
        return {
          ...prev,
          flags: {
            ...prev.flags,
            [flag]: value,
          },
        };
      });
    },
    [],
  );

  return {
    flags: data.flags,
    setFlagValue,
  };
};
