import { Button, Divider } from "Components";
import { ErrorMessageWarning } from "Components/ErrorMessageWarning";
import { yupEmail, yupRequired } from "Utils/yupStrictPassword";
import {
  getAuth,
  getMultiFactorResolver,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { Form, Formik } from "formik";
import { useHistoryPush } from "hooks";
import { SvgKey } from "melodies-source/Svgs/Key";
import { SvgMail } from "melodies-source/Svgs/Mail";
import { useState } from "react";
import styled from "styled-components";
import * as yup from "yup";
import { Description, Link } from "../Layout";
import { SESSION_PATHS } from "../paths";
import { useCustomAppContext } from "contexts/CustomAppContext";
import { TextInput } from "melodies-source/TextInput";
import Logo from "svg/Logo";
import { useUser } from "auth";

const Header = styled.div`
  margin-bottom: 40px;

  ${({ theme }) => theme.mediaQueries.mobile} {
    margin-bottom: 32px;
  }
`;

const Body = styled.div`
  gap: 24px;

  ${({ theme }) => theme.mediaQueries.mobile} {
    gap: 22px;
  }
`;

const Actions = styled.div`
  gap: 20px;
  ${({ theme }) => theme.custom && "margin-bottom: 5px;"}
`;

const Text = styled.p`
  font-size: 16px;
  color: var(--text-color);
`;

interface Values {
  email: string;
  password: string;
}

const validationsSchema = yup.object().shape({
  email: yupEmail,
  password: yupRequired,
});

export const LoginWithPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isInvalidCredential, setIsInvalidCredential] = useState(false);
  const historyPush = useHistoryPush();
  const { setMfaResolver, setLoginMethod } = useUser();
  const { customApp } = useCustomAppContext();

  const onSubmitForm = async (values: Values) => {
    try {
      const { email, password } = values;
      setIsLoading(true);
      setIsInvalidCredential(false);
      await signInWithEmailAndPassword(getAuth(), email, password);
      setLoginMethod("password");
    } catch (error) {
      if (error.code === "auth/multi-factor-auth-required") {
        const resolver = getMultiFactorResolver(getAuth(), error);
        setMfaResolver(resolver);
        setLoginMethod("password");
        historyPush(SESSION_PATHS.LOGIN_WITH_MFA);
      } else if (error.code === "auth/wrong-password") {
        setIsInvalidCredential(true);
      } else if (error.message?.includes("mfa-required")) {
        historyPush(SESSION_PATHS.MFA_REQUIRED, { email: values.email });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Header
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <StyledLogo />
        <Description style={{ textAlign: "center" }}>
          Log in to access your{" "}
          {customApp?.event.owner.singular.toLowerCase() || "artist"} account
        </Description>
      </Header>
      <Formik<Values>
        initialValues={{ email: "", password: "" }}
        validationSchema={validationsSchema}
        validateOnChange={false}
        onSubmit={onSubmitForm}
      >
        {({ values, errors, handleChange }) => {
          return (
            <Form style={{ width: "100%" }}>
              <Body
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {isInvalidCredential && (
                  <ErrorMessageWarning>
                    You entered an incorrect email, password, or both.
                  </ErrorMessageWarning>
                )}
                <TextInput
                  label="Email Address"
                  leftIcon={<SvgMail />}
                  name="email"
                  placeholder="Email..."
                  value={values.email}
                  onChange={handleChange("email")}
                  hasError={!!errors.email}
                  helperText={errors.email}
                />
                <div>
                  <TextInput
                    label="Password"
                    leftIcon={<SvgKey />}
                    type="password"
                    name="password"
                    placeholder="Password..."
                    value={values.password}
                    onChange={handleChange("password")}
                    hasError={!!errors.password}
                    helperText={errors.password}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      paddingTop: 7,
                    }}
                  >
                    <Link
                      onClick={() => historyPush(SESSION_PATHS.FORGOT_PASSWORD)}
                      size={14}
                    >
                      Forgot Password?
                    </Link>
                  </div>
                </div>
                <Button type="submit" disabled={isLoading} loading={isLoading}>
                  Log In
                </Button>
              </Body>
              <Actions
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 24,
                }}
              >
                <Link
                  style={{ textAlign: "center" }}
                  onClick={() => historyPush(SESSION_PATHS.LOGIN_WITH_SSO)}
                >
                  Use a One-Time Login Link
                </Link>
                {!customApp && (
                  <>
                    <Divider />
                    <Text style={{ textAlign: "center" }}>
                      Don’t have an account?{" "}
                      <Link as="a" href="/signup">
                        Get Started
                      </Link>
                    </Text>
                  </>
                )}
              </Actions>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

const StyledLogo = styled(Logo)`
  height: 65px;
  margin-bottom: 15px;
`;
