import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  MultiFactorResolver,
  ParsedToken,
  getAuth,
  isSignInWithEmailLink,
  signInAnonymously,
  signOut,
  updatePassword,
  updateProfile,
} from "firebase/auth";
import {
  Authentication,
  CreateAuthenticationProps,
  LoadUserClaims,
  LoginMethod,
  UserClaims,
  UserContextValue,
  UserProviderProps,
} from "./types";
import {
  DocumentReference,
  doc,
  getFirestore,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { getApp } from "firebase/app";
import { useAuthState } from "react-firebase-hooks/auth";
import * as session from "./session";
import { getUrl } from "Utils/getUrl";
import { AppLoading } from "melodies-source/AppLoading";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import useUserIdToken from "hooks/useUserIdToken";
import { useDocumentData } from "react-firebase-hooks/firestore";
import type { Profile } from "@max/common/user";

const renewalErrorCodes = [
  "auth/expired-action-code",
  "auth/invalid-action-code",
];

function CreateAuthentication({
  firebaseApp,
  firebaseAuthDomain,
  loadClaims,
  fallbackToAnon,
}: CreateAuthenticationProps = {}): Authentication {
  const auth = getAuth(firebaseApp);
  const firestore = firebaseApp ? getFirestore(firebaseApp) : getFirestore();

  if (!firebaseAuthDomain) {
    firebaseAuthDomain = (firebaseApp || getApp())?.options.authDomain;
  }

  if (!firebaseAuthDomain) {
    console.error(
      "Could not determine firebaseAuthDomain.  Auth will likely fail.",
    );
  }

  const UserContext = React.createContext<UserContextValue | undefined>(
    undefined,
  );

  const UserProvider: React.FC<React.PropsWithChildren<UserProviderProps>> = ({
    children,
    blockRendering = true,
  }) => {
    const [user, userLoading] = useAuthState(getAuth());
    const [profile, profileLoading] = useDocumentData(
      doc(
        getFirestore(),
        `profiles/${user?.uid}`,
      ) as DocumentReference<Profile>,
    );
    const [claims, setClaims] = useState<UserClaims>({ admin: false });
    const [verifyingEmailLink, setVerifyingEmailLink] = useState(true);
    const [mfaResolver, setMfaResolver] =
      useState<Nullable<MultiFactorResolver>>(null);
    const loginMethodKey = "login_method";
    const loginMethod = useRef<Nullable<LoginMethod>>(
      (localStorage.getItem(loginMethodKey) as LoginMethod) || null,
    ).current;
    const accessToken = useUserIdToken();
    const history = useHistory();

    const updateName = async (firstName: string, lastName: string) => {
      const displayName = `${firstName} ${lastName}`;
      const userProfile = {
        fullName: displayName,
        name: {
          firstName,
          lastName,
        },
        email: user?.email,
      };

      if (user) {
        await setDoc(doc(firestore, `profiles/${user?.uid}`), userProfile, {
          merge: true,
        });
        await updateProfile(user, { displayName });
      }
    };

    const updatePhotoURL = async (photoURL: string) => {
      if (user) {
        await updateDoc(doc(firestore, `profiles/${user?.uid}`), {
          photoURL,
        });
        await updateProfile(user, { displayName: user.displayName, photoURL });
      }
    };

    const doUpdatePassword = async (password: string): Promise<boolean> => {
      if (user) {
        return await updatePassword(user, password)
          .then(async () => {
            // Updating the password appears to invalidate existing tokens, but
            // our current session is still valid.  So, we use `getIdToken` to
            // force a new ID token, then we clear out our old cookies and re-init
            // our session
            const token = await user.getIdToken(true);

            await session.removeCookie();
            await session.init(token);

            return true;
          })
          .catch((error) => {
            console.error("Error updating password", error);
            return false;
          });
      }
      return false;
    };

    const redirectToLogin = (redirect?: string) => {
      const path =
        typeof redirect === "string" ? redirect : window.location.pathname;
      const loginUrl = getUrl({
        domain: firebaseAuthDomain,
        params: { redirect: getUrl({ path }) },
      });
      window.location.href = loginUrl;
    };

    const clearSession = async () => {
      await session.removeCookie();

      await signOut(auth).catch((error) =>
        console.error("Error signing out", error),
      );
    };

    const doSignOut = async (redirect?: string | boolean) => {
      const path = typeof redirect === "string" ? redirect : "/";

      await clearSession();

      if (firebaseAuthDomain !== window.location.origin && redirect !== false) {
        const signOutUrl = getUrl({
          domain: firebaseAuthDomain,
          params: {
            signOut: "true",
            redirect: getUrl({ path }),
          },
        });
        window.location.href = signOutUrl;
      }
    };

    const defaultLoadClaims: LoadUserClaims = (data) => ({
      admin: typeof data?.admin === "boolean" && data?.admin === true,
    });

    const setLoginMethod = (method: LoginMethod) => {
      localStorage.setItem(loginMethodKey, method);
    };

    const removeLoginMethod = () => {
      localStorage.removeItem(loginMethodKey);
    };

    const loadUserClaims = useMemo(
      () =>
        loadClaims
          ? loadClaims
          : (data: ParsedToken) => defaultLoadClaims(data),
      [],
    );

    const verifyEmailLink = useCallback(async () => {
      const { search, href } = window.location;
      const parsed = new URLSearchParams(search);
      const params = {
        email: parsed.get("email") || "",
        to: parsed.get("to"),
      };

      const isMagicLink = isSignInWithEmailLink(auth, href);

      // Try to sign-in the user
      const user = await session.resume(
        {
          href,
          firebaseError: (error) => {
            if (renewalErrorCodes.includes(error.code) && isMagicLink) {
              toast.error("Expired/Invalid link");
            } else {
              toast.error("There was an error");
            }
          },
        },
        history,
        setMfaResolver,
        setLoginMethod,
        firebaseApp,
      );

      if (user) {
        // Automatic sign-in successful -- bail and wait for next call
        // to set the state
        console.debug("Call to session.resume succeeded");
        return;
      }

      //if (isMagicLink && stopOnFailedSignInWithEmailLink === true) {
      //  // Don't go any further
      //  console.debug(
      //    "Magic link sign-in failed.  Stopping because stopOnFailedSignInWithEmailLink is true",
      //  );
      //}

      if (fallbackToAnon === true) {
        console.debug("No user, logging in anonymously");
        return await signInAnonymously(auth);
      } else {
        // Automatic sign-in failed.  Redirect to login page?
        console.debug("Automatic sign-in failed");
      }

      if (user) {
        if (isMagicLink && params.email && user.email !== params.email) {
          // User account mismatch
          console.debug("User account mismatch; clearSession");
          await clearSession();
          return;
        }

        if (isMagicLink) {
          console.debug(`Calling session.resume: ${href}`);
          await session.resume(
            { href },
            history,
            setMfaResolver,
            setLoginMethod,
            firebaseApp,
          );
        }

        // Set/update the session cookie
        console.debug("Calling session.init", user);
        await session.init(user);
      }

      if (
        user &&
        !user?.email &&
        !user.isAnonymous &&
        user.providerData.length === 0
      ) {
        // This looks pretty hacky, so here's why we do this.  The above call
        // to `session.resume` hits a backend function that checks the user's
        // session cookie and if valid returns a sign-in token.  The act of
        // generating this token will "upgrade" an anonymous account to a
        // "custom" account, however this "custom" account does not look
        // any different than an anonymous account.  So, if the account here
        // looks like one of these "custom" account, we force it into looking
        // like an anonymous account.
        console.debug("Forcing isAnonymous to true");
        Object.defineProperty(user, "isAnonymous", { value: true });
      }
    }, [history]);

    const loading = userLoading || profileLoading || verifyingEmailLink;

    const value = {
      user,
      profile,
      loading,
      claims,
      isLoggedIn: !!user,
      redirectToLogin,
      updateName,
      updatePhotoURL,
      signOut: doSignOut,
      clearSession,
      updatePassword: doUpdatePassword,
      mfaResolver,
      setMfaResolver,
      loginMethod,
      setLoginMethod,
      removeLoginMethod,
    };

    useEffect(() => {
      if (accessToken) Cookies.set("token", accessToken);
    }, [accessToken]);

    useEffect(() => {
      if (userLoading) {
        return;
      }

      const isMagicLink = isSignInWithEmailLink(
        getAuth(),
        window.location.href,
      );

      if (isMagicLink) {
        verifyEmailLink().finally(() => setVerifyingEmailLink(false));
      } else {
        setVerifyingEmailLink(false);
      }
    }, [userLoading, verifyEmailLink]);

    useEffect(() => {
      if (user) {
        user
          .getIdTokenResult()
          .then((res) => setClaims(loadUserClaims(res.claims)));
      }
    }, [loadUserClaims, user]);

    return (
      <UserContext.Provider value={value}>
        {blockRendering && loading ? <AppLoading /> : children}
      </UserContext.Provider>
    );
  };

  const useUser = () => {
    const context = useContext(UserContext);

    if (context === undefined) {
      throw new Error("useUser must be used within a UserProvider");
    }

    return context;
  };

  return { useUser, UserContext, UserProvider };
}

export const { useUser, UserContext, UserProvider } = CreateAuthentication({
  firebaseAuthDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
});
