import { ImageBuilderConfigModule, TextBuilderConfigModule } from "@max/common";
import { SetPageRelease } from "@max/common/setpage";
import { ReactComponent as AddToLibraryIcon } from "assets/svg/add-to-library.svg";
import { DateTime } from "luxon";
import {
  buildPreSaveContestMessage,
  buildPreSaveContestThankYouMessage,
} from "Routes/SetFan/util";
import { SurveyTemplate } from "../../types";

export type PreSaveReleaseTemplateProps = {
  preSaveRelease?: SetPageRelease;
  releaseDate?: string;
};

export const preSaveRelease: SurveyTemplate<PreSaveReleaseTemplateProps> = {
  icon: <AddToLibraryIcon />,
  title: "Pre-Save a Release",
  description:
    "Give fans the chance to pre-save an upcoming single, album, or EP.",
  templateId: "preSaveRelease",
  template: (props, _formState) => {
    const dateTime = props.releaseDate
      ? DateTime.fromISO(props.releaseDate)
      : null;

    return {
      fields: {
        description: {
          content: {
            en: props.preSaveRelease
              ? `Pre-Save ${props.preSaveRelease.title}`
              : "Pre-Save Release",
          },
        },
        numberOfCategories: {
          content: "1",
        },
        hasPreSave: {
          content: true,
        },
        preSaveRelease: {
          content: props.preSaveRelease ?? null,
        },
        preSaveMessage: {
          content: {
            en: dateTime
              ? `Available on ${dateTime.toFormat("LLLL d")}.`
              : "Pre-Save Release",
          },
        },
        preSaveThankYouMessage: {
          content: {
            en: "Thanks for pre-saving!",
          },
        },
        preSaveContestMessage: {
          content: {
            en: buildPreSaveContestMessage(1),
          },
        },
        preSaveContestThankYouMessage: {
          content: {
            en: buildPreSaveContestThankYouMessage(1),
          },
        },
      },
      surveyQuestions: [
        {
          id: "image_1",
          type: "image",
          label: { en: "" },
          src: { en: props.preSaveRelease?.image ?? "" },
          style: { paddingBottom: 20 },
        } as ImageBuilderConfigModule,
        {
          id: "text_1",
          type: "text",
          label: { en: "Pre-Save my latest release!" },
          variants: { mobile: "Headline4", desktop: "Headline3" },
          style: { paddingBottom: 20 },
        } as TextBuilderConfigModule,
      ],
    };
  },
  prompt: {
    promptId: (state) =>
      state.hasSweeps === "Yes" ? "music-presave-sweeps" : "music-presave",
  },
};
