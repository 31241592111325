import { ModalHeaderContainer } from "melodies-source/Modal";
import { Body1, H2 } from "melodies-source/Text";
import { LoadingState } from "./LoadingState";
import styled from "styled-components";
import { Form } from "./Form";
import { useState } from "react";
import { Button } from "melodies-source/Button";
import { useTemplates } from "../TemplateContext";
import { PromptResponse, usePrompt } from "./usePrompt";
import { useArtistContext } from "Components";
import { PromptFormState } from "../defaults/types";
import {
  ResponsiveModal,
  ResponsiveFooterActions,
} from "Components/ResponsiveModal";
import { useExceptionLogging } from "hooks/useExceptionLogging";
import { AiPrompt } from "../types";
import toast from "react-hot-toast";
import { SetPageRelease } from "@max/common/src/setpage";

interface PromptModalProps {
  onBack: VoidFunction;
}

const defaultSweepsFields: AiPrompt["fields"] = [
  {
    name: "hasSweeps",
    type: "radio",
    label: "Will you be offering a sweepstakes prize?",
    options: ["Yes", "No"],
    getValidation: (fields) =>
      !!fields.hasSweeps ? false : "You must select an option.",
  },
  {
    name: "prize",
    type: "text",
    label: "What's the prize?",
    placeholder: "Enter your answer...",
    getValidation: (fields) =>
      fields.prize || fields.hasSweeps === "No"
        ? false
        : "This field cannot be left blank",
    isVisible: (form) => form.hasSweeps === "Yes",
  },
];

const PRESAVE_FORMATS: Record<SetPageRelease["type"], string> = {
  full_album: "Album",
  single: "Single",
  track_on_album: "Track on Album",
};

const isValidResponse = (res: PromptResponse) => {
  if (res.surveyQuestions && !res.surveyQuestions.length) {
    return false;
  }
  if (!res.fields || !Object.keys(res.fields).length) {
    return false;
  }
  return true;
};

export const AiPromptModal = ({ onBack }: PromptModalProps) => {
  const [aiPromptLoading, setAiPromptLoading] = useState(false);
  const { name: artist } = useArtistContext();
  const {
    selectedTemplate,
    selectedPreSaveRelease,
    modal,
    isLoading: createLoading,
    handleCreate,
  } = useTemplates();

  const { prompt } = selectedTemplate ?? {};
  const isLoading = aiPromptLoading || createLoading;
  const { request } = usePrompt();
  const { logException } = useExceptionLogging();

  const handleSubmit = async (formState: PromptFormState) => {
    if (!prompt.promptId || !formState) {
      return;
    }
    const promptId = prompt.promptId(formState);
    const updatedFormState = { ...formState, artist };
    setAiPromptLoading(true);

    if (
      selectedTemplate?.templateId === "preSaveRelease" &&
      selectedPreSaveRelease
    ) {
      updatedFormState.title = selectedPreSaveRelease.title;
      updatedFormState.format = PRESAVE_FORMATS[selectedPreSaveRelease.type];
    }

    const attemptRequest = async (attempt = 0) => {
      try {
        const { result, error: responseError } = await request({
          templateId: promptId,
          values: updatedFormState,
        });

        if (responseError || !isValidResponse(result)) {
          throw new Error("Invalid response from AI endpoint.");
        }

        return result; // Successful response
      } catch (error) {
        console.error(error);
        logException(error, {
          action: "template-generate",
          promptId,
        });

        if (attempt < 2) {
          // 2 more attempts
          return attemptRequest(attempt + 1);
        } else {
          throw error; // Rethrow after max attempts
        }
      }
    };

    try {
      const aiResponse = await attemptRequest();
      await handleCreate({
        config: aiResponse,
        formState: updatedFormState,
      });
    } catch (error) {
      toast.error(
        "We encountered an issue while generating your content. Please try again or contact our support team for help.",
        { duration: 15000 },
      );
    } finally {
      setAiPromptLoading(false);
    }
  };

  return (
    <Modal
      header={<H2>{isLoading ? "Almost there..." : "Additional Details"}</H2>}
      isOpen={modal.aiPrompt}
    >
      {isLoading ? (
        <LoadingState message="Thanks for your patience. Behind the scenes we are using your responses to intelligently build a custom survey that will support your needs." />
      ) : (
        <>
          <Body1 style={{ color: "#666", marginTop: 4, marginBottom: 12 }}>
            Please share additional details for a customized SET.Fan experience:
          </Body1>
          <Form
            fields={(prompt?.fields || []).concat(defaultSweepsFields)}
            onSubmit={handleSubmit}
            style={{ marginTop: 12 }}
          >
            <ResponsiveFooterActions>
              <Button variant="tertiary" onClick={() => onBack()}>
                Back
              </Button>
              <Button type="submit">Create My Draft</Button>
            </ResponsiveFooterActions>
          </Form>
        </>
      )}
    </Modal>
  );
};

const Modal = styled(ResponsiveModal)`
  ${ModalHeaderContainer} {
    margin-bottom: 0;
  }
`;
