export const textWithoutParentheses = (text) => text.replace(/\([^)]*\)/g, "");

export const resetPage = (
  setPage: React.Dispatch<React.SetStateAction<number>>,
  setState: React.Dispatch<React.SetStateAction<string>>,
  value: string,
) => {
  setState((state) => {
    if (state !== value) {
      setPage(1);
    }
    return value;
  });
};

export const hexToRGB = (hex: string, alpha?: number) => {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r},${g},${b},${alpha})`;
  } else {
    return `rgba(${r},${g},${b})`;
  }
};

export const varHexToRGB = (varName: string, alpha?: number) => {
  const rootEl = document?.getElementById("root");
  if (!rootEl) {
    console.error("could not get root dom element");
    return "rgba(0,0,0)";
  }
  return hexToRGB(getComputedStyle(rootEl).getPropertyValue(varName), alpha);
};
export const Cookies = {
  get: (name: string) =>
    document.cookie
      .split("; ")
      .find((row) => row.startsWith(`${name}=`))
      ?.split("=")[1],
};

export const capitalize = (text: string) =>
  text.charAt(0).toUpperCase() + text.substring(1);
