import React, { useState } from "react";
import { StackedInternalModal } from "Components/ModalStack";
import { FormikProvider, useFormik, useFormikContext } from "formik";
import * as yup from "yup";
import {
  SetPagePreSaveReleaseModule,
  SetPageRelease,
  SetPageReleaseForm,
} from "@max/common/src/setpage";
import { ReleaseDetailForm } from "../../Components/ReleaseDetailForm";
import { RELEASE_VALIDATION_SHAPE, saveReleaseDate } from "../..";
import { SetPageFormValues } from "Routes/SetPage/types";
import { useFormChangesContext } from "contexts/FormChangesContext";
import { Timestamp } from "firebase/firestore";

interface Props {
  release: SetPageReleaseForm;
  isOpen: boolean;
  onClose: () => void;
  isTakeover?: boolean;
  onChange?: (release: SetPageRelease) => void;
}

export const EditPreSaveModal: React.FC<Props> = ({
  release,
  isOpen,
  onClose,
  isTakeover,
  onChange,
}) => {
  const moduleFormik = useFormikContext<SetPagePreSaveReleaseModule>();
  const mainFormik = useFormikContext<SetPageFormValues>();
  const formik = useFormik<SetPageReleaseForm>({
    initialValues: release,
    validationSchema: yup.object().shape(RELEASE_VALIDATION_SHAPE),
    onSubmit: handleSubmit,
  });
  const formChanges = useFormChangesContext();
  const [loading, setLoading] = useState(false);

  async function handleSubmit() {
    setLoading(true);
    const releaseForm: SetPageReleaseForm = {
      ...formik.values,
      updatedAt: Timestamp.now(),
    };
    await saveReleaseDate(releaseForm);

    const { date, ...release } = releaseForm;

    if (isTakeover) {
      const fieldKeyValue: [string, SetPageRelease] = [
        "page.takeoverPromotions.pre_save_release.release",
        release,
      ];
      formChanges?.compareChanges(...fieldKeyValue);
      mainFormik.setFieldValue(...fieldKeyValue);
    } else {
      moduleFormik.setFieldValue("release", release);
    }

    setLoading(false);
    onClose();
    onChange?.(releaseForm);
  }

  return (
    <StackedInternalModal
      header="Edit Pre-Save"
      headerColor="main"
      isOpen={isOpen}
      onClose={onClose}
      actions={{
        main: {
          text: "Update",
          onClick: () => formik.submitForm(),
          loading,
        },
        secondary: { text: "Cancel", onClick: onClose },
      }}
    >
      <FormikProvider value={formik}>
        <ReleaseDetailForm mode="edit" releaseId={formik.values.id} />
      </FormikProvider>
    </StackedInternalModal>
  );
};
