import React, { useEffect, useState } from "react";
import {
  DefaultAppLanding,
  DefaultLayout,
  PageContentWrapper,
  PageHeaderImage,
} from "Components";
import { GlobalStyles } from "styled";
import { SvgAddAlt } from "melodies-source/Svgs/AddAlt";
import { SvgCopy } from "melodies-source/Svgs/Copy";
import styled from "styled-components";
import { Tabs } from "Components/Tabs";
import { Shows } from "./Shows";
import { svg } from "svg";
import { MenuButton } from "Components/MenuButton";
import { ReactComponent as ExpandMoreIcon } from "assets/svg/chevron-bottom.svg";
import { useMobileMediaQuery } from "hooks/useMobileMediaQuery";
import { useHistory, useParams } from "react-router-dom";
import { Duplicate } from "./Duplicate";
import {
  SetLiveEventsProvider,
  useSetLiveEventsContext,
} from "contexts/SetLiveEventsContext";
import { useCreateSetLiveEvent, useSetLiveEvents } from "hooks";
import { useCustomAppContext } from "contexts/CustomAppContext";
import { SetLiveKnockout } from "assets/logos";
import animationData from "assets/lotties/live-marketing-animation.json";
import { Spinner } from "melodies-source/Spinner";
import { FlexColumn } from "Components/Flex";
import { BulletList } from "Routes/SetFan/dashboard";
import { IconLiveLocation, IconNoDevice, IconBus } from "assets/svg";
import { Body1, Li } from "melodies-source/Text";

export const SetLive: React.FC = () => {
  return (
    <SetLiveEventsProvider>
      <SetLiveInternal />
    </SetLiveEventsProvider>
  );
};

export const SetLiveInternal: React.FC = () => {
  const isMobile = useMobileMediaQuery();
  const { artistId } = useParams<{ artistId: string }>();
  const [duplicateOpen, setDuplicateOpen] = useState(false);
  const { reloadEvents } = useSetLiveEventsContext();
  const { customApp, language } = useCustomAppContext();
  const { createEvent } = useCreateSetLiveEvent({ artistId });
  const history = useHistory();

  useEffect(() => {
    if (history.location.state && history.location.state["duplicate"]) {
      setDuplicateOpen(true);
    }
  }, []);

  const { totalEvents, loading: eventsLoading } = useSetLiveEvents({
    filterBy: "deletedAt:0 && status:[published,unpublished] && version:=2",
    page: 1,
    resultsPerPage: 1,
  });

  const noEvents = !(totalEvents > 0);

  const tabs = [
    {
      param: "upcoming-shows",
      label: `Upcoming ${language.event.type.plural}`,
      content: <Shows key="upcoming-shows" />,
      icon: <svg.Microphone />,
    },
    {
      param: "past-shows",
      label: `Past ${language.event.type.plural}`,
      content: <Shows key="past-shows" />,
      icon: <svg.Calendar />,
    },
  ];

  const headerButtonMenu = {
    position: "right" as const,
    width: "200px",
    items: [],
  };

  if (!noEvents) {
    headerButtonMenu.items.push({
      label: `Duplicate ${language.event.type.singular}`,
      icon: {
        position: "left" as const,
        element: <SvgCopy />,
      },
      onClick: () => setDuplicateOpen(true),
    });
  }

  if (!isMobile) {
    headerButtonMenu.items.unshift({
      label: `New ${language.event.type.singular}`,
      icon: {
        position: "left" as const,
        element: <SvgAddAlt />,
      },
      onClick: createEvent,
    });
  }

  return (
    <>
      <DefaultLayout>
        <GlobalStyles grayBG />
        <PageHeaderImage app="live" />
        <PageContentWrapper
          title={
            customApp?.company.name ? (
              `${customApp.company.name}.Live`
            ) : (
              <SetLiveKnockout />
            )
          }
          action={
            !noEvents &&
            !customApp && (
              <HeaderButton
                leftIcon={<SvgAddAlt />}
                rightIcon={<StyledExpandMoreIcon />}
                variant="primary"
                menu={headerButtonMenu}
              >
                Add {language.event.type.singularWithArticle}
                <div
                  style={{
                    margin: "0 0 0 12px",
                    width: 0.5,
                    height: "65%",
                    background: "#dcdfe0aa",
                  }}
                />
              </HeaderButton>
            )
          }
        >
          {eventsLoading ? (
            <Spinner />
          ) : noEvents ? (
            <DefaultAppLanding
              app="live"
              title="Connect With Fans At Your Shows"
              body={
                <FlexColumn gap="20px">
                  <Body1>
                    SET.Live lets touring artists easily create unique digital
                    experiences at each concert to effortlessly capture fan info
                    and enhance the show experience.
                  </Body1>
                  <BulletList $iconColor="var(--set-live-primary)">
                    <IconLiveLocation />
                    <Li>Geo-targeted experiences</Li>
                    <IconNoDevice />
                    <Li>No app to download</Li>
                    <IconBus />
                    <Li>Easy to use on-the-road</Li>
                  </BulletList>
                </FlexColumn>
              }
              actionText="Get Started"
              onAction={() => createEvent()}
              video={{
                url: "https://storage.googleapis.com/set-live-stage.appspot.com/artist-portal/video/set-live-promotion.mp4",
              }}
              animation={animationData}
            />
          ) : (
            <Tabs tabs={tabs} basePath={`/${artistId}/set-live`} />
          )}
        </PageContentWrapper>
      </DefaultLayout>
      {duplicateOpen && (
        <Duplicate
          onComplete={(v) => {
            setDuplicateOpen(false);
            if (v) reloadEvents();
          }}
        />
      )}
    </>
  );
};

const StyledExpandMoreIcon = styled(ExpandMoreIcon)`
  height: 25px;
  width: 25px;
  margin-right: 0;
`;

const HeaderButton = styled(MenuButton)`
  width: auto;
  padding: 0 10px;
  background-color: #e71e3d;
  color: #fff;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.3);

  svg:first-child {
    margin: 0 5px;
  }
`;
