import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Header, Layout } from "../../Components/Layout";
import { RegionCard } from "./RegionCard";
import { GtbRegion } from "@max/common/src/thirdparty/gtb";
import { QueryDocumentSnapshot, QuerySnapshot } from "firebase/firestore";
import { Spinner } from "melodies-source/Spinner";
import { Button } from "melodies-source/Button";
import { ReactComponent as PlusIcon } from "assets/svg/plus.svg";
import { RegionModal } from "./RegionModal";
import { H3 } from "melodies-source/Text";
import { useUser } from "auth";
import { useCustomAppContext } from "contexts/CustomAppContext";
import { GtbCustomApp } from "../../types";

interface Props {
  regions: QuerySnapshot<GtbRegion>;
  regionsLoading: boolean;
}

export type Areas = Record<string, QueryDocumentSnapshot<GtbRegion>[]>;

export const Regions: React.FC<Props> = ({ regions, regionsLoading }) => {
  const [areas, setAreas] = useState<Areas>({});
  const [regionModalIsOpen, setRegionModalIsOpen] = useState(false);
  const { claims } = useUser();
  const { customApp } = useCustomAppContext<GtbCustomApp>();

  const isSystemAdmin = claims.admin;

  useEffect(() => {
    if (regions) {
      const areas: Areas = {};

      for (const region of regions.docs) {
        const areaName = region.data().area;
        areas[areaName] = [...(areas[areaName] || []), region];
      }

      setAreas(areas);
    }
  }, [regions, regionsLoading]);

  useEffect(() => {
    localStorage.removeItem("gtb_region_id");
  }, []);

  return (
    <Layout>
      <Container>
        {regionsLoading ? (
          <Spinner />
        ) : (
          <>
            <Header
              {...(isSystemAdmin && {
                action: (
                  <AddRegionButton
                    variant="secondary"
                    leftIcon={<PlusIcon />}
                    onClick={() => setRegionModalIsOpen(true)}
                  >
                    Add {customApp?.group.singular}
                  </AddRegionButton>
                ),
              })}
            >
              ALL {customApp?.group.plural.toUpperCase()}
            </Header>
            <AreasContainer>
              {Object.entries(areas)
                .sort(([areaA], [areaB]) => areaA.localeCompare(areaB))
                .map(([area, regions]) => (
                  <Area key={area}>
                    <AreaName>{area}</AreaName>
                    <RegionList>
                      {regions
                        .sort((a, b) =>
                          a.data().name?.localeCompare(b.data().name),
                        )
                        .map((region) => (
                          <RegionCard key={region.id} region={region} />
                        ))}
                    </RegionList>
                  </Area>
                ))}
            </AreasContainer>
          </>
        )}
      </Container>
      <RegionModal
        isOpen={regionModalIsOpen}
        onClose={() => setRegionModalIsOpen(false)}
        areas={areas}
      />
    </Layout>
  );
};

const Container = styled.div`
  margin-top: 30px;

  ${({ theme }) => theme.media.mobile} {
    margin-top: 15px;
  }
`;

const AreasContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 20px;

  ${({ theme }) => theme.media.mobile} {
    margin-top: 10px;
    gap: 30px;
  }
`;

const Area = styled.div`
  margin-bottom: 20px;
`;

const AreaName = styled(H3)`
  margin-bottom: 10px;
`;

const RegionList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const AddRegionButton = styled(Button)`
  width: auto;
  margin-left: auto;
`;
