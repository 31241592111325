import React, { useState } from "react";
import { StackedInternalModal } from "Components/ModalStack";
import { ModalTitle } from "Routes/SetPage/Components/ModalTitle";
import styled from "styled-components";
import { ReleaseTypeStep } from "./ReleaseTypeStep";
import { ReactComponent as ArrowRight } from "assets/svg/arrow-right.svg";
import * as yup from "yup";
import { ReactComponent as SaveReleaseIcon } from "assets/svg/save-release.svg";
import { FormikProvider, useFormik, useFormikContext } from "formik";
import { ReleaseDateStep } from "./ReleaseDateStep";
import { ReleaseDetailStep } from "./ReleaseDetailStep";
import {
  SetPagePreSaveReleaseModule,
  SetPageRelease,
  SetPageReleaseForm,
} from "@max/common/src/setpage";
import { PresaveSource } from "@max/common/src/artists/presave";
import {
  getReleaseInitialValues,
  saveRelease,
  RELEASE_VALIDATION_SHAPE,
} from "../..";
import { useArtistContext } from "Components";
import {
  collection,
  getDocs,
  getFirestore,
  query,
  Timestamp,
  where,
} from "firebase/firestore";
import toast from "react-hot-toast";
import { SetPageFormValues } from "Routes/SetPage/types";
import { useFormChangesContext } from "contexts/FormChangesContext";
import { generateFirestoreId } from "Utils/generateFirestoreId";

enum AddPreSaveStep {
  ReleaseType,
  ReleaseDate,
  ReleaseDetail,
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
  source: PresaveSource;
  onChange?: (release: SetPageRelease) => void;
}

export const AddPreSaveModal: React.FC<Props> = ({
  isOpen,
  onClose,
  source,
  onChange,
}) => {
  const [step, setStep] = useState<AddPreSaveStep>(AddPreSaveStep.ReleaseType);
  const mainFormik = useFormikContext<SetPageFormValues>();
  const moduleFormik = useFormikContext<SetPagePreSaveReleaseModule>();
  const formik = useFormik<SetPageReleaseForm>({
    initialValues: {
      ...getReleaseInitialValues(),
      source,
    } as SetPageReleaseForm,
    validationSchema: yup.object().shape(getStepVariables().validationShape),
    onSubmit: getStepVariables().handleNext,
  });
  const { id: artistGroupId } = useArtistContext();
  const [isSavingRelease, setIsSavingRelease] = useState(false);
  const formChanges = useFormChangesContext();

  const checkCodes = async () => {
    const q = query(
      collection(getFirestore(), "artist_releases"),
      where("artistGroupId", "==", artistGroupId),
      where("upc", "==", formik.values.upc),
      where("isrc", "==", formik.values.isrc || null),
      where("releaseDate", ">", new Date()),
      where("deletedAt", "==", null),
    );
    const querySnapshot = await getDocs(q);

    return querySnapshot.size === 0;
  };

  function getStepVariables() {
    let currentStep = null;
    let handleNext = async () => {};
    let validationShape = {};

    switch (step) {
      case AddPreSaveStep.ReleaseType: {
        currentStep = <ReleaseTypeStep />;
        handleNext = async () => {
          const valid = await checkCodes();
          if (valid) {
            formik.setTouched({});
            setStep(AddPreSaveStep.ReleaseDate);
          } else {
            toast("There's an active pre-save using the same codes");
          }
        };
        const { type, upc, isrc } = RELEASE_VALIDATION_SHAPE;
        validationShape = { type, upc, isrc };
        break;
      }
      case AddPreSaveStep.ReleaseDate: {
        currentStep = <ReleaseDateStep />;
        handleNext = async () => {
          formik.setTouched({});
          setStep(AddPreSaveStep.ReleaseDetail);
        };

        const { date } = RELEASE_VALIDATION_SHAPE;
        validationShape = { date };
        break;
      }
      case AddPreSaveStep.ReleaseDetail: {
        currentStep = <ReleaseDetailStep />;
        handleNext = async () => {
          setIsSavingRelease(true);
          let releaseForm: SetPageReleaseForm = {
            ...(formik.values as SetPageReleaseForm),
            createdAt: Timestamp.now(),
            updatedAt: Timestamp.now(),
          };
          if (releaseForm.type !== "track_on_album") {
            releaseForm.isrc = null;
          }
          releaseForm.id = await saveRelease(releaseForm, artistGroupId);
          const { date, ...release } = releaseForm;

          if (source === "setbio:takeover") {
            const preSaveModule: SetPagePreSaveReleaseModule = {
              id: generateFirestoreId(),
              index: 0,
              type: "pre_save_release",
              name: "Pre-Save Release",
              title: "",
              release,
              styles: {
                heading: {
                  align: "center",
                },
                layout: "cropped",
                displayCountdown: true,
              },
            };
            const fieldKeyValue: [string, SetPagePreSaveReleaseModule] = [
              "page.takeoverPromotions.pre_save_release",
              preSaveModule,
            ];
            formChanges?.compareChanges(...fieldKeyValue);
            mainFormik.setFieldValue(...fieldKeyValue);
          } else {
            moduleFormik.setFieldValue("release", release);
          }

          setIsSavingRelease(false);
          onClose();
          onChange?.(releaseForm);
        };

        const { title, description, image, preSaveOn, allowFutureReleases } =
          RELEASE_VALIDATION_SHAPE;
        validationShape = {
          title,
          description,
          image,
          preSaveOn,
          allowFutureReleases,
        };
        break;
      }
    }

    return { currentStep, handleNext, validationShape };
  }

  return (
    <StackedInternalModal
      header={
        <ModalTitle>
          <SaveReleaseIcon />
          Add a Pre-Save
        </ModalTitle>
      }
      isOpen={isOpen}
      onClose={onClose}
      actions={{
        main: {
          onClick: () => formik.submitForm(),
          ...(step === AddPreSaveStep.ReleaseDetail
            ? { text: "Add", loading: isSavingRelease }
            : {
                text: "Next",
                rightIcon: <StyledArrowRight />,
                nudgeRight: true,
              }),
        },
        secondary: { text: "Cancel", onClick: onClose },
      }}
    >
      <FormikProvider value={formik}>
        {getStepVariables().currentStep}
      </FormikProvider>
    </StackedInternalModal>
  );
};

const StyledArrowRight = styled(ArrowRight)`
  width: 14px !important;
`;
