"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.rogueValleyStrawfest = void 0;
const hearthHarmony_1 = require("./hearthHarmony");
const towne_1 = require("./towne");
exports.rogueValleyStrawfest = Object.assign(Object.assign({}, towne_1.towne), { group: hearthHarmony_1.hearthHarmony.group, parentGroup: hearthHarmony_1.hearthHarmony.parentGroup, app: { name: "SPONSORSHIP PROGRAMS" }, company: {
        name: "Rogue Valley Strawfest",
        logo: {
            url: "/custom/rogue-valley-strawfest/logo.svg",
            description: "portland strawfest",
        },
        icon: "/custom/rogue-valley-strawfest/tags/favicon.ico",
        image: "/custom/rogue-valley-strawfest/tags/logo.png",
    }, footerLinks: [
        {
            text: "Rogue Valley Strawfest Privacy Policy",
            url: "https://www.max.live/privacy-policy",
        },
        { text: "MAX Privacy Policy", url: "https://www.max.live/privacy-policy" },
    ], fanAppUrl: `https://strawfest.live`, onsiteAppUrl: `https://onsite.strawfest.live`, privacyPolicyUrl: "https://www.max.live/privacy-policy", regionsCollection: "rogue_valley_strawfest_regions", menu: {
        sections: {
            engagement: {
                header: "User Engagement",
                items: {
                    live: {
                        label: "RogueValleyStrawfest.Live",
                    },
                },
            },
        },
    } });
