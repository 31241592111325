import { FlexColumn } from "Components/Flex";
import styled from "styled-components";

export const Section = styled.div`
  padding: 30px;
  border-radius: 12px;
  background: #f2f5f7;

  ${({ theme }) => theme.media.mobile} {
    padding: 20px;
  }
`;

export const SectionHeader = styled.div`
  margin-bottom: 20px;
`;

export const FormContainer = styled(FlexColumn)`
  padding: 27px;
  background-color: #f2f5f7;
  border-radius: 12px;
  margin-top: 22px;

  ${({ theme }) => theme.media.mobile} {
    padding: 21px;
    margin-top: 15px;
  }
`;
